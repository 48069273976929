import { FLOW_MODAL } from '@/constants/actionTypes';
import { Close } from '@styled-icons/material-outlined';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ReactDraggable from 'react-draggable'
import './modal.css'

const modalStyles = {
  position: 'fixed',
  top: '13%', // 垂直位置在1/3处
  left: '50%',
  transform: 'translate(-50%, -50%)',
  cursor: 'move',
  width: '80%',
  minWidth: 300,
  maxWidth: 460,
  minHeight: 80,
  backgroundColor: 'lemonchiffon',
  padding: '20px',
  paddingBottom: '15px',
  borderRadius: '4px',
  boxShadow: '0px 0px 8px #bbb',
  border: '1px solid gray',
  zIndex: '1000',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  rowGap: 20
};

const Modal = () => {
  const modalState = useSelector(state => state.uiState.flow_modal);
  const { visible, action, params } = modalState || {};
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleClose = (action_confirmed) => {
    dispatch({
      type: FLOW_MODAL,
      value: {
        ...modalState,
        visible: false,
        action_confirmed: action_confirmed && action
      }
    })
  }

  if (!visible) {
    return null;
  }

  return (
    // <ReactDraggable handle=".handle">
    <div className="modal-overlay">
      <div
        className="modal"
      // style={modalStyles}
      >
        <div
          className='transparent-background'
          style={{
            position: 'absolute',
            cursor: 'pointer',
            right: 0,
            top: 0,
            margin: 3
          }}
          onClick={() => handleClose()}
        >
          <Close size={20} />
        </div>

        <div style={{
          fontSize: 18
        }}>
          {intl.formatMessage({ id: 'flow_info_' + action }, { models: params?.required_models?.join(', ') || '' })}
        </div>
        <div style={{
          border: '1px solid gray',
          boxShadow: '0px 0px 8px #bbb',
          padding: 5,
          paddingRight: 10,
          paddingLeft: 10,
          borderRadius: 4,
          cursor: 'pointer',
          width: 'fit-content',
        }}
          onClick={() => {
            handleClose(true);
          }}
        >
          {intl.formatMessage({ id: ['login', 'copy_to_editable'].includes(action) && action || action == 'saved_to_workspace' && 'to_workspace' || 'close' })}
        </div>
      </div>
    </div>
    // </ReactDraggable>
  );
};

export default Modal;