import { get_server_host } from '@/utils/serverAPIUtil';
import { Tooltip } from '@mui/material';
import { Edit } from '@styled-icons/fluentui-system-regular/Edit';
import React, { useRef, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { sendMessageToPreview } from '../../utils/SlidesUtil';

function RevealPresentation({ width, height, hid }) {
    const iframeRef = useRef();
    const intl = useIntl();

    const [server_host, setServer_host] = React.useState();
    useEffect(() => {
        get_server_host().then((value) => setServer_host(value));
    }, []);

    useEffect(() => {
        if (!iframeRef?.current) return;

        iframeRef.current.style.width = (width - 2) + 'px';
        iframeRef.current.style.height = (height - 2) + 'px';
        iframeRef.current.style.borderRadius = '4px';
    }, [width, height])

    return (
        <div
            style={{
                position: 'relative',
                borderRadius: 6
            }}>
            <div
                className='fill-available'
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: 24
                }}
            >

            </div>
            <iframe
                className='nodrag'
                id='slides-frame'
                ref={iframeRef}
                src={`${server_host}view.html?theme=sky&hid=${hid}`}
                title="FunBlocks AI Slides"
            />
        </div>
    );
}

export default RevealPresentation;