'use strict';

import { combineReducers } from 'redux';
import loginIn from './loginReducer';
import {
  org_lists,
  group_lists,
  to_confirm_org_lists,
  slide_lists,
  deleted_slide_lists,
  doc_lists,
  flow_doc_lists,
  dbdata_lists,
  dbview_lists,
  dbview_dataorder_lists,
  workspace_doc_lists,
  shared_doc_lists,
  private_doc_lists,
  deleted_doc_lists,
  sub_doc_lists,
  doc_history_lists,
  ril_lists,
  read_ril_lists,
  deleted_ril_lists,
  searched_ril_lists,
  searched_todo_lists,
  comment_lists,
  article_highlight_lists,
  feedback_lists,
  tag_lists,
  prompt_lists,
  public_prompt_lists,
  workspace_prompt_lists,
  validate_prompt_lists,
  pinned_prompt_lists
} from './listReducer';
import { operationStatus } from './operationStatusReducer';
import * as slidesDraftReducers from './slidesDraftReducer';
import * as docReducers from './docReducer';
import * as docHtmlReducers from './docHtmlReducer';
import * as viewSortsReducers from './viewSortsReducer';
import * as viewFiltersReducers from './viewFiltersReducer';
import * as articleReducers from './articleReducer';
import * as promptReducers from './promptReducer';
import * as dbViewReducers from './dbViewReducer';
import * as pageHistoryReducers from './pageHistoryReducer';
import * as ActionTypes from '../constants/actionTypes';

import { uiReducer } from './uiReducer';

const appReducer = combineReducers({
  uiState: uiReducer,
  operationStatus,
  loginIn,
  org_lists,
  group_lists,
  to_confirm_org_lists,
  slide_lists,
  doc_lists,
  flow_doc_lists,
  dbdata_lists,
  dbview_lists,
  dbview_dataorder_lists,
  workspace_doc_lists,
  shared_doc_lists,
  private_doc_lists,
  deleted_doc_lists,
  sub_doc_lists,
  doc_history_lists,
  comment_lists,
  deleted_slide_lists,
  ril_lists,
  read_ril_lists,
  deleted_ril_lists,
  searched_ril_lists,
  searched_todo_lists,
  feedback_lists,
  prompt_lists,
  public_prompt_lists,
  workspace_prompt_lists,
  pinned_prompt_lists,
  validate_prompt_lists,
  tag_lists,
  article_highlight_lists,
  docs: combineReducers({ ...docReducers }),
  docHtmls: combineReducers({ ...docHtmlReducers }),
  views: combineReducers({ ...dbViewReducers }),
  viewSorts: combineReducers({ ...viewSortsReducers }),
  viewFilters: combineReducers({ ...viewFiltersReducers }),
  articles: combineReducers({ ...articleReducers }),
  slides_drafts: combineReducers({ ...slidesDraftReducers }),
  prompts: combineReducers({ ...promptReducers }),
  page_history: combineReducers({ ...pageHistoryReducers }),
});

const rootReducer = (state, action) => {
  if (action.type === ActionTypes.SETTINGS.RESET_CACHE) {
    state = { loginIn: state.loginIn };
  }

  return appReducer(state, action)
}

export default rootReducer;
