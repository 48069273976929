import { useReactFlow } from '@xyflow/react';
import ELK from 'elkjs/lib/elk.bundled.js';
import { useCallback } from 'react';

const elk = new ELK();

export const useLayoutedElements = () => {
    const { getNodes, setNodes, getEdges, fitView } = useReactFlow();
    const defaultOptions = {
        'elk.algorithm': 'layered',
        // 'elk.layered.spacing.nodeNodeBetweenLayers': 10,
        // 'elk.spacing.nodeNode': 10,
    };

    const getLayoutedElements = useCallback((options) => {
        const layoutOptions = { ...defaultOptions, ...options };
        const graph = {
            id: 'root',
            layoutOptions: layoutOptions,
            children: getNodes().map(n => ({
                ...n,
                width: (n.measured?.width ?? 0) + 40,
                height: (n.measured?.height ?? 0) + 40,
            })),
            edges: getEdges().filter(
                (edge) =>
                    getNodes().some((node) => node.id === edge.source.id) &&
                    getNodes().some((node) => node.id === edge.target.id)
            ),
        };

        elk.layout(graph).then(({ children }) => {
            // By mutating the children in-place we saves ourselves from creating a
            // needless copy of the nodes array.
            const nodes = getNodes();

            children.forEach((node) => {
                if (!node.parentId || !getNodes().find(n => n.id === node.parentId)) {
                    node.position = { x: node.x, y: node.y };
                }
            });

            setNodes(nodes.map(n => {
                const child = children.find(c => c.id == n.id);
                return {
                    ...n,
                    position: child.position
                }
            }));
            window.requestAnimationFrame(() => {
                fitView();
            });
        });
    }, []);

    return { getLayoutedElements };
};