import * as React from 'react';
import { useIntl } from 'react-intl';
import { Question } from '@styled-icons/bootstrap/Question';
import { Tooltip } from '@mui/material';
import { ArrowDropUp, ArrowDropDown } from '@styled-icons/material';

export const DropDownMenu = ({ tooltip, isDropdown, setDropdown, items, onSelect, color_theme, value, style, explainIt }) => {
  const intl = useIntl();
  const [hovered, setHovered] = React.useState();

  if (!items?.length) return;

  return <>
    <Tooltip title={tooltip} placement="top">
      <div
        className='transparent-background'
        style={{
          cursor: 'pointer',
          borderRadius: 3,
          color: '#777',
        }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setDropdown(!isDropdown);
        }}
      >
        {!isDropdown &&
          <ArrowDropDown size={20} />}
        {isDropdown &&
          <ArrowDropUp size={20} />}
      </div>
    </Tooltip >
    {
      isDropdown &&
      <div
        className='nowheel'
        style={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          top: '100%',
          right: -1,
          border: `1px solid ${color_theme.border}`,
          backgroundColor: color_theme.content_bg,
          boxShadow: '0px 0px 8px #bbb',
          marginTop: 1,
          borderRadius: 4,
          zIndex: 1000,
          maxHeight: 300,
          overflowY: 'auto',
          ...style
        }}
      >
        {items?.map(item => {
          return <div
            key={item.value}
            className='transparent-background'
            style={{
              position: 'relative',
              justifyContent: 'flex-end',
              cursor: 'pointer',
              fontSize: 14,
              padding: 4,
              columnGap: 6,
              color: '#555',
              backgroundColor: item.value === value ? color_theme.title_bg : undefined
            }}
            onClick={(event) => {
              setDropdown(false);
              onSelect(item);
            }}
            onMouseEnter={() => setHovered(item.value)}
            onMouseLeave={() => setHovered(false)}
          >
            {item.label}
            {item.icon}
            {hovered === item.value && !!explainIt && item.value != 'other' &&
              <Tooltip
                title={intl.formatMessage({ id: 'explain' })}
                placement='bottom'
              >
                <div
                  className='circle-button'
                  style={{
                    // position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 16,
                    width: 16,
                    // top: '50%',
                    // transform: 'translate(0%, -50%)',
                    // right: 0,
                    borderColor: color_theme.border,
                    backgroundColor: color_theme.content_bg,
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    explainIt(item.label);
                  }}
                >
                  <Question size={15} color='dodgerblue' />
                </div>
              </Tooltip>}
          </div>;
        })}
      </div>
    }
  </>;
};
