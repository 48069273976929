// import AsyncStorage from '@react-native-community/async-storage';
// import DefaultPreference from 'react-native-default-preference';

export async function storeData(key, value) {
    try {
        // value = "userId=5b45c5810169b519597d2f19; Max-Age=2592000; Path=/; Expires=Sat, 24 Aug 2019 09:44:00 GMT;connect.sid=s%3ALBO0H1aOXGWh_RFVzcTxMkGLyMNNMgpS.VTHNpblvjrXMqdWql9PAobb4WxYhsZyqPLlVel63AS4; Path=/; Expires=Sat, 24 Aug 2019 09:44:00 GMT; HttpOnly";
        localStorage.setItem(key, value);
    } catch (e) {
        // saving error
    }
};

export async function getData(key) {
    try {
        const value = localStorage.getItem(key);
        return value;
    } catch (e) {
        // error reading value
    }
}



