import { useIntl } from 'react-intl'
import workspace_img from '../../assets/images/workspace.png'
import { Button } from '@mui/material'
import { SETTINGS_DIALOG } from '../../constants/actionTypes'
import { useDispatch } from 'react-redux'

const SpaceIntro = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <div
      style={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 10,
      }}
    >
      <div
        style={{
          marginTop: 56,
          padding: 18,
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
          border: '1px solid #e3e3e3',
          borderRadius: 6,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <span style={styles.title}>
            {intl.formatMessage({ id: 'what_is_workspace' })}
          </span>
        </div>
        <div
          style={{
            marginTop: 6,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            columnGap: 6,
          }}
        >
          <div
            style={{
              fontSize: 14,
              color: '#333',
              display: 'flex',
              flexDirection: 'column',
              rowGap: 8,
              flex: 4,
            }}
          >
            {intl
              .formatMessage({ id: 'workspace_intro' })
              .split('\n')
              .map((str, index) => {
                return <span key={index}>{str}</span>
              })}
          </div>
          <div style={{ flex: 4 }}>
            <img
              className="highlighted-image"
              src={'https://www.funblocks.net/assets/img/portfolio/fullsize/aiwriter.png'}
            />
          </div>
        </div>

        <div
          className="fill-available"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 50,
          }}
        >
          <Button
            onClick={() => {
              dispatch({
                type: SETTINGS_DIALOG,
                value: {
                    visible: false
                }
            });
              window.open(`http://${window.location.host}/`)
            }}
          >
            {intl.formatMessage({ id: 'to_workspace' })}
          </Button>
        </div>
      </div>
    </div>
  )
}

const styles = {
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    paddingTop: '36px',
    columnGap: '10px',
  },

  title: {
    fontWeight: 550,
    fontSize: 16,
    whiteSpace: 'nowrap',
  },
  input: {
    padding: '3px 12px',
    border: '1px solid #ccc',
    borderRadius: 15,
    outline: 'none',
    minWidth: '400px',
    fontSize: 14,
  },
  selector: {
    border: '1px solid #ccc',
    paddingLeft: 12,
    paddingRight: 6,
    borderRadius: 15,
    minWidth: 80,
  },
}

export default SpaceIntro
