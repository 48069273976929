import React, { useState, useEffect, useCallback } from 'react';
import {
    Radio, RadioGroup, FormControlLabel, Checkbox, Select, MenuItem,
    FormControl, InputLabel, Card, CardContent, Typography,
    Divider
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { FLOW_SETTINGS } from '../../constants/actionTypes';
import { isMac } from '../../constants/constants';
import { Selector } from '../common/Selector';

const FlowSettings = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const flow_settings = useSelector(state => state.uiState.flow_settings);

    const update_flow_settings = useCallback((key, value) => {
        const updated_settings = {
            ...flow_settings,
            [key]: value
        };

        dispatch({
            type: FLOW_SETTINGS,
            value: updated_settings
        });
    }, [flow_settings, dispatch]);

    useEffect(() => {
        update_flow_settings('zoomKey', isMac ? 'Meta' : 'Control');
    }, [flow_settings?.zoomKey]);

    const isTouchDevice = useCallback(() => {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    }, []);

    return (
        <div
            className='fill-available'
            style={{
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: 10,
                paddingBottom: 40,
                paddingTop: 20,
            }}
        >
            <div style={styles.section}>
                <div style={styles.section_box}>
                    <CardContent>
                        <Typography variant="subtitle1" gutterBottom>
                            {intl.formatMessage({ id: 'flow_settings_drag_behavior' })}
                        </Typography>
                        <RadioGroup
                            value={flow_settings?.dragBehavior || 'pan'}
                            onChange={(e) => update_flow_settings('dragBehavior', e.target.value)}
                        >
                            <div style={styles.radio_item}>
                                <FormControlLabel
                                    value="pan"
                                    control={<Radio />}
                                    label={intl.formatMessage({ id: 'flow_settings_drag_pan' })}
                                />
                                {flow_settings?.dragBehavior !== 'pan' && (
                                    <Typography style={styles.hint}>
                                        {intl.formatMessage(
                                            { id: 'flow_settings_pan_hint' },
                                            { key: flow_settings?.panKey || 'Space' }
                                        )}
                                    </Typography>
                                )}
                            </div>
                            <div style={styles.radio_item}>
                                <FormControlLabel
                                    value="selection"
                                    control={<Radio />}
                                    label={intl.formatMessage({ id: 'flow_settings_drag_select' })}
                                />
                                {flow_settings?.dragBehavior !== 'selection' && (
                                    <Typography style={styles.hint}>
                                        {intl.formatMessage(
                                            { id: 'flow_settings_selection_hint' },
                                            { key: flow_settings?.selectionKey || 'Shift' }
                                        )}
                                    </Typography>
                                )}
                            </div>
                        </RadioGroup>


                    </CardContent>
                </div>

                <div style={styles.section_box}>
                    <CardContent>
                        <Typography variant="subtitle1" gutterBottom>
                            {intl.formatMessage({ id: 'flow_settings_scroll_behavior' })}
                        </Typography>
                        <RadioGroup
                            value={flow_settings?.scrollBehavior || 'pan'}
                            onChange={(e) => update_flow_settings('scrollBehavior', e.target.value)}
                        >
                            <div style={styles.radio_item}>
                                <FormControlLabel
                                    value="zoom"
                                    control={<Radio />}
                                    label={intl.formatMessage({ id: 'flow_settings_scroll_zoom' })}
                                />
                                {flow_settings?.scrollBehavior !== 'zoom' && (
                                    <Typography style={styles.hint}>
                                        {intl.formatMessage(
                                            { id: 'flow_settings_zoom_hint' },
                                            { key: flow_settings?.zoomKey == 'Meta' && 'Control/Command' || flow_settings?.zoomKey || (isMac ? 'Control/Command' : 'Control') }
                                        )}
                                    </Typography>
                                )}
                            </div>
                            <div style={styles.radio_item}>
                                <FormControlLabel
                                    value="pan"
                                    control={<Radio />}
                                    label={intl.formatMessage({ id: 'flow_settings_scroll_pan' })}
                                />
                                {flow_settings?.scrollBehavior === 'pan' && (
                                    <Typography style={styles.hint}>
                                        {intl.formatMessage({ id: 'flow_settings_scroll_pan_horizontal_hint' })}
                                    </Typography>
                                )}
                            </div>
                        </RadioGroup>

                    </CardContent>
                </div>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!flow_settings?.disablePinchToZoom}
                            onChange={(e) => update_flow_settings('disablePinchToZoom', !e.target.checked)}
                        />
                    }
                    label={intl.formatMessage({ id: 'flow_settings_pinch_zoom' })}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!flow_settings?.deleteNodeDisabled}
                            onChange={(e) => update_flow_settings('deleteNodeDisabled', !e.target.checked)}
                        />
                    }
                    label={intl.formatMessage({ id: 'flow_settings_delete_node' })}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!flow_settings?.hideHintPanel}
                            onChange={(e) => update_flow_settings('hideHintPanel', !e.target.checked)}
                        />
                    }
                    label={intl.formatMessage({ id: 'flow_settings_show_hint_panel' })}
                />

                <Divider style={{
                    marginTop: 10,
                    marginBottom: 20
                }} />

                <div style={styles.item} >
                    <div>{intl.formatMessage({ id: 'settings_default_query_type_desc' }) + ':'}</div>
                    <Selector
                        options={[{ label: intl.formatMessage({ id: 'node_action_dynamic' }), value: 'dynamic' }, { label: intl.formatMessage({ id: 'node_action_brainstorming' }), value: 'brainstorming' }]}
                        value={flow_settings?.defaultQueryType || 'brainstorming'}
                        inputStyle={{
                            border: '1px solid #ccc',
                            borderRadius: '3px',
                        }}

                        onChange={(value) => {
                            update_flow_settings('defaultQueryType', value)
                        }}
                    />
                </div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!flow_settings?.hideExamples}
                            onChange={(e) => update_flow_settings('hideExamples', !e.target.checked)}
                        />
                    }
                    label={intl.formatMessage({ id: 'flow_settings_show_examples' })}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={flow_settings?.brainstormReqClarify}
                            onChange={(e) => update_flow_settings('brainstormReqClarify', e.target.checked)}
                        />
                    }
                    label={intl.formatMessage({ id: 'flow_settings_brainstorming_req_clarify' })}
                />
            </div>
        </div>
    );
};

const styles = {
    section: {
        marginBottom: '30px',
        display: 'flex',
        flexDirection: 'column'
    },
    formControl: {
        marginTop: '15px',
        marginBottom: '15px',
        minWidth: 200,
        display: 'block',
    },
    hint: {
        fontSize: '0.875rem',
        color: '#666',
    },

    section_title: {
        fontWeight: '500',
        fontSize: 24,
        whiteSpace: 'nowrap',
        paddingTop: 34,
        paddingBottom: 12,
    },

    section_box: {
        padding: 14,
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
        border: '1px solid #e3e3e3',
        borderRadius: 6,
        marginBottom: 20
    },

    radio_item: {
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
        columnGap: '10px',
    },

    item: {
        paddingTop: '15px',
        paddingBottom: '15px',
        columnGap: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: 450,
    }
};

export default FlowSettings;