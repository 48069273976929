'use strict';
import * as types from '../constants/actionTypes';

const initialState = {
  status: '点击登录',
  user: {},
}

export default function loginIn(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_ACTIONS.in:
      return {
        ...state,
        status: '登陆成功',
        user: {
          ...state.user,
          ...action.item,
        }
      }
    case types.LOGIN_ACTIONS.out:
      return {
        ...state,
        status: '未登录',
        user: {},
      }
    case types.LOGIN_ACTIONS.workspace:
      return {
        ...state,
        user: {
          ...state.user,
          workingOrgId: action.item.orgId
        },
      }
    default:
      return state;
  }
}
