/* @flow */

import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as TimeFormater from '../../utils/timeFormater';
import { CONFIRM_DIALOG, PROMPTS_DIALOG, PROMPT_DIALOG } from "src/constants/actionTypes";
import { deletePrompt, pinPrompt, upsertPrompt, validatePrompt } from "src/actions/ticketAction";
import { useIntl } from "react-intl";
import { PencilSquare } from '@styled-icons/bootstrap/PencilSquare';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { PinAngle } from '@styled-icons/bootstrap/PinAngle';
import { PinAngleFill } from '@styled-icons/bootstrap/PinAngleFill';
import { OpenInFull } from '@styled-icons/material/OpenInFull';
import { CopyAdd } from '@styled-icons/fluentui-system-regular/CopyAdd';
import { Button, Tooltip } from "@mui/material";
import { Share } from '@styled-icons/ionicons-outline/Share'
import { ShareButton } from "../flow/Share";
import { MOBILE_MEDIA_QUERY } from "../../utils/constants";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { ADD_FLOW_NODE, AI_ASSISTANT_DIALOG, SETTINGS_DIALOG } from "../../constants/actionTypes";

const PromptCard = ({ item, board, container }) => {

  const dispatch = useDispatch();
  const intl = useIntl();

  const promptsDialogState = useSelector(state => state.uiState.promptsDialog) || {};
  const loginUser = useSelector(state => state.loginIn && state.loginIn.user);
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const currentLocation = useLocation();
  const params = new Proxy(new URLSearchParams(currentLocation.search), {
    get: (searchParams, prop) => searchParams.get(prop) || '',
  });
  const docs = useSelector(state => state.docs);


  const showPromptModal = (item, mode) => {
    dispatch({
      type: PROMPT_DIALOG,
      value: {
        visible: true,
        data: item,
        mode
      }
    })
  };

  const removePrompt = (item) => {
    dispatch({
      type: CONFIRM_DIALOG, value: {
        visible: true,
        handleConfirm: () => dispatch(deletePrompt({ _id: item._id })),
        content: intl.formatMessage({ id: 'confirm_delete_item' })
      }
    })
  }

  const togglePinPrompt = (item, pin) => {
    dispatch(pinPrompt({ _id: item._id, pin }, null, 'prompts'));
  }

  const verifyPrompt = (item, qualified) => {
    dispatch(validatePrompt({ data: { _id: item._id, qualified: qualified ? 1 : -1 } }, null, 'prompts'))
  }

  const runPrompt = useCallback((item) => {
    dispatch({ type: SETTINGS_DIALOG, value: { visible: false } });

    const docType = params.hid && docs.byId[params.hid]?.type;
    const pathname = window.location.pathname;

    if (docType === 'doc') {
      dispatch({
        type: AI_ASSISTANT_DIALOG,
        value: {
          caller: 'plate',
          visible: true,
          trigger: 'cmd',
          hid: params.hid,
          action: item._id
        }
      });
    } else if (docType === 'flow' || window.location.href.includes('/flow') || window.location.href.includes('/aiflow')) {
      dispatch({
        type: ADD_FLOW_NODE,
        value: {
          action: item._id,
          title: item.name
        }
      })
    } else {
      dispatch({
        type: PROMPTS_DIALOG,
        value: {
          ...promptsDialogState,
          visible: false,
          prompt: item,
          refresher: Math.random()
        }
      })
    }
  }, [params.hid, docs]);

  const clonePrompt = (item) => {
    item = { ...item };
    delete item._id;
    delete item.userId;
    delete item.orgId;
    delete item.qualified;
    delete item.__v;
    delete item.createdAt;
    delete item.updatedAt;
    delete item.content_source;

    dispatch({
      type: PROMPT_DIALOG,
      value: {
        visible: true,
        data: item,
        mode: 'clone'
      },
    })
  }

  const time = item.updatedAt && TimeFormater.formatFromNow(item.createdAt, 'MM-DD') || 'just now';
  return (
    <div style={styles.container}>
      <div style={{ ...styles.title, position: 'relative' }}>
        {item.name}
        <div style={{ color: 'darkgray' }}>
          <Tooltip title={intl.formatMessage({ id: 'open' })} placement="top-start">
            <div
              onClick={() => showPromptModal(item, 'read')}
              className="hoverStand1"
              style={{ ...styles.iconButton, position: 'absolute', right: '6px', top: '7px' }}
            >
              <OpenInFull size={16} />
            </div>
          </Tooltip>
        </div>

      </div>
      <div style={styles.source}>
        {
          board === 'mine' ?
            intl.formatMessage({ id: 'visibility' }) + ': ' + intl.formatMessage({ id: !item.visibility ? 'visibility_private' : 'visibility_public' }) :
            '@' + item.userId?.nickname
        }
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '8px',
        paddingBottom: '2px',
        rowGap: '16px',
        flex: 1
      }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', rowGap: '12px' }}>
          {
            item.desc?.trim() &&
            <div className={"text-container"} style={{ fontSize: '15px', color: 'gray' }}>{item.desc?.trim()}</div>
          }
          {
            (!item.desc?.trim() || board == 'validate') &&
            <div className={board != 'validate' ? "text-container" : undefined} style={{ fontSize: '15px', color: 'gray' }}>{item.prompt}</div>
          }
        </div>
        {
          board == 'validate' && item.args &&
          <div>
            <table style={{ borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th>{intl.formatMessage({ id: 'arg_name' })}</th>
                  <th>{intl.formatMessage({ id: 'arg_label' })}</th>
                  <th>{intl.formatMessage({ id: 'arg_hint' })}</th>
                  <th>{intl.formatMessage({ id: 'arg_required' })}</th>
                </tr>
              </thead>
              <tbody>
                {
                  item.args?.length === 0 &&
                  <tr>
                    <td colSpan={5}>{intl.formatMessage({ id: 'prompt_no_arg' })}</td>
                  </tr>
                }
                {item.args?.map((arg, index) => (
                  <tr key={index}>
                    <td>{'var_' + index}</td>
                    <td>{arg.label}</td>
                    <td>{arg.hint}</td>
                    <td>{intl.formatMessage({ id: arg.required ? 'yes' : 'no' })}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }
        {
          board == 'validate' &&
          <div style={{ display: 'flex', flexDirection: 'row', columnGap: '26px', justifyContent: 'flex-end', paddingRight: '6px' }}>
            <Button
              variant="outlined"
              onClick={() => verifyPrompt(item, false)}
            >
              {intl.formatMessage({ id: 'prompt_not_qualified' })}
            </Button>
            <Button
              variant="contained"
              onClick={() => verifyPrompt(item, true)}
            >
              {intl.formatMessage({ id: 'prompt_qualified' })}
            </Button>
          </div>
        }
        {
          board != 'validate' &&
          <div style={{ display: 'flex', flexDirection: 'row', columnGap: '8px', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '6px' }}>
            {
              board === 'mine' && container !== 'drafter_panel' &&
              <Tooltip title={intl.formatMessage({ id: 'prompt_edit_tooltip' })} placement="bottom-start">
                <div
                  onClick={() => showPromptModal(item, 'edit')}
                  className="hoverStand"
                  style={styles.iconButton}
                >
                  <PencilSquare size={16} style={styles.barIcon} />
                </div>
              </Tooltip>
            }

            {
              board === 'mine' && container !== 'drafter_panel' &&
              <Tooltip title={intl.formatMessage({ id: 'prompt_delete_tooltip' })} placement="bottom-start">
                <div
                  onClick={() => removePrompt(item)}
                  className="hoverStand"
                  style={styles.iconButton}
                >
                  <Trash size={16} style={styles.barIcon} />
                </div>
              </Tooltip>
            }

            {
              board !== 'mine' &&
              loginUser?._id != item.userId?._id &&
              <Tooltip title={intl.formatMessage({ id: item.pinned ? 'prompt_unpin_tooltip' : 'prompt_pin_tooltip' })} placement="bottom-start">
                <div
                  onClick={() => togglePinPrompt(item, !item.pinned)}
                  className="hoverStand"
                  style={styles.iconButton}
                >
                  {
                    item.pinned &&
                    <PinAngleFill size={16} style={{ color: 'dodgerblue' }} />
                  }
                  {
                    !item.pinned &&
                    <PinAngle size={16} style={{ color: 'dodgerblue' }} />
                  }
                </div>
              </Tooltip>
            }
            {
              container !== 'drafter_panel' &&
              <Tooltip title={intl.formatMessage({ id: 'prompt_clone_tooltip' })} placement="bottom-start">
                <div
                  onClick={() => clonePrompt(item)}
                  className="hoverStand"
                  style={styles.iconButton}
                >
                  <CopyAdd size={18} style={{ color: 'dodgerblue' }} />
                </div>
              </Tooltip>
            }
            {
              container !== 'drafter_panel' &&
              <ShareButton
                triggerEle={
                  <div
                    className="hoverStand"
                    style={styles.iconButton}
                  >
                    <Share size={18} style={{ color: 'dodgerblue' }} />
                  </div>
                }
                isMobile={isMobile}
                shareData={{ title: intl.formatMessage({ id: 'share_prompt_title' }, { prompt: item.name }), url: `${window.location.origin}/#/aiflow?promptId=${item._id}` }}
                beforeOnClick={() => new Promise((resolve) => {
                  setTimeout(() => {
                    resolve("Shareit!");
                  }, 2000);
                })}
              />
            }
            {
              // container === 'drafter_panel' &&
              <Button
                variant="contained"
                onClick={() => runPrompt(item)}
                style={{ marginBottom: '4px', padding: 0 }}
                color='success'
              >
                {intl.formatMessage({ id: 'run_prompt' })}
              </Button>
            }
          </div>
        }

      </div>
    </div>
  )
}

const styles = {
  container: {
    border: 'solid 1px #eee',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    backgroundColor: '#f3f3f3',
    padding: '8px',
    fontWeight: 'bold',
    color: '#555'
  },
  itemContent: {
    paddingHorizontal: 12,
  },
  barIcon: {
    color: 'dodgerblue'
  },
  seperator: {
    height: 1,
    backgroundColor: 'lightgray',
  },

  iconButton: {
    padding: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
    borderRadius: '6px'
  },

  source: {
    color: 'gray',
    fontSize: '14px',
    paddingLeft: '8px',
    paddingTop: '6px',
  },
};

export default PromptCard
