
'use strict';

import React from 'react';
import { connect } from 'react-redux';

import { OPERATION_RESULT_HANDLED } from './../constants/actionTypes';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';

class SpinnerAndToast extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMessage: false,
      message: ''
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { operationStatus, dispatch, statusCallbacker } = nextProps;

    if (statusCallbacker) {
      statusCallbacker(operationStatus.inProgress);
    }

    //enable toast auto hide...
    if (this.state.showMessage && !nextState.showMessage) {
      return true;
    }

    if (!operationStatus || operationStatus.statusHandled) {
      return false;
    }

    if (!operationStatus.inProgress && this.state.showMessage) {
      dispatch({ type: OPERATION_RESULT_HANDLED });
    }

    if (operationStatus.message) {
      this.setState({
        showMessage: true,
        message: operationStatus.message
      })
    }

    return true;
  }

  handleClose = (event, reason) => {
    this.setState({
      showMessage: false
    })
  };

  render() {
    let { statusCallbacker, operationStatus } = this.props;
    const { showMessage, message } = this.state;

    return (
      <div className='center-foreground'>
        {!statusCallbacker &&
          !operationStatus.isSilientAction &&
          operationStatus.inProgress &&
          <CircularProgress />
        }

        <Snackbar
          open={showMessage}
          autoHideDuration={3000}
          onClose={this.handleClose}
          message={message}
          key={'bottom center'}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  operationStatus: state.operationStatus
});

export default connect(mapStateToProps)(SpinnerAndToast);