import { Tooltip } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { CheckboxUnchecked } from '@styled-icons/fluentui-system-regular/CheckboxUnchecked';
import { CheckboxChecked } from '@styled-icons/fluentui-system-regular/CheckboxChecked';
import { Close } from '@styled-icons/material';
import { Selector } from '../common/Selector';
import useStoreWithUndo, { useStore } from './store';
import { useShallow } from 'zustand/react/shallow';
import { node_color_themes } from './ColorMenu';
import { FlowCascade } from '@styled-icons/entypo/FlowCascade';
import { SortableCard } from '../dndlist/SortableCard';
import update from 'immutability-helper';
import { selector } from './AINode';
import { Edit } from "@styled-icons/fluentui-system-regular/Edit";
import { TextLeft } from '@styled-icons/bootstrap/TextLeft'
import { FLOW_INPUT_MODAL } from '@/constants/actionTypes';
import { useDispatch } from 'react-redux';
import ReactGA from "react-ga4";

const TodoItem = ({ item, index, nodeId, onClick, onCheck, onUpdatePriority, onRemoveTask, onTaskBreakdown, priorities, color_theme, moveCard }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [hovered, setHovered] = React.useState();

  const Check = item.done ? CheckboxChecked : CheckboxUnchecked;
  return <SortableCard
    index={index}
    id={item.id}
    moveCard={moveCard}
  >
    <div
      style={{
        position: 'relative',
        border: `1px solid ${color_theme.border}`,
        backgroundColor: color_theme.content_bg,
        boxShadow: `5px 5px 0px rgba(0, 0, 0, 10%)`,
        borderRadius: 4,
        padding: '6px',
        paddingLeft: 4,
        paddingTop: 3,
        paddingBottom: 0,
        fontSize: 13,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 4
      }}

      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        style={{
          cursor: 'pointer'
        }}
        onClick={(event) => {
          event.stopPropagation();
          onCheck();
        }}
      >
        <Check color='#333' size={18} />
      </div>
      <div
        className='fill-available'
      >
        <div
          style={{
            textDecoration: item.done ? 'line-through' : 'none',
            color: item.done ? 'GrayText' : undefined,
            marginTop: 2,
            marginBottom: 1
          }}
        >
          {item.description}
        </div>

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          columnGap: 3,
          marginTop: 2,
          fontSize: 11,
        }}>
          {item.dueDate && <div>{intl.formatMessage({ id: 'due_date' })}: {item.dueDate}</div>}
          {priorities &&
            <div style={{
              color: 'GrayText',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
              {intl.formatMessage({ id: 'priority' })}:
              <Selector
                options={priorities.map(priority => {
                  return {
                    label: priority,
                    value: priority
                  };
                })}
                value={item.priority}
                onChange={onUpdatePriority}
                labelStyle={{
                  fontSize: 11
                }}
                inputStyle={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  paddingTop: 0,
                  paddingBottom: 0,
                }} />
            </div>}
          <Tooltip
            title={intl.formatMessage({ id: 'flow_task_analysis_placeholder' })}
            placement='bottom'
          >
            <div
              className='transparent-background'
              style={{
                padding: 2
              }}
              onClick={() => {
                !item.done && onClick();
              }}
            >
              <TextLeft size={15} color={color_theme.border} />
            </div>
          </Tooltip>
          <Tooltip
            title={intl.formatMessage({ id: 'breakdown_task' })}
            placement='bottom'
          >
            <div
              className='transparent-background'
              style={{
                padding: 2
              }}
              onClick={(event) => {
                event.stopPropagation();
                onTaskBreakdown();
              }}
            >
              <FlowCascade size={13} color={color_theme.border} />
            </div>
          </Tooltip>
        </div>
      </div>
      {
        hovered &&
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'absolute',
          top: 0,
          right: 0,
          columnGap: 3
        }}>
          <div
            className='transparent-background'
            style={{
              width: 16,
              height: 16,
              cursor: 'pointer'
            }}
            onClick={(event) => {
              event.stopPropagation();
              dispatch({
                type: FLOW_INPUT_MODAL,
                value: {
                  id: 'todo_text',
                  index,
                  value: item.description,
                  nodeId,
                  multiline: true,
                  visible: true
                }
              })
            }}
          >
            <Edit color='gray' size={14} />
          </div>
          <Tooltip
            title={intl.formatMessage({ id: 'remove_task' })}
            placement='top'
          >
            <div
              className='transparent-background'
              style={{
                width: 16,
                height: 16,
                cursor: 'pointer'
              }}
              onClick={onRemoveTask}
            >
              <Close color='gray' size={14} />
            </div>
          </Tooltip>
        </div>
      }
    </div>
  </SortableCard>;
};
export const TodoList = ({ nodeId, list, priorities, listType, queryType, nodeType, aiItemClicked }) => {
  const [items, setItems] = React.useState(list);

  React.useEffect(() => {
    setItems(list);
  }, [list]);

  const moveCard = React.useCallback((dragIndex, hoverIndex) => {
    setItems((prevCards) => update(prevCards, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevCards[dragIndex]],
      ],
    })
    );
  }, []);

  const { updateNodeData } = useStoreWithUndo(
    useShallow(selector)
  );

  const { setSavingTrigger } = useStore(useShallow(state => ({
    setSavingTrigger: state.setSavingTrigger
  })));

  const handleCheck = React.useCallback((item) => {
    let todos = [...items];

    let todo = todos.find(it => it.id === item.id);
    todo.done = !todo.done;

    updateNodeData(nodeId, {
      todos
    });
  });

  const handleUpdatePriority = React.useCallback((item, priority) => {
    let todos = [...items];

    let todo = todos.find(it => it.id === item.id);
    todo.priority = priority;

    updateNodeData(nodeId, {
      todos
    });
  });

  const handleRemoveTask = React.useCallback((item) => {
    let todos = [...items];

    todos = todos.filter(it => it.id !== item.id);

    updateNodeData(nodeId, {
      todos
    });

    setSavingTrigger(Math.random());
  });

  return <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
      paddingRight: 10,
      marginTop: 5,
      marginBottom: 5,
      cursor: 'move'
    }}

    onDrop={(event) => {
      // updateProperties(items, 'moveCard');
      updateNodeData(nodeId, {
        todos: items
      });
    }}
  > {items?.map((item, index) => {
    if (!item) return;

    const priority_index = priorities?.findIndex(prio => prio === item.priority);
    const color_theme = node_color_themes.find(theme => theme.id === (item.done && 'gray' || priority_index === 0 && 'purple' || priority_index === 2 && 'blue' || 'green'));

    return <TodoItem
      key={item.id + ''}
      index={index}
      nodeId={nodeId}
      list={list}
      item={item}
      listType={listType}
      priorities={priorities}
      color_theme={color_theme}
      moveCard={moveCard}
      onCheck={() => { handleCheck(item); }}
      onTaskBreakdown={() => {
        ReactGA.event({ category: 'TodoListItem', action: 'flow_task_breakdown', label: listType });

        aiItemClicked({ item: { action: 'flow_task_breakdown' }, nodeType, queryType, userInput: item.description, color_theme });
      }}
      onClick={() => {
        ReactGA.event({ category: 'TodoListItem', action: 'flow_task_analysis', label: listType });

        aiItemClicked({ item: { action: 'flow_task_analysis' }, nodeType, queryType, userInput: item.description, color_theme });
      }}
      onUpdatePriority={(priority) => handleUpdatePriority(item, priority)}
      onRemoveTask={() => handleRemoveTask(item)} />;
  })}
  </div>;
};
