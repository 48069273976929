import { Divider, MenuItem, Select, Switch, TextField } from "@mui/material";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { updateRILBindedOrgId } from "src/actions/ticketAction";
import { DB_CAPABILITY_ENABLED } from "src/constants/actionTypes";
import { getStateByUser } from "src/reducers/listReducer";

const Labs = () => {
    const dispatch = useDispatch();
    const db_enabled = useSelector(state => state.uiState.db_enabled);
    const intl = useIntl();

    const handleChange = (event) => {
        dispatch({
            type: DB_CAPABILITY_ENABLED,
            value: event.target.checked
        })
    };

    return (
        <div style={{ paddingTop: '20px' }} >
            {intl.formatMessage({ id: "settings_db_capability_switch" })}
            <Switch
                checked={db_enabled}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </div>
    );
}

export default Labs;