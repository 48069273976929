import { ARTICAL_ACTIONS } from '../constants/actionTypes'

const init_state = {
    byId: {},
    allIds:[]
};

export function byId(state = {}, action) {
    switch (action.type) {
        case ARTICAL_ACTIONS.updated:
        case ARTICAL_ACTIONS.received:
            return Object.assign({}, state, { [action.item._id]: action.item });

        case ARTICAL_ACTIONS.invalidated:
            return Object.keys(state).reduce((result, key) => {
                if (key !== action.item._id) {
                    result[key] = state[key];
                }
                return result;
            }, {})
       
        default:
            return state;
    }
}

export function allIds(state=[], action) {
    let oldState = [...state];
    switch (action.type) {
        case ARTICAL_ACTIONS.invalidated:
            return oldState.filter(id =>
                id !== action.item._id
            );

        case ARTICAL_ACTIONS.received:
            oldState = oldState.filter(x => x!==action.item._id);
            oldState.unshift(action.item._id);
            return oldState;
       
        default:
            return state;
    }
}


