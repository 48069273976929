import { Trash } from "@styled-icons/bootstrap";
import { Edit } from "@styled-icons/fluentui-system-regular/Edit";

export const EditableMenuItemActions = ({ item, onDelete, onEdit }) => {
    return <div style={{
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        alignItems: 'center'
    }}>
        <div
            className="transparent-background"
            style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 6, paddingBottom: 6 }}
            onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                onEdit(item);
            }}
        >
            <Edit size={16} />
        </div>
        <div
            className="transparent-background"
            style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 6, paddingBottom: 6 }}
            onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                onDelete(item);
            }}>
            <Trash size={15} />
        </div>
    </div>;
};
