const removeNewlinesFromJSON = (jsonStr) => {
    // 将对象转换为JSON字符串，并使用正则表达式去除换行符（仅限键和值之间的换行符）
    const cleanedJsonStr = jsonStr
        // .replace(/(?<=[:,\{\}\[\]])\s*\n\s*/g, '')
        .replace(/(\s*\n\s*)?(?=[\{\}\[\],:])/g, '').replace(/(?<=[\{\}\[\],:])(\s*\n\s*)?/g, '');

    // console.log({ jsonStr, cleanedJsonStr })

    return cleanedJsonStr;
}

const escapeControlChars = (str) => {
    const controlChars = /[\x00-\x1F\x7F]/g; // Regular expression to match control characters
    const replacer = (match) => {
        const charCode = match.charCodeAt(0);
        switch (charCode) {
            case 0x08: // Backspace
                return '\\b';
            case 0x09: // Horizontal Tab
                return '\\t';
            case 0x0A: // Line Feed
                return '\\n';
            case 0x0C: // Form Feed
                return '\\f';
            case 0x0D: // Carriage Return
                return '\\r';
            case 0x1B: // Escape
                return '\\e';
            default:
                return '\\x' + charCode.toString(16).padStart(2, '0');
        }
    };
    return str.replace(controlChars, replacer);
}

const formatMarkdownOrderedList = (markdownText) => {
    // 正则表达式匹配模式：^(\*\*\d+\.\s*)(.+?)(\*\*)$
    // 解释：
    // ^ 匹配行的开始
    // (\*\*\d+\.\s*) 匹配 "**数字." 和可能的空格（第一个捕获组）
    // (.+?) 非贪婪匹配任何字符（第二个捕获组，列表项内容）
    // (\*\*)$ 匹配行末的 "**"（第三个捕获组）
    const regex = /^(\*\*\d+\.\s*)(.+?)(\*\*)$/gm;

    // 使用替换函数
    return markdownText.replace(regex, (match, start, content, end) => {
        // 移除开头的 "**"，保留数字和点
        const formattedStart = start.replace(/^\*\*/, '');
        return `${formattedStart}**${content}**`;
    });
}

const escapeUnescapedQuotesInArray = (str) => {
    // 正则匹配数组
    // const arrayPattern = /\[(.*?)\]/g;
    const arrayPattern = /\[((?:\{.*?\}|[^\[\]]*?))\]/g;
    let result = str;
    result = result.replace(arrayPattern, (match, content) => {
        // 如果匹配的内容以 { 开头，说明是 JSON 对象数组，直接返回不处理
        if (content.trim().startsWith('{')) {
            return match;
        }
        // 处理普通数组
        let elements = content.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
        elements = elements.map(element => {
            return element.trim()
                .replace(/(^"|"$)/g, '') // 去除开头和结尾的引号，用于处理内容
                .replace(/([^\\])"/g, '$1\\"'); // 找出未转义的引号并转义
        });
        // 将元素重新组合为数组字符串
        return '[' + elements.map(e => `"${e}"`).join(',') + ']';
    });
    return result;
  }

const escapeQuoteFromValue = (str) => {
    str = escapeUnescapedQuotesInArray(str)
    return str.replace(/([{,]\s*")([^"]+)":\s*"(.*?)"\s*(?=[,\]}])/gs, function(match, p1, p2, p3) {
        // 对没有被转义的双引号进行转义
        let escapedValue = p3.replace(/([^\\])"/g, '$1\\"');

        return `${p1}${p2}": "${escapedValue}"`;
      });
}

export const extractJSONFromString = (str) => {
    let stack = [];
    let startIndex = -1;
    let endIndex = -1;

    for (let i = 0; i < str.length; i++) {
        if (str[i] === "{") {
            if (stack.length === 0) {
                startIndex = i;
            }
            stack.push("{");
        } else if (str[i] === "}" && stack.length > 0) {
            stack.pop();
            if (stack.length === 0) {
                endIndex = i;
                break; // 找到第一个完整的 JSON 对象，提前结束循环
            }
        }
    }

    if (startIndex == -1 || endIndex == -1) return null;

    const jsonString = str.substring(startIndex, endIndex + 1);

    let parsedJSON;
    try {
        parsedJSON = JSON.parse(jsonString);
        return parsedJSON;
    } catch (error) {
        try {
            parsedJSON = JSON.parse(formatMarkdownOrderedList(escapeControlChars(removeNewlinesFromJSON(jsonString))));
            return parsedJSON;
        } catch (err) {
            try {
                let processedStr = escapeQuoteFromValue(formatMarkdownOrderedList(escapeControlChars(removeNewlinesFromJSON(jsonString))));
                parsedJSON = JSON.parse(processedStr);
                return parsedJSON;
            } catch (err1) {
                console.error('failed to parse json...........', err1, jsonString)
            }
        }
    }
}
