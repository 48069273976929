import { useIntl } from "react-intl";
import ServiceSubscribePanel from "./ServiceSubscribePanel";

const FunBlocksServiceSubscribe = () => {
    const intl = useIntl();
    const services = ['funblocks'];

    return (
        <div className="fill-available" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', maxWidth: 1160, alignSelf: 'center', paddingTop: 30 }}>
            <h1>{intl.formatMessage({ id: 'funblocks_product_slogan' })}</h1>
            <span style={{ fontSize: '18px', color: '#666' }}>{intl.formatMessage({ id: 'funblocks_promotion_slogan' })}</span>

            <ServiceSubscribePanel services={services} />
        </div>
    );
}

export default FunBlocksServiceSubscribe;