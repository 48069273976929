import { useState, useCallback, useRef } from 'react';
import { useStore, getBezierPath, EdgeLabelRenderer, useReactFlow } from '@xyflow/react';
import { getEdgeParams } from './utils.js';
import { Close } from '@styled-icons/material/Close';

function SimpleFloatingEdge({ id, source, target, markerEnd, style }) {
  const [isHovered, setIsHovered] = useState(false);
  const sourceNode = useStore(useCallback((store) => store.nodeLookup?.get(source), [source]));
  const targetNode = useStore(useCallback((store) => store.nodeLookup?.get(target), [target]));
  const { setEdges } = useReactFlow();
  const edgeRef = useRef(null);

  const onEdgeClick = useCallback((event) => {
    event.stopPropagation();
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
  }, [setEdges, id]);

  const onEdgeMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const onEdgeMouseLeave = useCallback((event) => {
    // 检查鼠标是否真的离开了整个边缘区域，包括按钮
    if (!edgeRef.current.contains(event.relatedTarget)) {
      setIsHovered(false);
    }
  }, []);

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  return (
    <g ref={edgeRef} onMouseEnter={onEdgeMouseEnter} onMouseLeave={onEdgeMouseLeave}>
      <path
        id={`${id}-selector`}
        className="react-flow__edge-selector"
        d={edgePath}
        strokeWidth={20}
        fill="none"
        stroke="transparent"
      />
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        strokeWidth={5}
        markerEnd={markerEnd}
        style={{
          ...style,
          stroke: isHovered ? '#555' : style?.stroke,
        }}
      />
      {isHovered && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              pointerEvents: 'all',
            }}
            className="nodrag nopan"
          >
            <div className="edgebutton" onClick={onEdgeClick}>
              <Close size={14} />
            </div>
          </div>
        </EdgeLabelRenderer>
      )}
    </g>
  );
}

export default SimpleFloatingEdge;