import {OPERATION_IN_PROGRESS, OPERATION_FAILED, OPERATION_SUCCESS, OPERATION_RESULT_HANDLED} from '../constants/actionTypes'

export const init_operation_state = {
    inProgress: false,
    statusHandled: true,
    message: '',
    navBack: false,
    screen: '',
    isSilientAction: false,
};

export function operationStatus(state = init_operation_state, action) {
    switch (action.type) {
        case OPERATION_IN_PROGRESS:
            return Object.assign({}, state, {
                inProgress: true,
                statusHandled: false,
                message: '',
                navBack: action.navBack,
                screen: action.screen,
                isSilientAction: action.isSilientAction,
            });
        case OPERATION_FAILED:
            return Object.assign({}, state, {
                inProgress: false,
                statusHandled: false,
                message: action.message,
                navBack: action.navBack,
                isSilientAction: false
            });
        case OPERATION_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                statusHandled: false,
                message: action.message,
                navBack: action.navBack,
                isSilientAction: false

            });
        case OPERATION_RESULT_HANDLED: 
            return Object.assign({}, init_operation_state);
        default:
            return state
    }
}