import * as React from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { INVITE_FRIENDS_DIALOG, SETTINGS_DIALOG } from 'src/constants/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { DialogContent, Dialog, Button, Avatar, IconButton, Menu, MenuItem } from '@mui/material';
import { getStateByUser } from 'src/reducers/listReducer';
import SpinnerAndToast from './SpinnerAndToast';
import RILSettings from './settings/RILSettings';
import { useIntl } from 'react-intl';
import ServiceSubscribe from './settings/ServiceSubscribe';
import Market from './settings/Market';
import Labs from './settings/Labs';
import APISettings from './settings/APISettings';
import Prompts from './settings/Prompts';
import { getLngList, updateUserName } from 'src/actions/ticketAction';
import { StripeCheckoutModal } from './settings/StripeCheckoutModal';
import { isCNDomain } from 'src/utils/constants';
import { blueGrey, grey, lightBlue } from '@mui/material/colors';
import AIServiceSubscribe from './settings/ServiceSubscribe_ai_en';
import FunBlocksServiceSubscribe from './settings/ServiceSubscribe_funblocks_en';
import { Edit } from "@styled-icons/fluentui-system-regular/Edit";
import { PopoverInput } from './common/PopoverInput';
import ReactGA from "react-ga4";
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MEDIA_QUERY } from '../utils/constants';
import { Menu as MenuIcon } from '@styled-icons/material/Menu';
import Account from './settings/General';
import FlowSettings from './settings/FlowSettings';
import Space from './settings/Space';
import Members from './settings/Members';
import MoreSettings from './settings/MoreSettings';
import SpaceIntro from './settings/SpaceIntro';
import Affiliate from './settings/Affiliate';

const drawerWidth = 200;

const SettingsModal = () => {
    const settingsDialogState = useSelector(state => state.uiState.settingsDialog) || {};
    const { app } = settingsDialogState;
    const dispatch = useDispatch();
    const intl = useIntl();

    const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

    const loginUser = useSelector(state => state.loginIn.user);
    const orgs = useSelector(state => getStateByUser(state.org_lists, loginUser));
    const workingSpace = orgs.items.find(org => org._id === loginUser.workingOrgId) || {};
    const isAdmin = !!(workingSpace.users && workingSpace.users.find(user => !!user.user && user.user._id == loginUser._id && user.role === 'admin'));
    const lng = useSelector(state => state.uiState.lng) == 'cn' ? 'zh' : 'en';

    const [btn_hovered, set_btn_hovered] = React.useState({});
    const [currentPage, setCurrentPage] = React.useState({});

    const handleClose = () => {
        dispatch({ type: SETTINGS_DIALOG, value: { visible: false } });
    }

    const toPage = (item) => {
        dispatch({
            type: SETTINGS_DIALOG,
            value: {
                ...settingsDialogState,
                page: item.id
            }
        });
    }

    React.useEffect(() => {
        dispatch(getLngList({}));
    }, [])

    React.useEffect(() => {
        settingsDialogState?.visible && ReactGA.send({ hitType: "pageview", page: "/settings", title: app });
    }, [settingsDialogState?.visible])

    const accountSettings = React.useMemo(() => [{
        id: 'account',
        text: intl.formatMessage({ id: 'settings_my_account_menu' }),
        title: intl.formatMessage({ id: 'settings_my_account_title' }),
        page: <Account />
    }, isCNDomain() ? undefined : {
        id: 'service_subscribe_funblocks',
        text: intl.formatMessage({ id: 'service_subscribe_funblocks' }),
        // title: intl.formatMessage({ id: 'service_subscribe_title'}),
        page: <FunBlocksServiceSubscribe />
    }, {
        id: 'service_subscribe',
        text: intl.formatMessage({ id: isCNDomain() ? 'service_subscribe' : 'service_subscribe_ai' }),
        // title: intl.formatMessage({ id: 'service_subscribe_title'}),
        page: isCNDomain() ? <ServiceSubscribe /> : <AIServiceSubscribe />
        // }, {
        //     id: 'service_market',
        //     text: intl.formatMessage({ id: 'service_market' }),
        //     // title: intl.formatMessage({ id: 'service_subscribe_title'}),
        //     page: <Market />
    }, {
        //     id: 'api_settings',
        //     text: intl.formatMessage({ id: 'settings_api_menu' }),
        //     title: intl.formatMessage({ id: 'settings_api_title' }),
        //     page: <LlmProvider />
        // }, {
        id: 'prompts',
        text: intl.formatMessage({ id: 'settings_prompts_menu' }),
        page: <Prompts />
    }, {
        id: 'ril_settings',
        text: intl.formatMessage({ id: 'settings_ril_menu' }),
        title: intl.formatMessage({ id: 'settings_ril_title' }),
        page: <RILSettings />
    }, {
        id: 'flow_settings',
        text: intl.formatMessage({ id: 'settings_flow_menu' }),
        title: intl.formatMessage({ id: 'settings_flow_title' }),
        page: <FlowSettings />
    }, {
        id: 'lng_settings',
        text: intl.formatMessage({ id: 'settings_more_menu' }),
        title: intl.formatMessage({ id: 'settings_more_title' }),
        page: <MoreSettings />
    }].filter(item => {
        if (!item) return false;

        let filteredList = [];
        if (app == 'flow') {
            filteredList = ['service_subscribe_funblocks', 'ril_settings'];
        }

        if (isMobile) {
            filteredList.push('flow_settings');
        }

        return !filteredList.includes(item.id);
    }), [intl, app]);

    const spaceSettings = React.useMemo(() => {
        let items = [{
            id: 'space_settings',
            text: intl.formatMessage({ id: 'settings_space_menu' }),
            title: intl.formatMessage({ id: 'settings_space_title' }),
            page: <Space isAdmin={isAdmin} />
        }, {
            id: 'members',
            text: intl.formatMessage({ id: 'settings_members_menu' }),
            page: <Members isAdmin={isAdmin} />
        }, {
            id: 'labs',
            text: intl.formatMessage({ id: 'settings_labs_menu' }),
            title: intl.formatMessage({ id: 'settings_labs_menu' }),
            page: <Labs />
        }, {
            id: 'space_intro',
            text: intl.formatMessage({ id: 'space_intro' }),
            title: intl.formatMessage({ id: 'space_intro' }),
            page: <SpaceIntro />
        }]

        if (loginUser?.isAffiliate && !items.find(item => item.id === 'affiliate')) {
            items.push({
                id: 'affiliate',
                text: intl.formatMessage({ id: 'settings_affiliate_menu' }),
                page: <Affiliate />
            })
        }

        return items;
    }, [isAdmin, intl, loginUser?.isAffiliate]);

    const [itemHovered, setItemHovered] = React.useState();

    React.useEffect(() => {
        if (settingsDialogState?.page) {
            setCurrentPage(accountSettings.find(item => item?.id === settingsDialogState.page) || spaceSettings.find(item => item?.id === settingsDialogState.page))
        } else if (accountSettings) {
            setCurrentPage(accountSettings[0]);
        }
    }, [settingsDialogState, accountSettings])

    const [mobileMenuAnchor, setMobileMenuAnchor] = React.useState(null);
    // const [itemHovered, setItemHovered] = React.useState(null);
    // const [btn_hovered, set_btn_hovered] = React.useState({});

    const handleMobileMenuOpen = (event) => {
        setMobileMenuAnchor(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMenuAnchor(null);
    };

    const menuItemRenderer = (item, index, list_name) => {
        const itemContent = (
            <span style={{ color: '#222', fontSize: '15px', paddingLeft: isMobile ? 0 : 6 }}>
                {item.text}
            </span>
        );

        if (isMobile) {
            return (
                <MenuItem
                    key={item.text}
                    onClick={() => {
                        toPage(item);
                        handleMobileMenuClose();
                    }}
                >
                    {itemContent}
                </MenuItem>
            );
        }

        return (
            <div
                key={item.text}
                onClick={() => toPage(item)}
                style={{
                    backgroundColor: currentPage && currentPage.text === item.text ? '#ddd' : (itemHovered?.index === index && itemHovered?.list_name === list_name ? '#dedede' : 'transparent'),
                    padding: 6,
                    paddingBottom: 6,
                    marginBottom: 1,
                    marginLeft: 4,
                    borderRadius: 5,
                    cursor: 'pointer',
                }}
                onMouseEnter={() => setItemHovered({ index, list_name })}
                onMouseLeave={() => setItemHovered(null)}
            >
                {itemContent}
            </div>
        );
    }

    const renderSidebarContent = () => {
        const content = [
            <div key="account-header" style={{ color: '#999', fontSize: 14, padding: 4, paddingLeft: 16 }}>
                <span>{intl.formatMessage({ id: 'settings_account' })}</span>
            </div>,
            loginUser?._id && (
                <div key="user-info" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 6, paddingRight: isMobile ? 6 : undefined }}>
                    <Avatar sx={{ bgcolor: grey[400], width: 24, height: 24, marginLeft: 1, marginRight: 1 }} variant="circle">
                        <span style={{ fontSize: 14 }}>
                            {loginUser.nickname ? loginUser.nickname[0].toUpperCase() : 'U'}
                        </span>
                    </Avatar>
                    <div style={{ padding: 4, display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', columnGap: 6 }}>
                            {loginUser.nickname}
                            <PopoverInput
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                onSubmit={(value) => {
                                    const isNameValid = value && value.length > 2;
                                    if (isNameValid) {
                                        dispatch(updateUserName({ name: value }));
                                    }
                                }}
                                value={loginUser.nickname}
                            >
                                <div style={{ cursor: 'pointer' }}>
                                    <Edit size={15} color='#666' />
                                </div>
                            </PopoverInput>
                        </div>
                        <span style={{ color: 'gray', fontSize: 13 }}>{loginUser.username}</span>
                    </div>
                </div>
            ),
            ...accountSettings.filter(item => !!item).map((item, index) => menuItemRenderer(item, index, 'account')),
            <Divider key="divider" style={{ marginTop: 6, marginBottom: 6 }} />,
            <div key="workspace-header" style={{ color: '#999', fontSize: 14, padding: 4, paddingLeft: 16 }}>
                <span>{intl.formatMessage({ id: 'settings_workspace' })}</span>
            </div>
        ];

        if (app !== 'flow') {
            content.push(...spaceSettings.filter(item => item.id != 'space_intro').map((item, index) => menuItemRenderer(item, index, 'space')));
        } else {
            content.push(...spaceSettings.filter(item => ['space_intro', 'affiliate'].includes(item.id)).map((item, index) => menuItemRenderer(item, index, 'space')))
        }

        if (!isMobile) {
            content.push(
                <div
                    key="invite-friends"
                    className='fill-available'
                    style={{
                        border: '1px solid #ccc',
                        borderRadius: 8,
                        padding: 8,
                        margin: 4,
                        paddingTop: 10,
                        fontSize: 14,
                        position: 'absolute',
                        bottom: 30
                    }}>
                    Get free AI queries by
                    <div
                        style={{
                            border: '1px solid #bbb',
                            borderRadius: 8,
                            padding: 4,
                            marginTop: 10,
                            marginBottom: 6,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            color: btn_hovered?.btn === 'invite_friend' ? 'white' : undefined,
                            backgroundColor: btn_hovered?.btn === 'invite_friend' ? 'dodgerblue' : undefined,
                            fontWeight: 600
                        }}
                        onMouseEnter={() => set_btn_hovered({ btn: 'invite_friend' })}
                        onMouseLeave={() => set_btn_hovered({})}
                        onClick={() => {
                            dispatch({ type: SETTINGS_DIALOG, value: { visible: false } });
                            dispatch({ type: INVITE_FRIENDS_DIALOG, value: { visible: true } });
                        }}
                    >
                        <span>🎁&nbsp;</span>
                        {intl.formatMessage({ id: 'invite_friends' })}
                    </div>
                </div>
            );
        }

        if (isMobile) {
            content.push(<MenuItem
                key={'close'}
                onClick={handleClose}
            >
                {intl.formatMessage({ id: 'close' })}
            </MenuItem>);
        }

        return content;
    };

    return (
        <Dialog
            open={!!settingsDialogState && settingsDialogState.visible}
            onClose={handleClose}
            scroll='paper'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth='lg'
            fullScreen={isMobile}
        >
            <DialogContent dividers={true} style={{ display: 'flex', flexDirection: 'row', height: isMobile ? '100%' : 680, width: isMobile ? '100%' : 950, padding: 0 }}>
                {!isMobile && (
                    <Box
                        sx={{
                            width: drawerWidth,
                            paddingTop: '20px',
                            backgroundColor: '#f0f0f0',
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                            },
                            position: 'relative'
                        }}
                        variant="permanent"
                        anchor="left"
                    >
                        {renderSidebarContent()}
                    </Box>
                )}
                {isMobile && (
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleMobileMenuOpen}
                        style={{ position: 'absolute', left: 10, top: 4, zIndex: 1000 }}
                    >
                        <MenuIcon size={30} color='dodgerblue' />
                    </IconButton>
                )}
                <Menu
                    anchorEl={mobileMenuAnchor}
                    open={Boolean(mobileMenuAnchor)}
                    onClose={handleMobileMenuClose}
                >
                    {renderSidebarContent()}
                </Menu>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, height: '100%', width: '100%', padding: 0, overflowY: 'auto', paddingLeft: isMobile ? '0' : '15px' }}
                >
                    {!!currentPage?.title && (
                        <div style={{ display: 'flex', paddingTop: '2px', paddingRight: '10px', flexDirection: 'row', paddingLeft: isMobile ? 34 : 0 }}>
                            <div style={{ padding: '12px', paddingLeft: '10px', fontWeight: 500, color: '#555555', flex: 1 }}>
                                {currentPage.title}
                            </div>
                            {
                                !isMobile &&
                                <Button variant='text' onClick={handleClose}>{intl.formatMessage({ id: 'close' })}</Button>
                            }
                        </div>
                    )}
                    <Divider />
                    {
                        currentPage?.page &&
                        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                            {currentPage.page}
                        </div>
                    }
                </Box>
            </DialogContent>
            <SpinnerAndToast />
            <StripeCheckoutModal />
        </Dialog>
    );
}

export default SettingsModal;
