
import { newDoc } from "@/actions/ticketAction";
import { PROMPTS_DIALOG } from "@/constants/actionTypes";
import { linkToPage } from "@/utils/PageLinkMaker";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const PromptsModalDataHandler = () => {
    const promptsDialogState = useSelector(state => state.uiState.promptsDialog) || {};
    const dispatch = useDispatch();
    const history = useHistory();
    const loginUser = useSelector(state => state.loginIn && state.loginIn.user);

    useEffect(() => {
        const { prompt, title, space } = promptsDialogState;
        if (!prompt) {
            return;
        }

        dispatch(newDoc({
            data: {
                space,
                orgId: loginUser.workingOrgId,
                doc: { parent: 'root', type: 'doc', title },
                isBlank: true
            }
        }, (doc) => {
            history.push(linkToPage(doc, { space, withAI: true, action: prompt.action || prompt._id, sub_item: prompt.sub_item }));
            dispatch({
                type: PROMPTS_DIALOG,
                value: {
                    visible: false
                }
            })
        }, 'editor'));
    }, [promptsDialogState])

    return null;
}