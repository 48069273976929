import * as React from 'react';
import { INVITE_FRIENDS_DIALOG } from 'src/constants/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { DialogContent, Dialog, Button, DialogTitle, Fab, Divider } from '@mui/material';
import InviteFriendsMessage from './InviteFriendsMessage';
import { useIntl } from 'react-intl';

const InviteFriendsModal = () => {
    const intl = useIntl();
    const dialogState = useSelector(state => state.uiState.inviteFriendsDialog) || { visible: false };
    const dispatch = useDispatch();
  
    const handleClose = () => {
        dispatch({ type: INVITE_FRIENDS_DIALOG, value: { visible: false } });
    }
    return (
        <Dialog
            open={!!dialogState && dialogState.visible}
            onClose={handleClose}
            scroll='paper'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth='md'
            style={{
                zIndex: 100,
            }}
        >
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 8 }}>
                    {intl.formatMessage({ id: "invite_friends" })}
                </div>
                <Button variant='text' onClick={handleClose}>{intl.formatMessage({ id: 'close' })}</Button>
            </div>
            <InviteFriendsMessage app={'flow'} />
        </Dialog>
    );
}

export default InviteFriendsModal;
