import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  XIcon
} from 'react-share';
import { Popover, Tooltip } from '@mui/material'
import { ShareForward2 } from '@styled-icons/remix-line/ShareForward2'
import { Link45deg } from '@styled-icons/bootstrap/Link45deg'
import { useDispatch } from 'react-redux';
import { FLOW_MODAL } from '@/constants/actionTypes';
import 'web-share-shim';
import ReactGA from "react-ga4";

const isWebShareSupported = !!navigator.share;

export const ShareButton = ({ shareData, beforeOnClick, isMobile, triggerEle }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState();
  const [copied, setCopied] = useState();

  const handleClose = () => {
    setAnchorEl(null);
  }

  const shareContent = shareData?.title;
  const shareUrl = shareData?.url;

  const copyUrl = () => {
    navigator.clipboard.writeText(shareUrl);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
      handleClose();
    }, 1000);
  }

  if (!shareData) return;

  return (<>
    <Tooltip
      title={intl.formatMessage({ id: 'share' })}
      placement="bottom">
      <div

        onClick={async (e) => {
          if (!shareUrl) {
            dispatch({
              type: FLOW_MODAL,
              value: {
                visible: true,
                action: 'login'
              }
            })
          } else {
            if (isWebShareSupported && isMobile) {
              try {
                return await navigator.share({
                  title: shareData.title,
                  text: shareData.title,
                  url: shareData.url
                })
              } catch (err) {
                console.log('分享失败:', err.message);
              }
            } else {
              setAnchorEl(e.currentTarget);
            }
          }

          ReactGA.event({ category: 'BoardButtons', action: 'share', label: 'show_share_panel' });
        }}>
        {
          triggerEle
        }
        {
          !triggerEle &&
          <div
            className='hoverStand'
            style={{
              padding: 6,
              position: 'relative', // 添加相对定位
            }}
          >
            <ShareForward2 size={18} />
          </div>
        }
      </div>
    </Tooltip>
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}

      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          columnGap: 6,
          padding: 8,
          paddingBottom: 2,
          borderRadius: 5,
          // boxShadow: `5px 5px 1px rgba(0, 0, 0, 10%)`,
          boxShadow: '0px 0px 8px #bbb',
          width: 'min-content'
          // backgroundColor: 'rgba(242,242,242, 0.8)',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            columnGap: 6,
          }}>
          <FacebookShareButton
            url={shareUrl}
            quote={shareContent}
            hashtag="FunBlocks_AI"
            beforeOnClick={beforeOnClick}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          {/* <FacebookMessengerShareButton
          url={shareUrl}
          appId="521270401588372"
          className="Demo__some-network__share-button"
        >
          <FacebookMessengerIcon size={32} round />
        </FacebookMessengerShareButton> */}
          <TwitterShareButton
            url={shareUrl}
            title={shareContent}
            hashtags={['FunBlocks_AI', 'AIFlow']}
            className="Demo__some-network__share-button"
          >
            <XIcon size={32} round />
          </TwitterShareButton>
          <WhatsappShareButton
            url={shareUrl}
            title={shareContent}

            separator=":: "
            className="Demo__some-network__share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <LineShareButton url={shareUrl} title={shareContent} className="Demo__some-network__share-button">
            <LineIcon size={32} round />
          </LineShareButton>

          <LinkedinShareButton
            url={shareUrl}
            title={shareData.title}
            summary={shareContent}
            source='https://funblocks.net'
            className="Demo__some-network__share-button"
            beforeOnClick={beforeOnClick}
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <RedditShareButton
            url={shareUrl}
            title={shareContent}
            windowWidth={660}
            windowHeight={460}
            className="Demo__some-network__share-button"
          >
            <RedditIcon size={32} round />
          </RedditShareButton>

          <EmailShareButton
            url={shareUrl}
            subject={shareData.title}
            body={shareContent}
            className="Demo__some-network__share-button"
          >
            <EmailIcon size={32} round />
          </EmailShareButton>

          <Tooltip
            title={intl.formatMessage({ id: copied ? 'link_copied' : 'copy_link' })}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 31,
                height: 31,
                borderRadius: 31,
                backgroundColor: 'rgb(127,127,127)',
                color: 'white',
                cursor: 'pointer'
              }}

              onClick={copyUrl}
            >
              <Link45deg size={22} />
            </div>
          </Tooltip>
        </div>
        <div
          style={{
            marginTop: 6,
            fontSize: 12,
            color: 'gray',
            paddingBottom: 5,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{
            padding: 4
          }}>
            <div
              style={{
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: 'tomato',
              }}
            />
          </div>
          {intl.formatMessage({ id: 'share_tip' })}
        </div>
      </div>
    </Popover>
  </>
  )
};

