import * as React from 'react';
import Box from '@mui/material/Box';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, MenuItem, FormControl, Select, OutlinedInput, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { switchWorkspace, updateUserRoleToOrg } from 'src/actions/ticketAction';
import { CONFIRM_DIALOG, INVITE_ORG_DIALOG, OPERATION_FAILED, ORGS_ACTIONS, SETTINGS_DIALOG } from 'src/constants/actionTypes';
import { getStateByUser } from 'src/reducers/listReducer';
import { useIntl } from 'react-intl';

const UsersTable = ({ isAdmin, users, descText, type }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const loginUser = useSelector(state => state.loginIn.user);
    const [filter, setFilter] = React.useState('');
    const [filteredUsers, setFilteredUsers] = React.useState([]);
    const adminNum = users.filter(user => user.role === 'admin').length;

    const ROLE_CHANGE_MENU_ITEMS = type === 'members' ? [
        { label: intl.formatMessage({ id: 'admin' }), value: 'admin' },
        { label: intl.formatMessage({ id: 'member' }), value: 'member' },
    ] : [
        { label: intl.formatMessage({ id: 'guest' }), value: 'guest' },
        { label: intl.formatMessage({ id: 'member' }), value: 'member' }
    ];

    const columns = [
        { id: 'nickname', label: intl.formatMessage({ id: 'user' }) },
        { id: 'role', label: intl.formatMessage({ id: 'role' }), align: 'right' }
    ]

    const RemoveMenuItem = ({ objId, orgId }) => {
        const intl = useIntl();
        const orgs = useSelector(state => getStateByUser(state.org_lists, loginUser));
        const updateRole = () => dispatch(updateUserRoleToOrg({ objId, action: 'remove' }, () => {
            if (objId != loginUser._id) {
                return;
            }

            const validOrgs = orgs.items.filter(org => org._id !== orgId);
            if (validOrgs.length === 0) {
                dispatch({ type: ORGS_ACTIONS.deleted, key: loginUser._id, _id: orgId, item: { _id: orgId } });
                dispatch({ type: SETTINGS_DIALOG, value: { visible: false } });
                return history.push('/newworkspace');
            }

            dispatch(switchWorkspace({ orgId: validOrgs[0]._id }, () => {
                dispatch({ type: ORGS_ACTIONS.deleted, key: loginUser._id, _id: orgId, item: { _id: orgId } });
                dispatch({ type: SETTINGS_DIALOG, value: { visible: false } });
            }, 'usertable'));

        }, 'members'))

        return <MenuItem onClick={
            (event) => {
                let content;
                if (objId == loginUser._id) {
                    if (users.length > 1) {
                        content = intl.formatMessage({ id: 'remove_self_from_org' });
                    } else {
                        content = intl.formatMessage({ id: 'remove_last_one_from_org' });
                    }
                }

                dispatch({ type: CONFIRM_DIALOG, value: { visible: true, content, handleConfirm: updateRole } });
                event.preventDefault();
                event.stopPropagation();
            }

        }>
            <span style={{ color: 'hotpink' }}>{objId == loginUser._id ? intl.formatMessage({ id: 'leave_team' }) : intl.formatMessage({ id: 'remove_from_team' })}</span>
        </MenuItem>;
    }

    const onFilterChange = (e) => {
        let filter = e.target.value || '';
        filter = filter.toLowerCase();
        setFilter(filter);
    }

    React.useEffect(() => {
        setFilteredUsers(users.filter(({ user }) => {
            return !!user && (user.nickname && user.nickname.toLowerCase().includes(filter)
                || user.username.toLowerCase().includes(filter))
        }));
    }, [users, filter]);

    const changeRole = (user, role) => dispatch(updateUserRoleToOrg({ objId: user._id, role }, null, 'members'));

    return (
        <Box sx={{ width: '100%' }}>
            <div style={{ color: 'gray', paddingBottom: '10px', fontSize: 14 }}>
                {descText}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                {
                    type === 'members' &&
                    <Button variant="contained" size='small' color="primary"
                        disabled={!isAdmin}
                        onClick={() => dispatch({ type: INVITE_ORG_DIALOG, value: { visible: true } })}>
                        {intl.formatMessage({ id: 'invite_user' })}
                    </Button>
                }
                {
                    type === 'guests' &&
                    <div />
                }
                {
                    users && users.length > 10 &&
                    <OutlinedInput
                        id="search"
                        placeholder="Filter by name or email"
                        style={{ width: '360px', padding: 0 }}
                        sx={{ padding: '0px' }}
                        size="small"
                        onChange={onFilterChange}
                    />
                }
            </div>
            <TableContainer sx={{ maxHeight: 540, marginTop: '8px' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: 'lightgray' }}>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    size='small'
                                    style={{ border:'none', borderBottom: '1px solid gray', fontWeight: 800, color: 'gray' }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.map(({ user, role }, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    <TableCell key={'user'} style={{ display: 'flex', flexDirection: 'column', padding: '3px', borderLeft: '0px', borderRight: '0px', borderTop: '0px' }}>
                                        <span>
                                            {user.nickname}
                                        </span>
                                        <span style={{ fontSize: '12px', color: 'gray' }}>
                                            {user.username}
                                        </span>
                                    </TableCell>

                                    <TableCell key={'role'} align={'right'} style={{ borderLeft: '0px', padding: '3px', borderRight: '0px', borderTop: '0px' }}>
                                        <FormControl variant="standard" disabled={!isAdmin && user._id != loginUser._id}>
                                            <Select
                                                labelId="member-operation"
                                                id="member-operation"
                                                value={role}
                                                onChange={(event) => {
                                                    if (user._id == loginUser._id && event.target.value == 'member') {
                                                        let content = intl.formatMessage({ id: 'change_role_to_member' });
                                                        if (adminNum < 2) {
                                                            return dispatch({ type: OPERATION_FAILED, message: intl.formatMessage({ id: 'last_admin_in_org' }) });
                                                        }
                                                        dispatch({
                                                            type: CONFIRM_DIALOG,
                                                            value: {
                                                                visible: true,
                                                                content,
                                                                handleConfirm: () => changeRole(user, event.target.value)
                                                            }
                                                        });
                                                    } else {
                                                        changeRole(user, event.target.value);
                                                    }
                                                }}
                                            >
                                                {ROLE_CHANGE_MENU_ITEMS.map((item, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={item.value}
                                                        disabled={!isAdmin}
                                                    >
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                                <RemoveMenuItem objId={user._id} orgId={loginUser.workingOrgId} />
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default UsersTable;