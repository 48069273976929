import {
    ITEMS_ACTIONS, GROUPS_ACTIONS, GROUP_MEMBERS_ACTIONS, FOLLOWERS_ACTIONS, POSTS_ACTIONS, TODOS_ACTIONS,
    MESSAGES_ACTIONS, getCommonListAction, SUB_TICKETS_ACTIONS, GROUP_TICKETS_ACTIONS,
    DOINGS_ACTIONS, DONES_ACTIONS, GROUP_TODOS_ACTIONS, GROUP_DOINGS_ACTIONS, GROUP_DONES_ACTIONS,
    TICKET_ACTIONS, LIST_KEYS, THREADS_ACTIONS, THREAD_MEMBERS_ACTIONS, TAGS_ACTIONS,
    ORGS_ACTIONS, GROUP_OKRS_ACTIONS, OKRS_ACTIONS, KEY_RESULTS_ACTIONS, COMPANY_OKRS_ACTIONS, PEOPLE_OKRS_ACTIONS,
    ALIGNED_OKRS_ACTIONS, MY_KRS_ACTIONS, GROUP_KRS_ACTIONS, PEOPLE_KRS_ACTIONS, COMPANY_KRS_ACTIONS,
    RILS_ACTIONS, ARTICLE_HIGHLIGHTS_ACTIONS, DELETED_RILS_ACTIONS, READ_RILS_ACTIONS, DELETED_TODOS_ACTIONS,
    SEARCHED_RILS_ACTIONS, SEARCHED_TODOS_ACTIONS, SLIDES_ACTIONS, DELETED_SLIDES_ACTIONS, DOCS_ACTIONS, DELETED_DOCS_ACTIONS,
    SUB_DOCS_ACTIONS,
    WORKSPACE_DOCS_ACTIONS,
    SHARED_DOCS_ACTIONS,
    PRIVATE_DOCS_ACTIONS,
    TO_CONFIRM_ORGS_ACTIONS,
    DOC_HISTORIES_ACTIONS,
    DBDATAS_ACTIONS,
    DB_VIEWS_ACTIONS,
    DB_VIEW_DATA_ORDER_ACTIONS,
    FEEDBACKS_ACTIONS,
    COMMENTS_ACTIONS,
    PROMPTS_ACTIONS,
    PUBLIC_PROMPTS_ACTIONS,
    PINNED_PROMPTS_ACTIONS,
    VALIDATE_PROMPTS_ACTIONS,
    WORKSPACE_PROMPTS_ACTIONS,
    INVITE_RECORDS_ACTIONS,
    FLOW_DOCS_ACTIONS
} from './../constants/actionTypes'

export const init_list_state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
    pageNum: 0,
    pageSize: 20,
    isEnd: true,
    newItems: 0,
};

export function isFetching(list_state) {
    return list_state.isFetching && list_state.lastRequested && ((new Date().getTime() - new Date(list_state.lastRequested).getTime()) < 30000);
}

let init_states = [];

export function post_lists(states = init_states, action) {
    return common_list(states, action, POSTS_ACTIONS);
}

export function thread_lists(states = init_states, action) {
    return common_list(states, action, THREADS_ACTIONS);
}

export function todo_lists(states = init_states, action) {
    if (action.type === TICKET_ACTIONS.updated) {
        let items = getState(states, action.key).items.slice();
        let index = items.findIndex(item => {
            return item.ticketId == action.item._id
        });

        if (index >= 0) {
            items[index] = { ...items[index], ticket: action.item };
            return setState(states, action.key, {
                items
            });
        }
    }

    return common_list(states, action, TODOS_ACTIONS);
}

export function doing_lists(states = init_states, action) {
    if (action.type === TICKET_ACTIONS.updated) {
        let items = getState(states, action.key).items.slice();
        let index = items.findIndex(item => {
            return item.ticketId == action.item._id
        });

        if (index >= 0) {
            items[index] = { ...items[index], ticket: action.item };
            return setState(states, action.key, {
                items
            });
        }
    }
    return common_list(states, action, DOINGS_ACTIONS);
}

export function done_lists(states = init_states, action) {
    return common_list(states, action, DONES_ACTIONS);
}

export function deleted_todo_lists(states = init_states, action) {
    return common_list(states, action, DELETED_TODOS_ACTIONS);
}

export function doc_lists(states = init_states, action) {
    return common_list(states, action, DOCS_ACTIONS);
}

export function flow_doc_lists(states = init_states, action) {
    return common_list(states, action, FLOW_DOCS_ACTIONS);
}

export function dbdata_lists(states = init_states, action) {
    return common_list(states, action, DBDATAS_ACTIONS);
}

export function dbview_lists(states = init_states, action) {
    return common_list(states, action, DB_VIEWS_ACTIONS);
}

export function dbview_dataorder_lists(states = init_states, action) {
    return common_list(states, action, DB_VIEW_DATA_ORDER_ACTIONS);
}

export function workspace_doc_lists(states = init_states, action) {
    return common_list(states, action, WORKSPACE_DOCS_ACTIONS);
}

export function shared_doc_lists(states = init_states, action) {
    return common_list(states, action, SHARED_DOCS_ACTIONS);
}

export function private_doc_lists(states = init_states, action) {
    return common_list(states, action, PRIVATE_DOCS_ACTIONS);
}

export function deleted_doc_lists(states = init_states, action) {
    return common_list(states, action, DELETED_DOCS_ACTIONS);
}

export function sub_doc_lists(states = init_states, action) {
    return common_list(states, action, SUB_DOCS_ACTIONS);
}

export function doc_history_lists(states = init_states, action) {
    return common_list(states, action, DOC_HISTORIES_ACTIONS);
}

export function comment_lists(states = init_states, action) {
    return common_list(states, action, COMMENTS_ACTIONS);
}

export function slide_lists(states = init_states, action) {
    return common_list(states, action, SLIDES_ACTIONS);
}

export function deleted_slide_lists(states = init_states, action) {
    return common_list(states, action, DELETED_SLIDES_ACTIONS);
}

export function ril_lists(states = init_states, action) {
    return common_list(states, action, RILS_ACTIONS);
}

export function read_ril_lists(states = init_states, action) {
    return common_list(states, action, READ_RILS_ACTIONS);
}

export function deleted_ril_lists(states = init_states, action) {
    return common_list(states, action, DELETED_RILS_ACTIONS);
}

export function searched_ril_lists(states = init_states, action) {
    return common_list(states, action, SEARCHED_RILS_ACTIONS);
}

export function searched_todo_lists(states = init_states, action) {
    return common_list(states, action, SEARCHED_TODOS_ACTIONS);
}

export function article_highlight_lists(states = init_states, action) {
    return common_list(states, action, ARTICLE_HIGHLIGHTS_ACTIONS);
}

export function feedback_lists(states = init_states, action) {
    return common_list(states, action, FEEDBACKS_ACTIONS);
}

export function tag_lists(states = init_states, action) {
    return common_list(states, action, TAGS_ACTIONS);
}

export function okr_lists(states = init_states, action) {
    return common_list(states, action, OKRS_ACTIONS);
}

export function aligned_okr_lists(states = init_states, action) {
    return common_list(states, action, ALIGNED_OKRS_ACTIONS);
}

export function group_okr_lists(states = init_states, action) {
    return common_list(states, action, GROUP_OKRS_ACTIONS);
}

export function company_okr_lists(states = init_states, action) {
    return common_list(states, action, COMPANY_OKRS_ACTIONS);
}

export function people_okr_lists(states = init_states, action) {
    return common_list(states, action, PEOPLE_OKRS_ACTIONS);
}

export function my_keyresults_lists(states = init_states, action) {
    return common_list(states, action, MY_KRS_ACTIONS);
}

export function group_keyresults_lists(states = init_states, action) {
    return common_list(states, action, GROUP_KRS_ACTIONS);
}

export function company_keyresults_lists(states = init_states, action) {
    return common_list(states, action, COMPANY_KRS_ACTIONS);
}

export function people_keyresults_lists(states = init_states, action) {
    return common_list(states, action, PEOPLE_KRS_ACTIONS);
}

export function key_result_lists(states = init_states, action) {
    return common_list(states, action, KEY_RESULTS_ACTIONS);
}

export function org_lists(states = init_states, action) {
    // if (action.type === GROUPS_ACTIONS.added_to_bottom) {
    //     let items = getState(states, action.key).items.slice();
    //     let index = items.findIndex(item => {
    //         return item._id == action.item.orgId
    //     });

    //     if (index >= 0) {
    //         items[index].groups.push(action.item);
    //         return setState(states, action.key, {
    //             items
    //         });
    //     }
    // } else if (action.type === GROUPS_ACTIONS.deleted) {
    //     let items = getState(states, action.key).items.slice();
    //     let index = items.findIndex(item => {
    //         return item._id == action.item.orgId
    //     });

    //     if (index >= 0) {
    //         items[index].groups = items[index].groups.filter(group => group._id !== action.item._id);
    //         return setState(states, action.key, {
    //             items
    //         });
    //     }
    // }
    return common_list(states, action, ORGS_ACTIONS);
}

export function group_lists(states = init_states, action) {
    return common_list(states, action, GROUPS_ACTIONS);
}

export function to_confirm_org_lists(states = init_states, action) {
    return common_list(states, action, TO_CONFIRM_ORGS_ACTIONS);
}

export function group_member_lists(states = init_states, action) {
    return common_list(states, action, GROUP_MEMBERS_ACTIONS);
}

export function follower_lists(states = init_states, action) {
    return common_list(states, action, FOLLOWERS_ACTIONS);
}

export function message_lists(states = init_states, action) {
    return common_list(states, action, MESSAGES_ACTIONS);
}

export function thread_member_lists(states = init_states, action) {
    return common_list(states, action, THREAD_MEMBERS_ACTIONS);
}

export function sub_ticket_lists(states = init_states, action) {
    return common_list(states, action, SUB_TICKETS_ACTIONS);
}

export function group_ticket_lists(states = init_states, action) {
    return common_list(states, action, GROUP_TICKETS_ACTIONS);
}

export function group_todo_lists(states = init_states, action) {
    return common_list(states, action, GROUP_TODOS_ACTIONS);
}

export function group_doing_lists(states = init_states, action) {
    return common_list(states, action, GROUP_DOINGS_ACTIONS);
}

export function group_done_lists(states = init_states, action) {
    return common_list(states, action, GROUP_DONES_ACTIONS);
}

export function prompt_lists(states = init_states, action) {
    return common_list(states, action, PROMPTS_ACTIONS);
}

export function public_prompt_lists(states = init_states, action) {
    return common_list(states, action, PUBLIC_PROMPTS_ACTIONS);
}

export function workspace_prompt_lists(states = init_states, action) {
    return common_list(states, action, WORKSPACE_PROMPTS_ACTIONS);
}

export function pinned_prompt_lists(states = init_states, action) {
    return common_list(states, action, PINNED_PROMPTS_ACTIONS);
}

export function validate_prompt_lists(states = init_states, action) {
    return common_list(states, action, VALIDATE_PROMPTS_ACTIONS);
}

const setState = (states, key, newState) => {
    let newStates = [...states];

    let state = getState(newStates, key);
    newState = Object.assign({}, state, newState);

    let index = newStates.findIndex(ele => ele.key === key);
    let replace = 1;
    if (index < 0) {
        index = 0;
        replace = 0;
    }

    newStates.splice(index, replace, { key, state: newState });
    return newStates;
}

export const getState = (states, key) => {
    if (!states) {
        return init_list_state;
    }

    let element = states.find(ele => ele.key === key);
    if (element) {
        return element.state;
    }

    return init_list_state;
}

export const getStateByUser = (states, user) => {
    let userId = '';
    if (user && user._id) {
        userId = user._id;
    }
    return getState(states, userId);
}

export const getListTopItemField = (states, key, field) => {
    let list = getState(states, key);
    if (!list || !list.items || list.items.length === 0) {
        return null;
    }

    return list.items[0][field];
}

function list(states, action) {
    let current_states = getState(states, action.key);
    let items = (current_states.items && current_states.items.length > 0) ? current_states.items.slice() : [];
    let index = null;

    let sortField = action.params && action.params.pageBy || 'updatedAt';
    let compareFunc = (a, b) => b[sortField] - a[sortField];
    if (sortField == 'updatedAt' || sortField == 'createdAt') {
        compareFunc = (a, b) => new Date(b[sortField]) - new Date(a[sortField]);
    }

    switch (action.type) {
        case ITEMS_ACTIONS.added:
            index = items.findIndex(item => item._id == action.item._id);
            let newItems = current_states.newItems;
            if (index < 0) {
                newItems++;
            }
            items = items.filter(x => x._id !== action.item._id);
            items.unshift(action.item);
            items.sort((a, b) => compareFunc(a, b));
            return setState(states, action.key, {
                items, newItems
            });

        case ITEMS_ACTIONS.added_to_bottom:
            items = items.filter(x => x._id !== action.item._id);
            items.push(action.item);
            return setState(states, action.key, {
                items
            });

        case ITEMS_ACTIONS.updated:
            index = items.findIndex(item => item._id == action.item._id);
            items[index] = action.item;

            items.sort((a, b) => compareFunc(a, b));

            return setState(states, action.key, {
                items
            });

        case ITEMS_ACTIONS.deleted:
            items = items.filter(item => item._id != action.item._id);
            return setState(states, action.key, {
                items
            });

        case ITEMS_ACTIONS.invalidated:
            states = states.filter(item => item.key != action.key);
            return [...states];

        case ITEMS_ACTIONS.requesting:
            return setState(states, action.key, {
                isFetching: true,
                didInvalidate: false,
                lastRequested: new Date()
            });
        case ITEMS_ACTIONS.received:
            let isEnd = !action.items || action.items.length == 0;
            if (action.params.pageSize === 0) {
                isEnd = true;
            } else {
                isEnd = isEnd || action.items.length < (action.params.pageSize ? action.params.pageSize : init_list_state.pageSize);
            }

            let newState = {
                isFetching: false,
                didInvalidate: false,
                pageNum: action.params.pageNum || 0,
                isEnd,
                lastUpdated: action.receivedAt
            };

            if (action.params.pageTo) {
                if (action.items && action.items.length > 0) {
                    action.items.forEach((ele) => {
                        items = items.filter(item => item._id != ele._id);
                    });

                    items = action.items.concat(items);

                    items.sort((a, b) => compareFunc(a, b));

                }
                newState.items = items;
                newState.newItems = current_states.newItems + action.items.length;
                newState.isEnd = current_states.isEnd;
            } else if (!action.params.pageNum &&
                (action.params.pageFrom === null || action.params.pageFrom === undefined) &&
                (action.params.pageTo === null || action.params.pageTo === undefined)
            ) {
                newState.items = action.items;
            } else {
                newState.items = items;
                if (action.items && action.items.length > 0) {
                    action.items.forEach(ele => {
                        newState.items = newState.items.filter(x => x._id !== ele._id);
                        newState.items.push(ele);
                    });
                }
            }

            return setState(states, action.key, newState);
        case ITEMS_ACTIONS.request_failed:
            return setState(states, action.key, {
                isFetching: false
            });
        case ITEMS_ACTIONS.reset_new_items_counter:
            return setState(states, action.key, { newItems: 0 });
        default:
            return states
    }
}

function common_list(states, action, action_group) {
    let orignal_action_type = action.type;
    let new_action_type = getCommonListAction(action_group, action.type);

    if (!new_action_type) {
        return states;
    }

    action.type = new_action_type;
    let newStates = list(states, action);

    action.type = orignal_action_type;

    return newStates;
}