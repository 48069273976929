
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useIntl } from 'react-intl';
import { Selector } from '../common/Selector';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MEDIA_QUERY } from '../../utils/constants';

export const PromptFormArgEditor = ({ editorState, handleClose, form, handleConfirm }) => {
    const intl = useIntl();

    const [formErrMsg, setFormErrMsg] = useState();
    const [option, setOption] = useState('')

    const [data, setData] = useState({ type: 'text' });

    useEffect(() => {
        if (!editorState) return;

        if (editorState.visible && editorState.edittingArgIndex > -1 ) {
            setData(form.args[editorState.edittingArgIndex])
        } else {
            setData({ type: 'text' })
        }
    }, [editorState])

    const onConfirm = () => {
        if (!data.label) {
            return setFormErrMsg(intl.formatMessage({ id: 'not_null' }, { field: intl.formatMessage({ id: 'title' }) }));
        } else if (data.type == 'select' && data.options?.length < 2) {
            return setFormErrMsg(intl.formatMessage({ id: 'not_enough_options' }));
        }

        setFormErrMsg(null);
        handleConfirm(data);
    }

    const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

    const styles = {
        dialogContent: {
            width: isMobile ? '100%' : '640px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: 0,
            backgroundColor: 'white',
            overflowX: 'hidden',
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '8px',
            padding: '10px',
            fontSize: 14,
            width: '100%',
        },
        row: {
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            columnGap: '8px',
            rowGap: '8px',
            width: '100%',
            fontSize: 16
        },
        input: {
            padding: '3px',
            width: '100%',
            border: '1px solid #ddd',
            outline: 'none',
            borderRadius: '4px',
            fontSize: '15px',
        },
        selectOptionsContainer: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '6px',
            width: '100%',
        },
        optionTable: {
            borderCollapse: 'collapse',
            width: '100%',
        },
        addOptionContainer: {
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'stretch' : 'center',
            width: '100%',
            rowGap: '8px',
        },
        addOptionInput: {
            padding: '3px',
            width: isMobile ? '100%' : '300px',
            border: '1px solid #ddd',
            outline: 'none',
            borderRadius: '4px',
            fontSize: '15px',
        },
        addOptionButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
            marginLeft: isMobile ? 0 : '6px',
            color: 'white',
            cursor: 'pointer',
            backgroundColor: 'dodgerblue',
            borderRadius: '3px',
            whiteSpace: 'nowrap',
            fontSize: '14px',
            width: isMobile ? '100%' : 'auto',
        },
    };

    return (
        <Dialog
            open={editorState.visible}
            onClose={handleClose}
            scroll='paper'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullScreen={isMobile}
            maxWidth='md'
        >
            <DialogContent style={styles.dialogContent}>
                <div style={styles.formContainer}>
                    <div style={styles.row}>
                        <div style={{ color: 'dodgerblue', backgroundColor: '#eee', padding: '4px', paddingLeft: '8px', paddingRight: '8px', borderRadius: '4px' }}>
                            {'var_' + (editorState.edittingArgIndex > -1 ? editorState.edittingArgIndex : (form.args?.length || 0))}
                        </div>
                        {intl.formatMessage({ id: 'arg_type' })}
                        <Selector
                            options={[
                                { label: intl.formatMessage({ id: 'arg_type_textline' }), value: 'input' },
                                { label: intl.formatMessage({ id: 'arg_type_text' }), value: 'textarea' },
                                { label: intl.formatMessage({ id: 'arg_type_select' }), value: 'select' }
                            ]}
                            value={data.type || 'text'}
                            inputStyle={{
                                border: '1px solid #ddd',
                                width: isMobile ? '100%' : 'auto',
                            }}
                            onChange={(value) => {
                                setData({
                                    ...data,
                                    type: value
                                })
                            }}
                        />
                    </div>
                    <div style={styles.row}>
                        {intl.formatMessage({ id: 'arg_label' })}
                        <input
                            value={data.label || ''}
                            onChange={(event) => {
                                setData({
                                    ...data,
                                    label: event.target.value
                                })
                            }}
                            style={styles.input}
                            placeholder={intl.formatMessage({ id: 'arg_label_placeholder' })}
                        />
                    </div>
                    {/* {
                        ['text', 'textline'].includes(data.type) &&
                        <div style={styles.row}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                columnGap: 6
                            }}>
                            {intl.formatMessage({ id: 'arg_hint' })}
                            <span style={{ color: '#666', fontSize: '14px' }}> {'(' + intl.formatMessage({ id: 'arg_optional' }) + ')'} </span>
                            </div>
                            <input
                                value={data.hint || ''}
                                onChange={(event) => {
                                    setData({
                                        ...data,
                                        hint: event.target.value
                                    })
                                }}
                                style={{
                                    ...styles.input,
                                    width: isMobile ? '100%' : '375px',
                                }}
                                placeholder={intl.formatMessage({ id: 'arg_hint_placeholder' })}
                            />
                        </div>
                    } */}
                    {
                        ['select'].includes(data.type) &&
                        <div style={styles.row}>
                            <div>
                                <input type="checkbox"
                                    id="free_input"
                                    name="free_input"
                                    checked={!!data.enable_user_input}
                                    onChange={(event) => {
                                        setData({
                                            ...data,
                                            enable_user_input: event.target.checked
                                        })
                                    }}
                                />
                                <label htmlFor="free_input" style={{ paddingLeft: '4px' }}>{intl.formatMessage({ id: 'select_option_free_input' })}</label>
                            </div>
                        </div>
                    }
                    {
                        data.type === 'select' &&
                        <div style={styles.row}>
                            {intl.formatMessage({ id: 'select_options' })}
                            <div style={styles.selectOptionsContainer}>
                                <table style={styles.optionTable}>
                                    <tbody>
                                        {data.options?.filter(option => !!option.value).map((option, index) => (
                                            <tr key={index}>
                                                <td style={{ paddingLeft: '4px' }}>{index + '.'}</td>
                                                <td style={{ minWidth: '180px', paddingLeft: '4px' }}>{option.label}</td>
                                                <td><div style={{
                                                    paddingTop: '4px',
                                                    paddingBottom: '4px',
                                                    paddingLeft: '10px',
                                                    paddingRight: '10px',
                                                    cursor: 'pointer',
                                                    color: 'dodgerblue',
                                                    borderRadius: '3px',
                                                    whiteSpace: 'nowrap',
                                                    fontSize: '14px'
                                                }}
                                                    onClick={() => {
                                                        let options = [...data.options];
                                                        options.splice(index, 1);
                                                        setData({ ...data, options });
                                                    }}
                                                >{intl.formatMessage({ id: 'delete' })}</div></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div style={styles.addOptionContainer}>
                                    <input
                                        value={option}
                                        onChange={(event) => {
                                            setOption(event.target.value)
                                        }}
                                        style={styles.addOptionInput}
                                        placeholder={intl.formatMessage({ id: 'select_option_placeholder' })}
                                    />
                                    <div style={styles.addOptionButton} onClick={() => {
                                        if (!option?.trim() || data.options?.find(op => op.label === option.trim())) {
                                            return setFormErrMsg(intl.formatMessage({ id: 'not_null' }, { field: intl.formatMessage({ id: 'select_options' }) }));
                                        }

                                        setFormErrMsg(null);

                                        setData(data => {
                                            let options = [...(data.options || [])];
                                            options.push({
                                                label: option.trim(),
                                                value: option.trim()
                                            })

                                            return { ...data, options }
                                        })

                                        setOption('');
                                    }}>
                                        {intl.formatMessage({ id: 'add_option' })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        formErrMsg &&
                        <div style={{ color: 'red' }}>{formErrMsg}</div>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', padding: isMobile ? '16px' : '0 16px', rowGap: isMobile ? '10px' : '0', columnGap: '20px', width: isMobile ? '100%' : 'auto' }}>
                    <Button variant='text' onClick={handleClose} fullWidth={isMobile}>{intl.formatMessage({ id: 'cancel' })}</Button>
                    <Button variant='contained' onClick={onConfirm} fullWidth={isMobile}>{intl.formatMessage({ id: 'confirm' })}</Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
