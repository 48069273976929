// 在文件顶部添加这个新的辅助函数
const convertAttributeValue = (value, totalSize) => {
  if (typeof value !== 'string') return value;

  // 处理百分比
  if (value.endsWith('%')) {
    const percentage = parseFloat(value) / 100;
    return totalSize * percentage;
  }

  // 处理带单位的值（如 px）
  const numericValue = parseFloat(value);
  return isNaN(numericValue) ? value : numericValue;
};

export const preprocessSvg = (svgString) => {
  // 移除 @import 语句
  const cleanedSrc = svgString.replace(/@import\s+url\([^)]+\);?/g, '');

  // 使用DOMParser解析清理后的SVG字符串
  const parser = new DOMParser();
  const doc = parser.parseFromString(cleanedSrc, 'image/svg+xml');
  const svgElement = doc.documentElement;

  if (svgElement.tagName === 'parsererror') {
    throw new Error('Invalid SVG content');
  }

  // 获取 SVG 的宽度和高度
  const svgWidth = parseFloat(svgElement.getAttribute('width')) || svgElement.viewBox?.baseVal?.width;
  const svgHeight = parseFloat(svgElement.getAttribute('height')) || svgElement.viewBox?.baseVal?.height;

  // 处理所有元素的 x, y, width, height 属性
  const allElements = svgElement.getElementsByTagName('*');
  for (let element of allElements) {
    ['x', 'y', 'width', 'height'].forEach(attr => {
      if (element.hasAttribute(attr)) {
        const value = element.getAttribute(attr);
        const convertedValue = convertAttributeValue(value, attr === 'x' || attr === 'width' ? svgWidth : svgHeight);
        element.setAttribute(attr, convertedValue);
      }
    });
  }

  // 设置 viewBox 以保持宽高比
  if (!svgElement.getAttribute('viewBox')) {
    const width = svgWidth || svgElement.width?.baseVal?.value || 400;
    const height = svgHeight || svgElement.height?.baseVal?.value || 800;
    svgElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
  }

  //   设置 preserveAspectRatio 以确保 SVG 完整显示
  svgElement.setAttribute('preserveAspectRatio', 'xMidYMid meet');

  // 处理文本换行
  wrapLongText(svgElement);

  // 将修改后的SVG转换回字符串
  const serializer = new XMLSerializer();
  return serializer.serializeToString(svgElement);
};

const wrapLongText = (svgElement) => {
  const texts = svgElement.querySelectorAll('text');
  texts.forEach((text) => {
    const x = parseFloat(text.getAttribute('x') || 0);
    const y = parseFloat(text.getAttribute('y') || 0);
    const textWidth = parseFloat(text.getAttribute('width') || 0);

    // 使用 text 元素的 width 属性（如果存在），否则使用 SVG 宽度的 80%
    const svgWidth = parseFloat(svgElement.getAttribute('width') || svgElement.viewBox?.baseVal?.width);
    const maxWidth = (textWidth || svgWidth * 0.8);

    const tspans = text.querySelectorAll('tspan');

    if (tspans.length > 0) {
      tspans.forEach((tspan, index) => {
        tspan.setAttribute('x', x);
        if (index > 0) {
          tspan.setAttribute('dy', '1.2em');
        } else {
          tspan.setAttribute('y', y);
        }
      })
    } else {
      const textContent = text.textContent;
      const words = textContent.split(/\s+/);

      text.textContent = '';

      let line = '';
      let tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
      tspan.setAttribute('x', x);
      tspan.setAttribute('y', y);
      text.appendChild(tspan);

      words.forEach((word, index) => {
        const testLine = line + (line ? ' ' : '') + word;
        const testWidth = getTextWidth(testLine + ' ', text, svgElement);

        // console.log('testWidth..............', testWidth, maxWidth, testLine);

        if (testWidth > maxWidth && index > 0) {
          tspan.textContent = line;
          line = word;

          tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
          tspan.setAttribute('x', x);
          tspan.setAttribute('dy', '1.2em');
          text.appendChild(tspan);
        } else {
          line = testLine;
        }
      });

      tspan.textContent = line;
    }
  });
};

const extractStylesFromSvg = (svgElement) => {
  const styles = {};
  const styleElements = svgElement.getElementsByTagName('style');
  for (let styleEl of styleElements) {
    const cssText = styleEl.textContent;
    const rules = cssText.match(/[^\{\}]+\{[^\}]+\}/g);
    if (rules) {
      rules.forEach(rule => {
        const [selector, declaration] = rule.split('{');
        const properties = declaration.replace('}', '').split(';');
        properties.forEach(prop => {
          const [key, value] = prop.split(':').map(s => s.trim());
          if (key && value) {
            if (!styles[selector.trim()]) {
              styles[selector.trim()] = {};
            }
            styles[selector.trim()][key] = value;
          }
        });
      });
    }
  }
  return styles;
};

const getTextWidth = (text, element, svgElement) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // 提取 SVG 中的样式
  const svgStyles = extractStylesFromSvg(svgElement);
  //   console.log('svgStyles..............', svgStyles);
  // 尝试获取元素的类名

  let fontSize = element.getAttribute('font-size');
  let fontFamily = element.getAttribute('font-family');

  const className = element.getAttribute('class');
  // 如果元素有类名，尝试从 SVG 样式中获取字体信息
  if (className && svgStyles[`.${className}`]) {
    fontSize = fontSize || svgStyles[`.${className}`]['font-size'];
    fontFamily = fontFamily || svgStyles[`.${className}`]['font-family'];
  }

  fontSize = fontSize || svgStyles['text'] && svgStyles['text']['font-size'] || svgStyles['.content'] && svgStyles['.content']['font-size'] || '14px';
  fontFamily = fontFamily || svgStyles['text'] && svgStyles['text']['font-family'] || svgStyles['.content'] && svgStyles['.content']['font-family'] || 'Arial, sans-serif';

  //   console.log('Font info:', parseInt(fontSize), fontFamily);

  context.font = `${fontSize}px ${fontFamily}`;

  return context.measureText(text).width;
};

export function getSvgDimensions(svgString) {
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgString.replace(/@import\s+url\([^)]+\);?/g, ''), 'image/svg+xml');
  const svgElement = svgDoc.documentElement;

  // console.log('svgElement..............', svgElement, svgElement.getAttribute('width'), svgElement.getAttribute('height'), svgElement.viewBox);

  let width = svgElement.getAttribute('width') || svgElement.viewBox?.baseVal?.width;
  let height = svgElement.getAttribute('height') || svgElement.viewBox?.baseVal?.height;

  // 如果没有明确的宽度和高度属性，尝试从 viewBox 中获取
  if (!width || !height) {
    const viewBox = svgElement.getAttribute('viewBox');
    if (viewBox) {
      const [, , vbWidth, vbHeight] = viewBox.split(' ').map(Number);
      width = width || vbWidth;
      height = height || vbHeight;
    }
  }

  // 处理可能的百分比或带单位的值
  // const totalWidth = svgElement.viewBox?.baseVal?.width || 300;
  // const totalHeight = svgElement.viewBox?.baseVal?.height || 150;

  // width = convertAttributeValue(width, totalWidth);
  // height = convertAttributeValue(height, totalHeight);

  // // 如果仍然没有有效的宽度或高度，设置默认值
  // if (isNaN(width) || width <= 0) width = 300;
  // if (isNaN(height) || height <= 0) height = 150;

  return { width, height };
}
