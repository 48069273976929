import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useIntl } from 'react-intl';
import { getAffiliateUsers } from '../../actions/ticketAction';

const ReferredUsersTable = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const loginUser = useSelector(state => state.loginIn.user);

    // const referredUsers = useSelector(state => state.affiliate.referredUsers); // Assume this data is available in the state
    const [referredUsers, setReferredUsers] = React.useState([]);

    React.useEffect(() => {
        dispatch(getAffiliateUsers({}, (users) => {
            if (users) {
                setReferredUsers(users);
            }
        }))
    }, [])

    const [copied, setCopied] = React.useState();
    const [affiliateLink, setAffiliateLink] = React.useState();

    React.useEffect(() => {
        if (loginUser) {
            setAffiliateLink(`https://funblocks.net/aiflow.html?aid=${loginUser?._id}`);
        }
    }, [loginUser])

    return (<>
        <div style={{ marginTop: 20, fontWeight: 'bold', fontSize: '16px' }}>
            Affiliate Link for you
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px' }}>
            <span style={{ marginRight: '10px', color: '#555' }}>{affiliateLink}</span>
            <div
                style={{
                    cursor: 'pointer',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    padding: '8px 12px',
                    backgroundColor: '#f9f9f9',
                    color: copied ? 'gray' : '#333',
                    transition: 'background-color 0.3s',
                }}
                onClick={() => {
                    navigator.clipboard.writeText(affiliateLink).then(() => {
                        setCopied(true);
                        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
                    }).catch(err => {
                        console.error('Failed to copy: ', err);
                    });
                }}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f9f9f9'}
            >
                {copied ? 'Copied to clipboard' : 'Copy'}
            </div>
        </div>

        <div style={{ marginTop: 20, paddingBottom: 10, fontWeight: 'bold', fontSize: '16px' }}>
            Referred users (Show only latest 50 records)
        </div>
        <div style={{ border: '1px solid #ccc', borderRadius: '4px', overflow: 'hidden', marginRight: 20 }}>
            {referredUsers.map((user) => (
                <div key={user.id} style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #eee', fontSize: 13 }}>
                    <span style={{ color: '#333' }}>{user.username}</span>
                    <span style={{ color: '#777' }}>{user.createdAt}</span>
                </div>
            ))}
        </div>
    </>
    );
}

export default ReferredUsersTable; 