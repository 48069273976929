import thunk from "redux-thunk";
import { applyMiddleware, compose, createStore } from "redux";
import { autoRehydrate } from "redux-persist";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from "./reducers/index";

export default () => {
  const persistConfig = {
    key: 'root',
    storage: storage,
    // whitelist: ['authType'] // which reducer want to store
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const logger = createLogger();
  const preloadState = {};
  let store = createStore(
    persistedReducer,
    preloadState,
    compose(process.env.NODE_ENV === 'production' ? applyMiddleware(thunk) : applyMiddleware(logger, thunk))
  );

  let persistor = persistStore(store);
  return { store, persistor }
}

