import * as React from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { PROMOTE_VIP, SETTINGS_DIALOG } from 'src/constants/actionTypes';

const PromoteVIP = ({ onClose }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const settingsDialogState = useSelector(state => state.uiState.settingsDialog) || {};

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            border: '1px solid red',
            borderRadius: '6px',
            padding: '4px',
            paddingLeft: '20px',
            paddingRight: '20px',
            columnGap: '10px'
        }}>
            {intl.formatMessage({ id: 'exceeded_quota' })}
            <Button
                variant='contained'
                onClick={() => {
                    dispatch({
                        type: SETTINGS_DIALOG,
                        value: {
                            ...settingsDialogState,
                            visible: true,
                            page: 'service_subscribe'
                        }
                    })

                    dispatch({
                        type: PROMOTE_VIP,
                        value: {
                            visible: false
                        }
                    });
                    onClose && onClose();
                }}
            >
                {intl.formatMessage({ id: 'upgrade_plan' })}
            </Button>
        </div>
    );
}

export default PromoteVIP;