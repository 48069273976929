import { VIEW_SORT_ACTIONS } from '../constants/actionTypes'

const init_state = {
    byId: {}
};

export function byId(state = init_state, action) {
    switch (action.type) {
        case VIEW_SORT_ACTIONS.updated:
        case VIEW_SORT_ACTIONS.received:
            return Object.assign({}, state, { [action.item.viewId]: {
                ...state[action.item.viewId],
                ...action.item
            }});

        case VIEW_SORT_ACTIONS.invalidated:
            return Object.keys(state).reduce((result, key) => {
                if (key !== action.item.viewId) {
                    result[key] = state[key];
                }
                return result;
            }, {})
        default:
            return state;
    }
}


