import * as React from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from '@mui/material';
import { Refresh } from '@styled-icons/material';
import { useDispatch, useSelector } from 'react-redux';
import { Selector } from '../common/Selector';
import { RefreshCircle } from '@styled-icons/ionicons-outline/RefreshCircle'

const RegenerateMenu = ({ data, onRegenerate, iconStyle }) => {
  const intl = useIntl();

  const appConfigs = useSelector(state => state.uiState.app_config);
  const llm_api_keys = useSelector(state => state.uiState.llm_api_keys);

  const [models, set_models] = React.useState();

  React.useEffect(() => {
    if (!appConfigs?.ai_api_models) return;

    let ai_models = [...appConfigs.ai_api_models];

    ai_models.push({
      type: 'title',
      label: intl.formatMessage({ id: 'self_owned_ai_api' })
    })

    if (llm_api_keys) {
      ai_models = ai_models.concat(llm_api_keys.map(item => ({
        label: item.name,
        value: item.id,
      })));
    }

    ai_models = ai_models.map(model => ({
      ...model,
      tips: model.desc
    }));

    set_models(ai_models);
  }, [appConfigs?.ai_api_models, llm_api_keys, intl])

  if (!models?.length) return;

  return <Selector
    options={models}
    value={data.ai_model}
    triggerElement={<Tooltip
      title={intl.formatMessage({ id: 'regenerate_tooltip' })}
      placement='top'
    >
      <div
        className='hoverStand'
        style={iconStyle}
      >
        <RefreshCircle color='#666' size={22} />
      </div>
    </Tooltip>
    }
    onChange={(value) => {
      if (!value) return;

      !!onRegenerate && onRegenerate(value);
    }}
    inputStyle={{ backgroundColor: 'transparent', padding: 0, margin: 0, border: 0 }}
    menuHeader={
      <div style={{
        color: 'gray',
        fontSize: 14,
        padding: 6,
      }}>
        {data.ai_model && <>
          <div>{intl.formatMessage({ id: 'generated_with' })}:</div>
          <div style={{
            color: '#333',
            paddingLeft: 7
          }}>{models.find(model => model.value === data.ai_model || model.id === data.ai_model)?.label || data.ai_model}</div>
        </>}
        <div style={{
          marginTop: 6
        }}>{intl.formatMessage({ id: 'regenerate_with' })}: </div>
      </div>}
    itemStyle={{
      paddingLeft: 12,
      minWidth: 240
    }}
    dropdownIconSize={14}
  />
};


export default RegenerateMenu;