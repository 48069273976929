import * as React from 'react';

import { INVITE_FRIENDS_DIALOG, SETTINGS_DIALOG } from 'src/constants/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { DialogContent, Dialog, Button, Avatar, IconButton, Menu, MenuItem, DialogTitle } from '@mui/material';
import { getStateByUser } from 'src/reducers/listReducer';
import { useIntl } from 'react-intl';

import { useMediaQuery } from 'react-responsive';
import { MOBILE_MEDIA_QUERY } from '../utils/constants';
import { PAGE_CHOOSER_DIALOG } from '../constants/actionTypes';
import { PageChooser } from './PageChooser';
import { Close } from '@styled-icons/material';

const drawerWidth = 200;

const PageChooserModal = ({ onSelect }) => {
    const dialogState = useSelector(state => state.uiState.page_chooser_dialog) || {};
    const dispatch = useDispatch();
    const intl = useIntl();

    const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

    // const loginUser = useSelector(state => state.loginIn.user);
    // const orgs = useSelector(state => getStateByUser(state.org_lists, loginUser));
    // const workingSpace = orgs.items.find(org => org._id === loginUser.workingOrgId) || {};

    const handleClose = (item) => {
        dispatch({
            type: PAGE_CHOOSER_DIALOG,
            value: {
                ...dialogState,
                item,
                visible: false
            }
        });
    }

    return (
        <Dialog
            open={!!dialogState && dialogState.visible}
            onClose={() => handleClose()}
            scroll='paper'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth='lg'
            fullScreen={isMobile}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 8,
                paddingBottom: 0,
            }}>
                {intl.formatMessage({ id: 'page_chooser_title' })} {/* You can replace this with a static string if needed */}
                <div
                    onClick={() => handleClose()}
                    style={{
                        width:24,
                        height: 24,
                        cursor: 'pointer'
                    }}
                >
                    <Close size={20}/>
                </div>
            </div>
            <DialogContent dividers={false} style={{ display: 'flex', flexDirection: 'row', height: isMobile ? '100%' : 680, width: isMobile ? '100%' : 480, padding: 0 }}>
                <PageChooser
                    accepts={['doc', 'slides']}
                    onSelect={(item) => {
                        onSelect && onSelect(item);
                        handleClose(item)
                    }}
                    onClose={() => handleClose()}
                />
            </DialogContent>
        </Dialog>
    );
}

export default PageChooserModal;
