import escapeHtml from 'escape-html'
import { Text } from 'slate'

// const config = require('../../config').config;

const makeListItem = (isOL, listDepth, children) => {
    if (!children.endsWith('\n')) {
        children = children + '\n';
    }

    let spacer = '';
    for (let k = 1; listDepth > k; k++) {
        if (isOL) {
            // https://github.com/remarkjs/remark-react/issues/65
            spacer += '   ';
        } else {
            spacer += '  ';
        }
    }
    return `${spacer}${isOL ? '1.' : '-'} ${children}`;
}

const followedBySameTypeBlock = (block, type, index, blocks) => {
    if (index === blocks.length - 1) {
        return false;
    }

    return block[type] === blocks[index + 1][type]
}

export const serialize = (server_host, node, parentNode, listDepth = 0, listType) => {
    if (Text.isText(node)) {
        let string = escapeHtml(node.text)
        if (node.bold) {
            string = `**${string}**`
        }

        if (node.italic) {
            string = `*${string}*`
        }

        if (node.underline) {
            string = `<u>${string}</u>`
        }

        if (node.strikethrough) {
            string = `~~${string}~~`
        }

        if (node.superscript) {
            string = `<sup>${string}</sup>`
        }

        if (node.subscript) {
            string = `<sub>${string}</sub>`
        }

        if (node.code) {
            string = `\`${string}\``
        }

        // if (node.highlight) {
        //     node.backgroundColor = '#FEF3B7';
        // }

        // if (node.color && node.backgroundColor) {
        //     string = `<span style="color: ${node.color}; background-color: ${node.backgroundColor}">${string}</span>`
        // } else if (node.color) {
        //     string = `<span style="color: ${node.color}">${string}</span>`
        // } else if (node.backgroundColor) {
        //     string = `<span style="background-color: ${node.backgroundColor}">${string}</span>`
        // }

        if (parentNode?.type === 'li') {
            return string + '\n';
        }

        return string
    }


    if (['ol', 'ul'].includes(node.type)) {
        listDepth = listDepth + 1;
        listType = node.type;
    }

    const children = node.children.map(n => serialize(server_host, n, node, listDepth, listType)).join('')

    switch (node.type) {
        case 'blockquote':
            return `> ${children}`
        case 'ul':
        case 'ol':
            return `${children}`;
        case 'lic':
        case 'li':
            if (node.type === 'li' && node.children[0].type && node.children[0].type != 'p') {
                return children;
            }

            return makeListItem(listType == 'ol', listDepth, children);

        case 'action_item':
            if (node.checked) {
                return `- [x] ${children}`
            } else {
                return `- [ ] ${children}`
            }
        case 'p':
            if (['disc', 'decimal'].includes(node.listStyleType)) {
                return makeListItem(node.listStyleType == 'decimal', node.indent, children)
            }

            return `${children || ''}`
        case 'media_embed':
            return `![${children}](${escapeHtml(node.url) || ''})`
        case 'a':
            return `[${children}](${escapeHtml(node.url) || ''})`
        case 'img':
            return `![${escapeHtml(node.caption && node.caption[0] && node.caption[0].text || '')}](${node.url || ''})`
        case 'code_block':
            return `\`\`\`${node.lang || 'plaintext'}\n${children}\n\`\`\``
        case 'code_line':
            return `${children}\n`
        case 'h1':
            return `# ${children}`
        case 'h2':
            return `## ${children}`
        case 'h3':
            return `### ${children}`
        case 'h4':
            return `#### ${children}`
        case 'h5':
            return `##### ${children}`
        case 'h6':
            return `###### ${children}`
        case 'hr':
            return `---`
        case 'mention':
            return `${node.value.includes('@') ? '' : '@'}${node.value}`
        case 'tag':
            return `#${node.value}`

        case 'subpage':
            let url = server_host + '#/';
            if (node.docType === 'doc') {
                url += 'editor';
            } else if (node.docType === 'flow') {
                url += 'flow';
            } else if (node.docType === 'db') {
                url += 'db';
            } else {
                url += 'slidesViewer';
            }
            url += `?hid=${node.hid}`;

            return `[${node.title}](${url})`

        case 'slides-embed':
        case 'db-embed':
            const embed_url = node.type === 'db-embed' ?
                `${server_host}#/embed/db?hid=${node.hid}` :
                `${server_host}present.html?hid=${node.hid}`
            return `[${node.title || node.type}](${embed_url})`

        case 'rilmark':
            return `> ${node.highlight} ${node.note ? ('\n\nNotes: ' + node.note[0]?.text || '') : ''}`;

        case 'math_inline':
            return `$$${node.expression}$$`
        case 'math_block':
            return `\`$$${node.expression}$$\``
        // case 'table':
        //     return `<table>${children}</table>`
        case 'tr':
            let rowContent = `${children}|\n`;
            if (node.children[0]?.type === 'th') {
                rowContent += node.children.map(c => '|---').join('') + '|\n';
            }

            return rowContent
        case 'table':
            if (!children.includes('|---')) {
                const colCount = node.children[0].children.length;
                let header = '|';
                let seperator = '|';
                for (var i = 0; i < colCount; i++) {
                    header += '|';
                    seperator += '---|';
                }
                return header + '\n' + seperator + '\n' + children;
            }

            return children;
        case 'td':
            return `|${children}`
        case 'th':
            return `|${children}`

        default:
            return children;
    }
}

export const slateToMD = (blocks) => {
    const host = window.location.protocol + "//" + window.location.host;

    const md = blocks.map((b, index) => {
        if (['disc', 'decimal'].includes(b.listStyleType) && followedBySameTypeBlock(b, 'listStyleType', index, blocks)
            || ['action_item'].includes(b.type) && followedBySameTypeBlock(b, 'type', index, blocks)) {
            return serialize(host, b, null, 0).replace(/(\r\n|\n|\r)*$/, "");
        }

        return serialize(host, b, null, 0).replace(/(\r\n|\n|\r)*$/, "") + '\n';
    }).join('\n');

    return md?.trim();
}

export const parseMarkdownTable = (str) => {
    // 匹配表格的正则表达式
    const tableRegex = /^(\|[^\n]+\|\r?\n)((?:\|:?[-\s]+:?)+\|)(\n(?:\|[^\n]+\|\r?\n?)*)?$/mg;

    // 匹配表格头部分割线的正则表达式
    const headerRegex = /\s*\|?\s*:?-+:?\s*\|?\s*/g;

    let match = tableRegex.exec(str);

    if (!match) {
        // 如果字符串不符合表格格式，则返回 null
        return null;
    }

    // console.log("matches: ", match)

    const headerRow = match[1];
    const seperatorRow = match[2];
    const bodyRows = match[3] !== undefined ? match[3] : "";

    // console.log("headerRow: ", headerRow);
    // console.log("bodyRows: ", bodyRows);

    // 解析表格头部分割线
    const headerSeparator = seperatorRow.match(headerRegex);

    // console.log("headerSeparator: ", headerSeparator);

    // 解析表格头部分割线上每一列的对齐方式
    const alignments = headerSeparator.map((sep) => {
        if (sep.startsWith(':') && sep.endsWith(':')) {
            return 'center';
        } else if (sep.endsWith(':')) {
            return 'right';
        } else {
            return 'left';
        }
    });

    // console.log("alignments: ", alignments);

    // 解析表格头部分割线上每一列的标题
    const headers = headerRow
        .split('|')
        .map((h) => h.trim())
        .filter((h) => h);

    // console.log("headers: ", headers);

    // 解析表格主体
    const body = [];
    const rows = bodyRows.split("\n").map((row) => row.trim()).filter((row) => row);

    for (let i = 0; i < rows.length; i++) {
        const cols = rows[i].split("|").map((c) => c.trim()).filter((c) => c);
        body.push(cols);
    }

    // console.log("body: ", body);
    // 构造表格对象
    const table = {
        headers,
        alignments,
        rows: body,
    };

    // console.log("table: ", table);

    return table;
}

// 根据不同句子分隔符切割句子
function splitSentences(paragraph) {
    // 句子分隔符包括"."、"!"、"?"、汉字中的句号符号（。）、"？"和"！"
    return paragraph.split(/\.|\!|\?|。|？|！/).filter(sentence => sentence.trim() !== '');
}

// 判断是否为特殊段落（headings和list）
function isSpecialParagraph(paragraph) {
    // 假设特殊段落以"#"开头或以"*"、"-"或数字开头，可根据实际情况修改判断逻辑
    return paragraph.length < 300 && paragraph.startsWith("#") || /^\s*([*-]|\d+\.)\s+/.test(paragraph);
}

// 缩减文档长度
export const trimMarkdown = (markdownDocument, maxLength) => {
    let willDropLength = countWords(markdownDocument) - maxLength;
    let droppedLength = 0;

    // console.log('length............', countWords(markdownDocument), willDropLength)

    let paragraphs = markdownDocument.split("\n\n").filter(p => !!p).map(p => {
        if (isSpecialParagraph(p)) {
            return p;
        }

        return splitSentences(p);
    }).filter(p => !!p && p.length > 0);

    let nonSpecialParagraphs = paragraphs.map((p, index) => {
        if (typeof p !== 'string') {
            return {
                index
            }
        }

        return null;
    }).filter(item => !!item).map(item => item.index);

    const droppedParagraphs = [];
    while (willDropLength > droppedLength && nonSpecialParagraphs.length > 0) {
        let choosenParagraphIndex = nonSpecialParagraphs[chooseRandomly(nonSpecialParagraphs.length)];
        let choosenParagraph = paragraphs[choosenParagraphIndex];

        if (choosenParagraph.length === 0) {
            nonSpecialParagraphs = nonSpecialParagraphs.filter(item => item != choosenParagraphIndex);
            droppedParagraphs.push(choosenParagraphIndex);
            continue;
        }

        let choosenSentenceIndex = chooseRandomly(choosenParagraph.length);
        let choosenSentence = choosenParagraph[choosenSentenceIndex];

        droppedLength += countWords(choosenSentence);
        choosenParagraph.splice(choosenSentenceIndex, 1);
    }

    while (willDropLength > droppedLength && paragraphs.length > 1) {
        // console.log('drop paragraph, length............', willDropLength, droppedLength)
        let choosenParagraphIndex = chooseRandomly(paragraphs.length);
        while (droppedParagraphs.includes(choosenParagraphIndex)) {
            choosenParagraphIndex = chooseRandomly(paragraphs.length);
        }
        droppedLength += countWords(paragraphs[choosenParagraphIndex]);

        droppedParagraphs.push(choosenParagraphIndex);
    }

    return paragraphs.filter((p, index) => {
        return !droppedParagraphs.includes(index)
    }).map(p => {
        if (typeof p === 'string') {
            return p;
        } else {
            return p.join("。")
        }
    }).join("\n\n");
}

function chooseRandomly(length) {
    const random = Math.floor(Math.random() * length);
    return random;
}

function countWords(text) {
    const wordPattern = /\b\w+\b/g;

    const englishWordCount = (text.match(wordPattern) || []).length;
    const chineseCharCount = text.replace(/[^\u4e00-\u9fa5]/g, "").length;
    //Todo: 不清楚ChatGPT是否算空格、标点符合之类的
    const otherCharCount = text.replace(/[\u4e00-\u9fa5]/g, "").replace(wordPattern, "").length;
    const totalWordCount = englishWordCount + otherCharCount + chineseCharCount;

    return totalWordCount;
}

