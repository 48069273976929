import { DOC_ACTIONS } from '../constants/actionTypes'

export function byId(state = {}, action) {
    switch (action.type) {
        case DOC_ACTIONS.updated:
        case DOC_ACTIONS.received:
            return Object.assign({}, state, { [action.item.hid]: action.item });

        case DOC_ACTIONS.invalidated:
            return Object.keys(state).reduce((result, key) => {
                if (key !== action.item.hid) {
                    result[key] = state[key];
                }
                return result;
            }, {})
        case DOC_ACTIONS.grantedPermission:
            return Object.assign({}, state, { [action.hid]: { ...state[action.hid], grantedTo: action.grantedTo } });
        default:
            return state;
    }
}


