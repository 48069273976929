import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import { TabPanel, a11yProps } from '../tab/TabPanel';
import PromptsMine from './PromptsMine';
import PromptsPublic from './PromptsPublic';
import { useSelector } from 'react-redux';
import PromptsValidate from './PromptsValidate';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MEDIA_QUERY } from '../../utils/constants';
import './prompts.css'

const Prompts = ({ }) => {
    const intl = useIntl();
    const loginUser = useSelector(state => state.loginIn.user);

    const [tab, setTab] = React.useState(0);
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
                sx={{ 
                    '& .MuiTabs-flexContainer': {
                        justifyContent: 'center',
                    },
                }}
                value={tab} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={intl.formatMessage({ id: 'prompts_mine' })} {...a11yProps(0)} />
                    <Tab label={intl.formatMessage({ id: 'prompts_public' })} {...a11yProps(1)} />
                    {loginUser?.userLevel > 97 && <Tab label={intl.formatMessage({ id: 'prompts_validate' })} {...a11yProps(2)} />}
                </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
                <div style={{ paddingTop: '10px' }}>
                    <PromptsMine />
                </div>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <div style={{ paddingTop: '10px' }}>
                    <PromptsPublic />
                </div>
            </TabPanel>
            {
                loginUser?.userLevel > 97 &&
                <TabPanel value={tab} index={2}>
                    <div style={{ paddingTop: '10px' }}>
                        <PromptsValidate />
                    </div>
                </TabPanel>}
        </Box>
    );
}

export default Prompts;