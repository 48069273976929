import {createIntl, createIntlCache, RawIntlProvider} from 'react-intl'
import messages from '../messages';

let locale = navigator.language;

if(locale && locale.indexOf('zh-') > -1) {
  locale = 'cn';
}

if(locale != 'cn') {
  locale = 'en';
}

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache()

const intl = createIntl({
  locale: locale,
  messages: messages[locale],
}, cache);


export const getLocale = () => {
    return locale;
}


export default intl;