import { useIntl } from "react-intl"
import PrivilegeItem from "./PrivilegeItem"

const PrivilegesFunBlocks = ({ privileges_primitive, getQuotaText, capitalizeFirstLetter, sku }) => {
    const intl = useIntl();

    if(!privileges_primitive) return;

    return <>
        <div className="fill-available" style={{ height: 1, marginBottom: 18, marginTop: 18, backgroundColor: '#eee' }}></div>

        <div style={styles.advantage_title}>{intl.formatMessage({ id: 'advantage_of_product' }, { product: capitalizeFirstLetter(sku.service_level) })}</div>

        <div style={styles.advantage_title}>{intl.formatMessage({ id: 'more_capacity' })}</div>
        <PrivilegeItem text={intl.formatMessage({ id: 'blocks_capacity' }, { quota: getQuotaText(privileges_primitive?.blocks, intl) })} desc={intl.formatMessage({ id: 'blocks_capacity_desc' })} />
        <PrivilegeItem text={intl.formatMessage({ id: 'team_size' }, { quota: getQuotaText(privileges_primitive?.members, intl) })} />

        <div className="fill-available" style={{ height: 1, marginLeft: 26, marginRight: 26, marginBottom: 4, marginTop: 4, backgroundColor: '#eee' }}></div>

        <div style={styles.advantage_title}>{intl.formatMessage({ id: 'advanced_features' })}</div>
        <PrivilegeItem text={intl.formatMessage({ id: 'writing_assistant' })} />
        <PrivilegeItem text={intl.formatMessage({ id: 'markdown_slides' })} desc={intl.formatMessage({ id: 'markdown_slides_desc' })} />
        <PrivilegeItem text={intl.formatMessage({ id: 'doc_to_slides' })} />

        <div className="fill-available" style={{ height: 1, marginLeft: 26, marginRight: 26, marginBottom: 4, marginTop: 4, backgroundColor: '#eee' }}></div>
        <div style={styles.advantage_title}>{intl.formatMessage({ id: 'other_benefits' })}</div>
        <PrivilegeItem text={intl.formatMessage({ id: 'no_traffic_control' })} />
        <PrivilegeItem text={intl.formatMessage({ id: 'technical_support' })} />
    </>
}

const styles = {
    advantage_title: {
        fontWeight: 'bold',
        paddingTop: 8,
        paddingBottom: 8
    }
}

export default PrivilegesFunBlocks