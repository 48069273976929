import * as React from 'react';
import { EXPORT_DOC_DIALOG } from 'src/constants/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { DialogContent, Dialog, Button, DialogTitle, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// import { getStateByUser } from 'src/reducers/listReducer';
import { useIntl } from 'react-intl';

import { slateToMD } from '../../utils/SlateMarkdown';
import { Selector } from '../common/Selector';
import { getState, getStateByUser } from 'src/reducers/listReducer';
import { showMessage } from 'src/actions/uiAction';
import { fillAdvancedDbData, fillDbData } from '../dbeditor/DBUtil';
import { usePapaParse } from 'react-papaparse';
import { formatDate } from 'src/utils/timeFormater';
import { exportDoc, exportSlides } from 'src/actions/ticketAction';
import { Download } from '@styled-icons/material/Download';

const ExportModal = ({ doc_to_export }) => {
    const dialogState = useSelector(state => state.uiState.exportDocDialog);
    const dispatch = useDispatch();
    const intl = useIntl();
    const [fileType, setFileType] = React.useState('md');

    const loginUser = useSelector(state => state.loginIn.user);
    const orgs = useSelector(state => getStateByUser(state.org_lists, loginUser));
    const workingSpace = orgs.items.find(org => org._id === loginUser.workingOrgId) || { users: [] };
    const members = workingSpace.users.filter(user => !!user.user).map(user => user.user);
    const dbdata_list = useSelector(state => getState(state.dbdata_lists, doc_to_export?.hid));

    const [doc, setDoc] = React.useState(doc_to_export);
    const [exportFileTypes, setExportFileTypes] = React.useState();

    React.useEffect(() => {
        if (!dialogState?.doc) return;

        setDoc(dialogState.doc);
    }, [dialogState?.doc])

    React.useEffect(() => {
        if (!doc) return;

        if (doc.type === 'doc') {
            setExportFileTypes([{
                value: 'pdf',
                label: 'PDF'
            }, {
                value: 'docx',
                label: 'Word'
            }, {
                value: 'md',
                label: 'Markdown'
            }, {
                value: 'html',
                label: 'Html'
            }]);

            setFileType('pdf');
        } else if (doc.type === 'db') {
            setExportFileTypes([{
                value: 'csv',
                label: 'CSV',
            }]);

            setFileType('csv');
        } else {
            setExportFileTypes([{
                value: 'pdf',
                label: 'PDF'
            }, {
                value: 'md',
                label: 'Markdown'
            }]);

            setFileType('pdf');
        }
    }, [doc]);

    const pdf_slides_notes = [{
        label: intl.formatMessage({ id: 'same_page' }),
        value: 'same_page'
    }, {
        label: intl.formatMessage({ id: 'separated' }),
        value: 'separated'
    }, {
        label: intl.formatMessage({ id: 'excluded' }),
        value: 'excluded'
    }];

    const [showSlidesNotes, setShowSlidesNotes] = React.useState('same_page');

    const [dbdata, setDbdata] = React.useState([]);

    React.useEffect(() => {
        if (!doc || doc.type != 'db') {
            setDbdata([]);
            return;
        }

        const viewProperties = doc.meta?.properties;
        if (!dbdata_list || !dbdata_list.items || dbdata_list.items.length === 0 || !viewProperties || viewProperties.length === 0) {
            setDbdata([]);
            return;
        }

        let advancedData = fillAdvancedDbData(dbdata_list.items, viewProperties);
        setDbdata(fillDbData(advancedData, viewProperties, members));
    }, [dbdata_list, doc]);

    const handleClose = () => {
        dispatch({ type: EXPORT_DOC_DIALOG, value: { visible: false } });
    }

    const { jsonToCSV } = usePapaParse();

    const [loading, setLoading] = React.useState(false);

    const handleExport = () => {
        if (!doc) {
            return;
        }

        if (['pdf', 'html', 'docx'].includes(fileType)) {
            let export_action = exportDoc;
            let params = { hid: doc.hid, type: fileType };
            if (doc.type === 'slides') {
                export_action = exportSlides;
                params.showSlidesNotes = showSlidesNotes;
            }

            setLoading(true);
            dispatch(export_action(params, (data) => {
                data?.content && download(doc, data);
                setLoading(false);
                handleClose();
            }, () => {
                setLoading(false);
            }, 'export'));
            return;
        }

        let fileContent;

        if (doc.type === 'doc') {
            let blocks = [];
            if (doc.title) {
                blocks.push({
                    type: 'h1',
                    children: [{
                        text: doc.title
                    }]
                });
            }

            if (doc.blocks) {
                blocks = blocks.concat(doc.blocks);
            }

            if (!blocks?.length) {
                return;
            }

            if (fileType === 'md') {
                fileContent = slateToMD(blocks);
            }
        } else if (doc.type === 'slides') {
            fileContent = doc.markdown;
        } else {
            const properties = doc.meta?.properties;
            if (!properties || properties.length == 0) {
                showMessage(intl.formatMessage({ id: 'export_no_data' }));
                return;
            }

            if (dbdata) {
                fileContent = jsonToCSV(dbdata.map(data => {
                    let newData = {};
                    for (let p of properties) {
                        if (p.type === 'Date' && data.data[p.name]) {
                            const dateData = new Date(data.data[p.name]);
                            if (dateData !== "Invalid Date" && !isNaN(dateData)) {
                                let format = 'YYYY-MM-DD';
                                if (p.hasTime) {
                                    format += ' HH:mm:ss'
                                }
                                newData[p.label] = formatDate(dateData, format);
                            }
                        } else {
                            newData[p.label] = data.data[p.name];
                        }
                    }
                    return newData;
                }));
            }
        }

        fileContent && download(doc, { content: { data: fileContent, type: 'text/plain' } });

        handleClose();
    }

    const download = (doc, { content }) => {
        const element = document.createElement("a");
        const file = content.type === 'Buffer' ? new Blob([new Int8Array(content.data)]) : new Blob([content.data]);
        element.href = URL.createObjectURL(file);
        element.download = `${doc.title}.${fileType}`;
        document.body.appendChild(element);
        element.click();
    }

    return (
        <Dialog
            open={!!dialogState && dialogState.visible}
            onClose={handleClose}
            scroll='paper'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth='sm'
        >
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ padding: '8px', fontWeight: 500, color: 'gray' }}>{intl.formatMessage({ id: 'export' })}</div>
            </div>
            <DialogContent dividers={true} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', rowGap: 8, height: 160, width: 360, padding: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'row', columnGap: 5 }}>
                    <div>{intl.formatMessage({ id: 'export_format' })}</div>
                    <Selector
                        value={fileType}
                        options={exportFileTypes}
                        onChange={
                            (value) => setFileType(value)
                        }
                    />
                </div>
                {
                    doc?.type === 'slides' && fileType === 'pdf' &&
                    <div style={{ display: 'flex', flexDirection: 'row', columnGap: 5 }}>
                        <div>{intl.formatMessage({ id: 'export_slides_notes' })}</div>
                        <Selector
                            value={showSlidesNotes}
                            options={pdf_slides_notes}
                            onChange={
                                (value) => setShowSlidesNotes(value)
                            }
                        />
                    </div>
                }
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>
                    {
                        intl.formatMessage({ id: 'cancel' })
                    }
                </Button>
                <LoadingButton
                    variant='contained'
                    loading={loading}
                    loadingPosition="end"
                    endIcon={<Download size={20} />}
                    onClick={handleExport}
                >
                    <span>
                        {intl.formatMessage({ id: 'export' })}
                    </span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ExportModal;
