import * as React from 'react';
import { useIntl } from 'react-intl';
import CircularProgress from '@mui/material/CircularProgress';
import { KeyboardReturn } from '@styled-icons/material';
import { DropDownMenu } from './DropDownMenu';
import { Search } from '@styled-icons/remix-line/Search';
import { LightUp } from '@styled-icons/entypo/LightUp';
import { ListTask } from '@styled-icons/bootstrap/ListTask';
import { List } from '@styled-icons/fluentui-system-filled/List';
import { Link } from '@styled-icons/entypo/Link';
import { Magic as Dynamic } from '@styled-icons/remix-line/Magic';
import { Message } from '@styled-icons/boxicons-regular/Message';
import { StickyNote } from '@styled-icons/remix-line/StickyNote';
import Tooltip from '@mui/material/Tooltip';
import { BookOpen } from '@styled-icons/remix-line';
import { Movie } from '@styled-icons/boxicons-regular/Movie';

const Prompt_QueryTypes = [{
  type: 'dynamic',
  textId: 'dynamic',
  icon: <Dynamic size={16} />,
}, {
  type: 'ask',
  textId: 'ask_question',
  icon: <Message size={16} />,
}, {
  type: 'breakdown',
  textId: 'breakdown_topics',
  icon: <List size={16} />,
}, {
  type: 'brainstorming',
  textId: 'brainstorming',
  icon: <LightUp size={16} />,
}, {
  type: 'book',
  textId: 'generate_book_mindmap',
  icon: <BookOpen size={16} />,
}, {
  type: 'movie',
  textId: 'generate_movie_mindmap',
  icon: <Movie size={16} />,
}, {
  type: 'todos',
  textId: 'generate_todo_list',
  icon: <ListTask size={16} />,
}, {
  type: 'search',
  textId: 'search_web',
  icon: <Search size={16} />,
}, {
  type: 'link',
  textId: 'add_link',
  icon: <Link size={16} />,
}, {
  type: 'note',
  textId: 'as_note',
  icon: <StickyNote size={16} />,
}];

export const doableAfterTriggeredTypes = ['ask', 'search'];

export const PromptTypeDropdownAndActionButton = ({ nodeType, queryType, queryTypeOptions, triggered, loading, actionEnabled, onActionButtonClicked, onSelect, color_theme, queryTypeChangable, isDropdown, setDropdown }) => {
  const intl = useIntl();

  const confirm_icon = React.useMemo(() => Prompt_QueryTypes.find(item => item.type === (queryType || nodeType))?.icon || <KeyboardReturn size={16} />, [queryType]);
  const Selectable_Querytypes = React.useMemo(() => {
    if (!queryTypeChangable && !queryTypeOptions) return null;

    if(queryTypeOptions?.length) {
      return Prompt_QueryTypes.filter(item => queryTypeOptions.includes(item.type)).map(item => {
        return {
          value: item.type,
          label: intl.formatMessage({ id: item.textId }),
          icon: item.icon
        };
      });
    }

    let types = (!triggered ? Prompt_QueryTypes : (['ask', 'search'].includes(queryType) && Prompt_QueryTypes.filter(item => ['ask', 'search'].includes(item.type)) || []))?.map(item => {
      return {
        value: item.type,
        label: intl.formatMessage({ id: item.textId }),
        icon: item.icon
      };
    });

    if (nodeType == 'note') {
      types = types.filter(item => item.value != nodeType);
    }

    return types;
  }, [queryTypeChangable, queryType, nodeType, queryTypeOptions, triggered, intl]);

  if ((triggered && !['ask', 'ask_question', 'search'].includes(queryType))) {
    return null;
  }

  return <>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 'fit-content' }}>
      {
        nodeType != 'note' &&
        <Tooltip placement='top' title={intl.formatMessage({ id: 'click_to_execute' }, {action: intl.formatMessage({ id: 'node_action_' + (queryType == 'ask_question_or_choose_skill' && 'ask' || queryType) })})} arrow>
          <div
            className='transparent-background'
            style={{
              padding: 2,
              paddingLeft: 4,
              paddingRight: 4,
              cursor: 'pointer',
              borderRadius: '4px',
              border: `1px solid ${actionEnabled ? color_theme.border : '#aaa'}`,
              whiteSpace: 'nowrap',
              color: actionEnabled ? color_theme.border : '#aaa',
            }}
            onClick={() => !loading && actionEnabled && onActionButtonClicked && onActionButtonClicked()}
          >
            {!loading && confirm_icon}
            {loading && <CircularProgress size={16} />}
          </div>
        </Tooltip>
      }
      {!!Selectable_Querytypes?.length &&
        <DropDownMenu
          items={Selectable_Querytypes}
          value={queryType === 'ask_question_or_choose_skill' && 'ask' || queryType}
          isDropdown={isDropdown}
          setDropdown={setDropdown}
          color_theme={color_theme}
          style={{
            marginTop: 4
          }}
          tooltip={intl.formatMessage({id: 'change_prompt_querytype'})}
          onSelect={onSelect} />}
    </div>
  </>;
};
