import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { InputModal } from './InputModal';

export const PopoverInput = (props) => {
    const intl = useIntl();
    const [anchorEl, setAnchorEl] = useState(null);
    const { children, onChange, onSubmit, multiline = false, transformOrigin, style, containerStyle } = props;
    const [value, setValue] = useState(props.value || '');

    useEffect(() => {
        setValue(props.value || '');
    }, [props.value]);

    return (
        <div>
            <div onClick={(e) => setAnchorEl(e.target)}>
                {children}
            </div>
            <InputModal 
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange && onChange(e);
                }}
                onSubmit={onSubmit}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                value={value}
                multiline={multiline}
                transformOrigin={transformOrigin}
                style={style}
                containerStyle={containerStyle}
            />
        </div>
    );
}