import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getState } from "src/reducers/listReducer";
import { useDispatch } from "react-redux";
import { fetchDocs } from "src/actions/ticketAction";
import { Divider } from "@mui/material";
import { useIntl } from "react-intl";
import { FileSlides } from '@styled-icons/bootstrap/FileSlides';
import { FileSpreadsheet } from "@styled-icons/bootstrap/FileSpreadsheet";
import { FileText } from "@styled-icons/bootstrap/FileText";
import { CloseCircle } from '@styled-icons/ionicons-sharp/CloseCircle';
import { Selector } from "./common/Selector";
import { FileMedical } from "@styled-icons/bootstrap";

export const PageChooser = (props) => {
    const { hideTitle, excludes, accepts } = props;

    const dispatch = useDispatch();
    const intl = useIntl();

    const loginUser = useSelector(state => state.loginIn.user);
    const doc_lists = useSelector((state) => state.doc_lists);
    const docs = getState(doc_lists, loginUser.workingOrgId)
    const [filteredDocs, setFilteredDocs] = useState(docs && docs.items || []);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const searchInputRef = useRef();

    const [searchText, setSearchText] = useState("");
    const [type, setType] = useState();

    useEffect(() => {
        if (!accepts?.length) {
            return setType('doc');
        }

        setType(accepts[0])
    }, accepts)

    useEffect(() => {
        if(!type) return;

        let data = { type, orgId: loginUser.workingOrgId, pageSize: 10000 };
        if (type === 'db') {
            data.isDataSource = true;
        }
        dispatch(fetchDocs(data));
    }, [type]);

    useEffect(() => {
        if (!docs) {
            return;
        }

        let docItems = docs.items;
        if (excludes) {
            docItems = docItems.filter(item => !excludes.includes(item.hid));
        }

        if (searchText) {
            setFilteredDocs(docItems.filter(doc => (doc.title || '').toLowerCase().includes(searchText.toLowerCase())));
        } else {
            setFilteredDocs(docItems);
        }
    }, [searchText, docs, excludes]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            props.onSelect(filteredDocs[selectedIndex]);

            e.preventDefault();
            e.stopPropagation();
        }
    }
    const handleKeyDown = (e) => {
        if (['Escape', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
            if (e.key === 'Escape') {
                props.onClose();
            }

            if (e.key === 'ArrowUp') {
                if (selectedIndex > 0) {
                    setSelectedIndex(selectedIndex - 1);
                } else {
                    setSelectedIndex(filteredDocs.length - 1);
                }
            }

            if (e.key === 'ArrowDown') {
                if (selectedIndex < filteredDocs.length - 1) {
                    setSelectedIndex(selectedIndex + 1);
                } else {
                    setSelectedIndex(0);
                }
            }


            e.preventDefault();
            e.stopPropagation();
        }
    }

    const elmRefs = useRef({});
    useLayoutEffect(() => {
        elmRefs[selectedIndex] && elmRefs[selectedIndex].current.scrollIntoView();
        const selectedItem = elmRefs.current[selectedIndex];
        selectedItem && selectedItem.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, [selectedIndex])


    useLayoutEffect(() => {
        if (props.searchInputRef && props.searchInputRef.current) {
            props.searchInputRef.current.focus();
        }

        if (searchInputRef && searchInputRef.current) {
            setTimeout(() => {
                searchInputRef.current.focus();
            }, 10);
        }
    }, [props.searchInputRef, searchInputRef, type])

    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflowY: 'hidden',
            overflowX: 'hidden',
            height: '100%',
        }}

        onKeyDown={handleKeyDown}
        onKeyPress={handleKeyPress}
    >
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '8px',
            padding: '10px',
            paddingTop: '8px',
            paddingBottom: '6px'
        }}
        >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '4px' }}>
                {
                    accepts?.length > 1 &&
                    <Selector
                        options={accepts.map((value, index) => {
                            return {
                                label: intl.formatMessage({ id: value }),
                                value
                            }
                        })}
                        value={type}
                        onChange={(value) => {
                            setType(value);
                        }}
                        inputStyle={{
                            overflow: 'inherit'
                        }}
                    />
                }
                <input
                    type="text"
                    placeholder="Search"
                    ref={props.searchInputRef || searchInputRef}
                    autoFocus
                    style={{
                        width: '-webkit-fill-available',
                        padding: '1.5px 4px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        outline: 'none'
                    }}

                    onFocus={(e) => {
                        e.target.select();
                    }}

                    onChange={(e) => {
                        setSearchText(e.target.value);
                    }}
                />
            </div>
        </div>
        <Divider />
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
        }}>
            {
                filteredDocs &&
                filteredDocs.map((item, index) => {
                    let icon = <FileText size={18} />;
                    if (item.type === 'slides') {
                        icon = <FileSlides size={18} />;
                    } else if (item.type === 'flow') {
                        icon = <FileMedical size={18} />;
                    } else if (item.type === 'db') {
                        icon = <FileSpreadsheet size={18} />;
                    }

                    return <div
                        className="hoverStand"
                        key={item._id}
                        ref={(ref) => {
                            elmRefs.current = { ...elmRefs.current, [index]: ref };
                        }}
                        onClick={() => props.onSelect(item)}
                        style={{
                            columnGap: '2px',
                            paddingLeft: '8px',
                            backgroundColor: selectedIndex === index ? '#f5f5f5' : '#fff',
                        }}
                    >
                        <div style={{ width: 20, alignItems: 'center', display: 'flex', flexDirection: 'row' }}>{icon}</div>
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {item.title || intl.formatMessage({ id: 'untitled' })}
                        </div>
                    </div>
                }
                )
            }
        </div>
    </div>
}