import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from "react-dom/client";
import App from './App'
import { Provider } from 'react-redux'
import getStoreConfig from './store'
import { PersistGate } from 'redux-persist/integration/react'
import reportWebVitals from './reportWebVitals'

const { store, persistor } = getStoreConfig();

// ReactDOM.render(
//   <Provider store={store}>
//     <PersistGate loading={null} persistor={persistor}>
//       <App />
//     </PersistGate>
//   </Provider>,
//   document.getElementById('root'),
// )

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

reportWebVitals();
