import { Box, Dialog, DialogTitle, DialogContent, TextField, Button, DialogActions, Chip, Divider, ListItem, ListItemText, Select, MenuItem } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { inviteUsersToOrg } from 'src/actions/ticketAction';
import { INVITE_ORG_DIALOG } from 'src/constants/actionTypes';
import { validateEmail, validatePhone } from 'src/utils/validator';

const InviteToOrg = ({ workingSpace }) => {
  const dialogState = useSelector(state => state.uiState.inviteToOrgDialog);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [selectedItems, setSelectedItems] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [itemTargeted, setItemTargeted] = React.useState(0);
  const [inviteValid, setInviteValid] = React.useState(true);
  const [role, setRole] = React.useState('admin');

  React.useEffect(() => {
    setSelectedItems([]);
  }, [dialogState.visible]);

  const handleDelete = (chipToDelete) => () => {
    setSelectedItems(selectedItems.filter(chip => chip._id !== chipToDelete._id));
  }

  const handleClose = () => {
    dispatch({ type: INVITE_ORG_DIALOG, value: { visible: false } });
  }

  const handleChange = (item) => {
    setSearchText("");
    if (!selectedItems.find(i => i._id === item._id)) {
      setSelectedItems([...selectedItems, item]);
    }
  }

  function handleInputChange(event) {
    setInviteValid(true);
    setSearchText(event.target.value);
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (itemTargeted < 0 || itemTargeted >= filteredUsers.length) {
        return;
      }

      if (itemTargeted >= 0 && selectedItems.indexOf(filteredUsers[itemTargeted].user) === -1) {
        setSelectedItems([...selectedItems, filteredUsers[itemTargeted].user]);
        setItemTargeted(0);
      }

      setSearchText("");
    }
  }

  const onKeyDown = (event) => {
    if (event.key === 'Backspace') {
      if (searchText.length === 0) {
        setSelectedItems(selectedItems.slice(0, selectedItems.length - 1));
      }
    } else if (event.key === 'ArrowDown') {
      if (itemTargeted < filteredUsers.length - 1) {
        setItemTargeted(itemTargeted + 1);
      } else {
        setItemTargeted(0);
      }
    } else if (event.key === 'ArrowUp') {
      if (itemTargeted > 0) {
        setItemTargeted(itemTargeted - 1);
      } else {
        setItemTargeted(filteredUsers.length - 1);
      }
    }
  }

  const [filteredUsers, setFilteredUsers] = React.useState([]);


  React.useEffect(() => {
    if ((validateEmail(searchText) || validatePhone(searchText))
      && !workingSpace.users.map(({ user, role }) => {
        if (role === 'guest' || !user) {
          return '';
        } else {
          return user.username;
        }
      }).includes(searchText)) {
      setFilteredUsers([{ user: { username: searchText, email: validateEmail(searchText) ? searchText : null, type: 'toInvite' }, role }]);
    } else {
      setFilteredUsers([]);
    }

  }, [searchText, workingSpace.users]);

  const handleConfirm = () => {
    const items = [...selectedItems];
    if (filteredUsers.length > 0) {
      items.push(filteredUsers[0].user);
    }

    if (items.length === 0) {
      setInviteValid(false);
      return;
    }

    dispatch(inviteUsersToOrg({
      objs: items.map(item => {
        return {
          ...item,
          role
        }
      })
    }, handleClose, 'inviteToOrg'));
  }

  return (
    <Dialog
      open={!!dialogState && dialogState.visible}
      onClose={handleClose}
      scroll='paper'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{intl.formatMessage({ id: 'invite' })}</span>
      </DialogTitle>
      <DialogContent dividers={true} style={{ height: '60vh' }}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <TextField
            autoComplete='off'
            required
            id="invite"
            label="phone, email, user, groups..."
            style={{ width: '480px', padding: '0px', margin: '0px' }}
            value={searchText}
            InputProps={{
              startAdornment: <Box sx={{ display: 'flex', flexWrap: 'wrap' }}> {selectedItems.map((item, index) => (
                <Chip
                  key={index}
                  tabIndex={-1}
                  label={item.username || item.nickname}
                  onDelete={handleDelete(item)}
                />
              ))}
              </Box>,
              // endAdornment:,
              // onBlur,
              onChange: event => {
                handleInputChange(event);
              },
              onKeyDown,
              onKeyPress,
              autoFocus: true,
              error: !inviteValid && selectedItems.length === 0,
            }}
          />
          <Select
            value={role}
            style={{ marginLeft: '10px' }}
          >
            <MenuItem value='admin' onClick={() => setRole('admin')}>Admin</MenuItem>
            <MenuItem value='member' onClick={() => setRole('member')}>Member</MenuItem>
          </Select>
        </Box>
        {/* <Divider /> */}
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
          {
            filteredUsers.map(({ user, role }, index) => (<ListItem
              key={user.username}
              button 
              onClick={() => handleChange(user)}
              style={{ backgroundColor: index === itemTargeted ? '#eee' : 'transparent' }}
            >
              <ListItemText style={{ width: '320px' }} primary={user.name || user.nickname} secondary={(user.username || user.name) + ' @' + role} />
            </ListItem>))
          }
        </div>
      </DialogContent>
      <DialogActions style={{paddingRight: 12}}>
        <Button onClick={handleClose}>{intl.formatMessage({ id: 'cancel' })}</Button>
        <Button variant="contained" onClick={handleConfirm}>{intl.formatMessage({ id: 'confirm' })}</Button>
      </DialogActions>
    </Dialog>
  )
}


export default InviteToOrg;
