import { useEffect, useRef, useState } from 'react';
import { 
    Button,
    Dialog,
    DialogContent,
    Box,
    Stack,
    TextField,
    Typography,
    Divider
} from "@mui/material";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { IMAGE_UPLOAD_DIALOG, OPERATION_FAILED } from 'src/constants/actionTypes';
import { uploadImgs } from 'src/actions/ticketAction';
import { useLocation } from 'react-router-dom';
import PromoteVIP from '../settings/PromoteVIP';
import { getProxiedImageUrl } from '../../utils/url';
import { get_server_host } from '../../utils/serverAPIUtil';

export const ImageUploadModal = ({ isMobile }) => {
    const intl = useIntl();
    const fileInputRef = useRef(null);
    const currentLocation = useLocation();
    const params = new Proxy(new URLSearchParams(currentLocation.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    const dialogState = useSelector(state => state.uiState.imageUploadDialog) || { visible: false };
    const promote_vip = useSelector(state => state.uiState.promote_vip) || {};
    const dispatch = useDispatch();
    const hid = dialogState.hid || params.hid;

    const [image, setImage] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');

    const handleClose = () => {
        dispatch({ type: IMAGE_UPLOAD_DIALOG, value: { visible: false } });
    };

    const confirmInsert = (imageData) => {
        if (!imageData.link) return;
        dispatch({
            type: IMAGE_UPLOAD_DIALOG,
            value: {
                ...dialogState,
                visible: false,
                image: imageData
            }
        });
    };

    const handleUrlChange = async (e) => {
        const newUrl = e.target.value;
        setUrl(newUrl);
        setPreviewUrl(getProxiedImageUrl(newUrl, await get_server_host()));
        if (newUrl) {
            setImage({
                link: newUrl,
                altText: image.altText
            });
        }
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage({
                file,
                altText: image.altText
            });
            setUrl('');
            // Create preview URL for the selected file
            const objectUrl = URL.createObjectURL(file);
            setPreviewUrl(objectUrl);
            
            return () => URL.revokeObjectURL(objectUrl);
        }
    };

    const handleConfirm = () => {
        if (url) {
            confirmInsert(image);
        } else if (image.file) {
            setIsLoading(true);
            dispatch(uploadImgs({ files: [image.file], hid, enctype: 'multipart' }, (files) => {
                setIsLoading(false);
                if (!files || !files.length || !files[0].uri) {
                    return dispatch({
                        type: OPERATION_FAILED,
                        message: intl.formatMessage({ id: 'upload_failed' })
                    });
                }

                confirmInsert({
                    ...image,
                    link: files[0].uri
                });
            }, 'editor'));
        }
    };

    useEffect(() => {
        if (dialogState.visible) {
            setImage({});
            setUrl('');
            setPreviewUrl('');
        }
    }, [dialogState.visible]);

    return (
        <Dialog
            open={!!dialogState && dialogState.visible}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    handleConfirm();
                    event.preventDefault();
                    event.stopPropagation();
                }
            }}
        >
            <DialogContent>
                <Box sx={{ p: 2 }}>
                    <Stack spacing={3}>
                        {/* Upload and URL input row */}
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Box>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    style={{ display: 'none' }}
                                />
                                <Button
                                    variant="contained"
                                    onClick={() => fileInputRef.current.click()}
                                    disabled={isLoading}
                                    sx={{ whiteSpace: 'nowrap' }}
                                >
                                    {intl.formatMessage({ id: 'choose_image' })}
                                </Button>
                            </Box>
                            
                            <Typography color="text.secondary" sx={{ mx: 1 }}>
                                {intl.formatMessage({ id: 'or' })}
                            </Typography>

                            <TextField
                                fullWidth
                                size="small"
                                placeholder={intl.formatMessage({ id: 'paste_image_url' })}
                                value={url}
                                onChange={handleUrlChange}
                                disabled={isLoading}
                            />
                        </Stack>

                        {/* File name display */}
                        {image.file && (
                            <Typography variant="body2" color="text.secondary">
                                {image.file.name}
                            </Typography>
                        )}

                        {/* Image preview */}
                        {previewUrl && (
                            <Box 
                                sx={{ 
                                    width: '100%',
                                    height: '200px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    bgcolor: 'background.default',
                                    borderRadius: 1,
                                    overflow: 'hidden'
                                }}
                            >
                                <Box
                                    component="img"
                                    src={previewUrl}
                                    alt="Preview"
                                    sx={{
                                        maxWidth: '100%',
                                        maxHeight: '200px',
                                        objectFit: 'contain'
                                    }}
                                    onError={(e) => {
                                        e.target.style.display = 'none';
                                    }}
                                />
                            </Box>
                        )}

                        {/* Alt text input */}
                        <Stack direction="row" spacing={2}>
                            <Typography sx={{ width: 80, textAlign: 'right', pt: 1 }}>
                                {intl.formatMessage({ id: 'alt_text' })}
                            </Typography>
                            <TextField
                                fullWidth
                                size="small"
                                value={image.altText || ''}
                                onChange={(e) => setImage({
                                    ...image,
                                    altText: e.target.value
                                })}
                            />
                        </Stack>

                        {promote_vip?.visible && <PromoteVIP onClose={handleClose} />}

                        {/* Action buttons */}
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button onClick={handleClose}>
                                {intl.formatMessage({ id: 'cancel' })}
                            </Button>
                            <Button 
                                variant="contained" 
                                onClick={handleConfirm}
                                disabled={isLoading || (!image.file && !url)}
                            >
                                {intl.formatMessage({ id: 'confirm' })}
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </DialogContent>
        </Dialog>
    );
};