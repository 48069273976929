import React, { useState } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { USER_GUIDES } from '../../constants/actionTypes';
import { useIntl } from 'react-intl';

const FlowGuide = () => {
    const [runTour, setRunTour] = useState(true);
    const loginUser = useSelector(state => state.loginIn.user);
    const user_guides = useSelector(state => state.uiState.user_guides);
    const dispatch = useDispatch();
    const intl = useIntl();

    const steps = [
        {
            "target": ".flow-logo",
            "content": "欢迎使用 FunBlocks AIFlow！",
            "disableBeacon": true,
            "id": "flow-editor"
        },
        {
            "target": ".flow-boards",
            "content": "查看或创建白板",
            "id": "flow-boards"
        },
        {
            "target": ".dynamic-ai-nodes",
            "content": "开始AI互动",
            "id": "dynamic-ai-nodes"
        },
        {
            "target": ".more-ai-nodes",
            "content": "更多AI互动节点",
            "id": "more-ai-nodes"
        },
        {
            "target": ".non-ai-nodes",
            "content": "便签、待办清单",
            "id": "non-ai-nodes"
        },
        {
            "target": ".model-selector",
            "content": "选择AI模型",
            "id": "model-selector"
        },
        {
            "target": ".output-lng-selector",
            "content": "设置AI输出语言",
            "id": "output-lng-selector"
        },
        {
            "target": ".board-operations",
            "content": "保存、撤销、重做",
            "id": "board-operations"
        },
        {
            "target": ".share-board",
            "content": "导出或分享白板，获得免费AI访问奖励",
            "id": "share-board"
        },
        {
            "target": ".download-extension",
            "content": "下载FunBlocks AI浏览器插件，提升工作、学习效率",
            "id": "download-extension"
        },
        {
            "target": ".settings",
            "content": "应用设置，账户管理",
            "id": "settings"
        },
        {
            "target": ".board-viewport-operations",
            "content": "调整白板视图",
            "id": "board-viewport-operations"
        },
        {
            "target": ".board-interaction-hint",
            "content": "白板操作指南",
            "id": "board-interaction-hint"
        },
        {
            "target": ".minimap",
            "content": "缩略图导航",
            "id": "minimap"
        }
    ].map(item => {
        item.content = intl.formatMessage({id: item.id})
        return item;
    });

    const handleJoyrideCallback = (data) => {
        const { status } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setRunTour(false);
            dispatch({
                type: USER_GUIDES,
                value: {
                    ...user_guides,
                    flow_done: true
                }
            })
        }
    };

    if (!loginUser?._id || user_guides?.flow_done) {
        return null;
    }

    return (
        <Joyride
            steps={steps}
            run={runTour}
            continuous={true}
            showSkipButton={true}
            showProgress={true}
            callback={handleJoyrideCallback}
            styles={{
                options: {
                    arrowColor: '#e3ffeb',
                    backgroundColor: '#e3ffeb',
                    overlayColor: 'rgba(0, 0, 0, 0.5)',
                    primaryColor: 'dodgerblue',
                    textColor: '#004a14',
                    width: 400,
                    zIndex: 1002,
                }
            }}
        />
    );
};

export default FlowGuide;