import intl from '../utils/Intl';
import { FormatListBulleted } from '@styled-icons/material/FormatListBulleted'
import { ArrowSquareDown } from '@styled-icons/fluentui-system-regular/ArrowSquareDown'
import { TextDescription } from '@styled-icons/fluentui-system-filled/TextDescription'
import { Title } from '@styled-icons/material/Title';
import { NumberSymbol } from '@styled-icons/fluentui-system-filled/NumberSymbol'
import { DateRange } from '@styled-icons/material/DateRange'
import { PeopleAlt } from '@styled-icons/material-outlined/PeopleAlt'
import { CheckboxChecked } from '@styled-icons/fluentui-system-regular/CheckboxChecked'
import { AlternateEmail } from '@styled-icons/material/AlternateEmail'
import { Phone } from '@styled-icons/material/Phone'
import { Link } from '@styled-icons/material';
import { Kanban } from '@styled-icons/bootstrap/Kanban';
import { Table } from '@styled-icons/bootstrap/Table';
import { TextBulletListSquare } from '@styled-icons/fluentui-system-regular/TextBulletListSquare';
import { GridView } from '@styled-icons/material-outlined/GridView';
import { Calendar4Event } from '@styled-icons/bootstrap/Calendar4Event';
import { InsertChart } from '@styled-icons/material-outlined/InsertChart';
import { AccessTime } from '@styled-icons/material/AccessTime';
import { User } from '@styled-icons/boxicons-regular/User';
import { BarChart } from '@styled-icons/ionicons-outline/BarChart';

export const RIL_FOLDER = {
    ril: 0,
    read: 1,
    trashbin: 2
}

export const DOC_PERMISSION_ARRAY = [{
    text: intl.formatMessage({ id: 'full_access' }),
    discription: intl.formatMessage({ id: 'full_access_description' }),
    value: 99
}, {
    text: intl.formatMessage({ id: 'can_edit' }),
    discription: intl.formatMessage({ id: 'can_edit_description' }),
    value: 9
    // }, {
    //     text: intl.formatMessage({ id: 'can_comment' }), 
    //     discription: intl.formatMessage({ id: 'can_comment_description' }),
    //     value: 6,
}, {
    text: intl.formatMessage({ id: 'can_view' }),
    discription: intl.formatMessage({ id: 'can_view_description' }),
    value: 3
}, {
    text: intl.formatMessage({ id: 'no_access' }),
    value: -1
}]

export const DB_PROPERTY_TYPES = [{
    value: 'Title',
    label: intl.formatMessage({ id: 'type_title' }),
    icon: <Title size={18} style={{ paddingRight: '2px' }} />
}, {
    value: 'Text',
    label: intl.formatMessage({ id: 'type_text' }),
    icon: <TextDescription size={18} style={{ paddingRight: '2px' }} />
}, {
    value: 'Number',
    label: intl.formatMessage({ id: 'type_number' }),
    icon: <NumberSymbol size={16} style={{ paddingRight: '2px' }} />
}, {
    value: 'Date',
    label: intl.formatMessage({ id: 'type_date' }),
    icon: <DateRange size={18} style={{ paddingRight: '2px' }} />
}, {
    value: 'Person',
    label: intl.formatMessage({ id: 'type_person' }),
    icon: <PeopleAlt size={18} style={{ paddingRight: '2px' }} />
}, {
    value: 'Select',
    label: intl.formatMessage({ id: 'type_select' }),
    icon: <ArrowSquareDown size={19} style={{ paddingRight: '2px' }} />,
}, {
    value: 'MultiSelect',
    label: intl.formatMessage({ id: 'type_multi_select' }),
    icon: <FormatListBulleted size={18} style={{ paddingRight: '2px' }} />,
}, {
    value: 'Checkbox',
    label: intl.formatMessage({ id: 'type_checkbox' }),
    icon: <CheckboxChecked size={18} style={{ paddingRight: '2px' }} />,
}, {
    value: 'Email',
    label: intl.formatMessage({ id: 'type_email' }),
    icon: <AlternateEmail size={18} style={{ paddingRight: '2px' }} />,
}, {
    value: 'Phone',
    label: intl.formatMessage({ id: 'type_phone' }),
    icon: <Phone size={18} style={{ paddingRight: '2px' }} />,
}, {
    value: 'Url',
    label: intl.formatMessage({ id: 'type_url' }),
    icon: <Link size={18} style={{ paddingRight: '2px' }} />,
}, {
    value: 'MultiSelect',
    advancedType: 'tags',
    // category: 'advanced',
    label: intl.formatMessage({ id: 'type_tags' }),
    icon: <FormatListBulleted size={18} style={{ paddingRight: '2px' }} />,
    disabled: true
}, {
    value: 'Date',
    advancedType: 'createdAt',
    // category: 'advanced',
    label: intl.formatMessage({ id: 'type_created_at' }),
    icon: <AccessTime size={18} style={{ paddingRight: '2px' }} />,
}, {
    value: 'Person',
    advancedType: 'createdBy',
    // category: 'advanced',
    label: intl.formatMessage({ id: 'type_created_by' }),
    icon: <User size={18} style={{ paddingRight: '2px' }} />,
}, {
    value: 'Date',
    advancedType: 'updatedAt',
    // category: 'advanced',
    label: intl.formatMessage({ id: 'type_updated_at' }),
    icon: <AccessTime size={18} style={{ paddingRight: '2px' }} />,
}, {
    value: 'Person',
    advancedType: 'updatedBy',
    // category: 'advanced',
    label: intl.formatMessage({ id: 'type_updated_by' }),
    icon: <User size={18} style={{ paddingRight: '2px' }} />,
}];

export const VIEW_LAYOUTS = [{
    name: 'table',
    label: intl.formatMessage({ id: 'table' }),
    icon: <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '22px',
        height: '22px',
        paddingLeft: '2px',
    }}> <Table size={14} /> </div>,
}, {
    name: 'board',
    label: intl.formatMessage({ id: 'board' }),
    icon: <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '22px',
        height: '22px',
        paddingLeft: '2px',
    }}><Kanban size={15} /></div>,
}, {
    name: 'list',
    label: intl.formatMessage({ id: 'list' }),
    icon: <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '23px',
        height: '22px',
        paddingLeft: '0px',
    }}><TextBulletListSquare size={19} /></div>,
}, {
    name: 'gallery',
    label: intl.formatMessage({ id: 'gallery' }),
    icon: <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '23px',
        height: '22px',
        paddingLeft: '0px',
    }}><GridView size={18} /></div>,
}, {
    name: 'timeline',
    label: intl.formatMessage({ id: 'timeline' }),
    icon: <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '21px',
        height: '22px',
        paddingLeft: '2px',
    }}><Calendar4Event size={14} /></div>,
}, {
    name: 'chart',
    label: intl.formatMessage({ id: 'chart' }),
    icon: <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '21px',
        height: '22px',
        paddingLeft: '2px',
    }}><BarChart size={16} /></div>,
}];

export const OPTION_BG_COLORS = [
    { label: intl.formatMessage({ id: 'color_whitesmoke' }), value: '#f5f5f5' },
    { label: intl.formatMessage({ id: 'color_lightgray' }), value: '#d3d3d3' },
    { label: intl.formatMessage({ id: 'color_beige' }), value: '#f5f5dc' },
    { label: intl.formatMessage({ id: 'color_azure' }), value: '#F0FFFF' },
    { label: intl.formatMessage({ id: 'color_lightcyan' }), value: '#E0FFFF' },
    { label: intl.formatMessage({ id: 'color_skyblue' }), value: '#87ceeb' },
    { label: intl.formatMessage({ id: 'color_honeydew' }), value: '#F0FFF0' },
    { label: intl.formatMessage({ id: 'color_pomonagreen' }), value: '#C1FFC1' },
    { label: intl.formatMessage({ id: 'color_lightgreen' }), value: '#90ee90' },
    { label: intl.formatMessage({ id: 'color_lightyellow' }), value: '#ffffe0' },
    { label: intl.formatMessage({ id: 'color_yellow' }), value: '#ffff00' },
    { label: intl.formatMessage({ id: 'color_gold' }), value: '#ffd700' },
    { label: intl.formatMessage({ id: 'color_lavender' }), value: '#e6e6fa' },
    { label: intl.formatMessage({ id: 'color_mistyrose' }), value: '#ffe4e1' },
    { label: intl.formatMessage({ id: 'color_lightpink' }), value: '#ffb6c1' },
    { label: intl.formatMessage({ id: 'color_tomato' }), value: '#ff6347' },
]

export const FILTER_RULE_OP_TYPES = [{
    toProperties: ['Title', 'Text', 'Email', 'Phone'],
    ruleOps: [{
        value: 'contains',
        label: intl.formatMessage({ id: 'contains' }),
    }, {
        value: 'not_contains',
        label: intl.formatMessage({ id: 'not_contains' }),
    }, {
        value: 'eq',
        label: intl.formatMessage({ id: 'equals' }),
    }, {
        value: 'ne',
        label: intl.formatMessage({ id: 'not_equals' }),
    }, {
        value: 'starts_with',
        label: intl.formatMessage({ id: 'starts_with' }),
    }, {
        value: 'ends_with',
        label: intl.formatMessage({ id: 'ends_with' }),
    }]
}, {
    toProperties: ['Number'],
    ruleOps: [{
        label: '=',
        value: 'eq'
    }, {
        label: '≠',
        value: 'ne'
    }, {
        label: '>',
        value: 'gt'
    }, {
        label: '≥',
        value: 'ge'
    }, {
        label: '<',
        value: 'lt'
    }, {
        label: '≤',
        value: 'le'
    }]
}, {
    toProperties: ['Date'],
    ruleOps: [{
        label: intl.formatMessage({ id: 'equals' }),
        value: 'eq'
    }, {
        label: intl.formatMessage({ id: 'not_equals' }),
        value: 'ne'
    }, {
        label: intl.formatMessage({ id: 'date_greater_than' }),
        value: 'gt'
    }, {
        label: intl.formatMessage({ id: 'date_greater_than_or_equal' }),
        value: 'ge'
    }, {
        label: intl.formatMessage({ id: 'date_less_than' }),
        value: 'lt'
    }, {
        label: intl.formatMessage({ id: 'date_less_than_or_equal' }),
        value: 'le'
    }, {
        label: intl.formatMessage({ id: 'date_between' }),
        value: 'between'
    }]
}, {
    toProperties: ['Select'],
    ruleOps: [{
        label: intl.formatMessage({ id: 'equals' }),
        value: 'eq'
    }, {
        label: intl.formatMessage({ id: 'not_equals' }),
        value: 'ne'
    }]
}, {
    toProperties: ['MultiSelect', 'Person'],
    ruleOps: [{
        label: intl.formatMessage({ id: 'contains' }),
        value: 'contains'
    }, {
        label: intl.formatMessage({ id: 'not_contains' }),
        value: 'not_contains'
    }]
}, {
    toProperties: ['Checkbox'],
    ruleOps: [{
        label: intl.formatMessage({ id: 'equals' }),
        value: 'eq'
    }, {
        label: intl.formatMessage({ id: 'not_equals' }),
        value: 'ne'
    }]
}];

export const getDefaultRuleOp = (property) => {
    const ruleOps = FILTER_RULE_OP_TYPES.find(item => item.toProperties.includes(property));
    return ruleOps ? ruleOps.ruleOps[0].value : null;
}

export const DOC_PERMISSION = {
    no_access: -1,
    view: 3,
    comment: 6,
    edit: 9,
    full_access: 99
}

export const ARTICLE_STATUS = {
    gathering: 0,
    succeed: 1,
    failed: 2
}

export const TODO_STATUS = {
    todo: 0,
    doing: 1,
    done: 2,
}

export const TICKET_STATUS = {
    private: 0,
    open: 1,
    processing: 2,
    resolved: 3,
    closed: 4,
    pending: 5
}

export const TICKET_PRIORITY = {
    P1: 1,
    P2: 2,
    P3: 3,
    P4: 4,
    P5: 5
}

export const THREAD_TYPE = {
    user: 0,
    dialog: 1,
    group: 2,
    ticket: 3,
    subscription: 4
}

export const TODO_TYPE = {
    Todos: 0,
    Notes: 1
}

export const TODO_FOLDER = {
    normal: 0,
    trashbin: 1
};

export const DOCS_FOLDER = {
    normal: 0,
    trashbin: 1,
    deleted: -1
};

export const SLIDES_FOLDER = {
    normal: 0,
    trashbin: 1,
    deleted: -1
};

export const MESSAGE_CONTENT_TYPE = {
    image: 1,
    weblink: 2,
    card: 3
}

export const TICKET_WRAPPER_TYPE = {
    todo: 0,
    okr_object: 1,
    okr_keyresult: 2
}

export const OKR_LIST = {
    my: 1,
    group: 2,
    org: 3,
    people: 4,
    profile: 5,
    aligns: 6,

    my_key_result: 10,
    group_key_result: 11,
    org_key_result: 12,
    people_key_result: 13
}

export const OKR_TYPE = {
    objective: 0,
    keyResult: 1
};

export const OKR_SESSION_TYPE = {
    Quarterly: 0,
    Yearly: 1
}

export const OKR_STATUS = {
    none: 0,
    onTrack: 1,
    atRisk: 2,
    offTrack: 3,
};

export const OKR_STATUS_COLOR = {
    none: 'lightgrey',
    onTrack: 'lightgreen',
    atRisk: 'gold',
    offTrack: 'red',
};

export const OKR_LEVEL = {
    individual: 0,
    team: 1,
    company: 2
}

export const TIME_SELECTOR = {
    during: 1,
    before: 2,
    after: 3
}

export const getConstantKey = (constantObj, value) => {

    return Object.keys(constantObj)[Object.values(constantObj).indexOf(value)];
}

const getPickerItemsFromKVObject = (kv_obj) => {
    let items = [];
    Object.keys(kv_obj).forEach(key => {
        if (kv_obj[key] !== kv_obj.private) {
            items.push({ label: key, value: String(kv_obj[key]) })
        }
    });

    return items;
}


// let uniqueId = getUniqueId();
// export let deviceId = isWeb ? 'web' : uniqueId;
// getBaseOs().then(baseOs => deviceId = !isWeb ? uniqueId: baseOs);


export const NavigatorHeaderStyle = {
    height: 56,
    backgroundColor: 'white',
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
}
const MasterWidthRatio = 0.4;
const MaxMasterWidth = 560;

export const isDEV = process.env.NODE_ENV === 'development';

export const FEEDBACK_THREAD_ID = isDEV ? '5ef2e67cb29f364747ea5411' : '';

export const Resources = {
    extractorJS: 'javascripts/extractor.client.js',
    noteJS: 'javascripts/note.js',
    imgJS: 'javascripts/img.js',
    jqueryJS: 'javascripts/jquery.min.js',
    underscoreJS: 'javascripts/underscore.js',
    articleStyle: 'stylesheets/article.css',
    highlightStyle: 'stylesheets/highlight.css',
    typoStyle: 'stylesheets/typo.css',
    bodyStyle: 'stylesheets/mobile_body.css'
}

export const isMac = window.navigator.userAgent.toUpperCase().indexOf('MAC') != -1

export const slides_themes = [{
    name: 'black',
    stylesheet: 'dist/theme/black.css',
    desc: 'Black background, white text, blue links'
  }, {
    name: 'white',
    stylesheet: 'dist/theme/white.css',
    desc: 'White background, black text, blue links'
  }, {
    name: 'league',
    stylesheet: 'dist/theme/league.css',
    desc: 'Gray background, white text, blue links'
  }, {
    name: 'beige',
    stylesheet: 'dist/theme/beige.css',
    desc: 'Beige background, dark text, brown links'
  }, {
    name: 'sky',
    stylesheet: 'dist/theme/sky.css',
    desc: 'Blue background, thin dark text, blue links'
  }, {
    name: 'night',
    stylesheet: 'dist/theme/night.css',
    desc: 'Black background, thick white text, orange links'
  }, {
    name: 'serif',
    stylesheet: 'dist/theme/serif.css',
    desc: 'Cappuccino background, gray text, brown links'
  }, {
    name: 'simple',
    stylesheet: 'dist/theme/simple.css',
    desc: 'White background, black text, blue links'
  }, {
    name: 'solarized',
    stylesheet: 'dist/theme/solarized.css',
    desc: 'Cream-colored background, dark green text, blue links'
  }, {
    name: 'blood',
    stylesheet: 'dist/theme/blood.css',
    desc: 'Dark background, thick white text, red links'
  }, {
    name: 'moon',
    stylesheet: 'dist/theme/moon.css',
    desc: 'Dark blue background, thick grey text, blue links'
  }, {
    name: 'dracula',
    stylesheet: 'dist/theme/dracula.css'
  }];