import { useCallback, useState } from 'react';
import {
    ReactFlow,
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    Background,
    ReactFlowProvider,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';

const rfStyle = {
    backgroundColor: '#D0C0F7',
};

const initialNodes = [
    {
        id: 'A',
        type: 'group',
        position: { x: 0, y: 0 },
        style: {
            width: 170,
            height: 140,
        },
    },
    {
        id: 'A-1',
        type: 'input',
        data: { label: 'Child Node 1' },
        position: { x: 10, y: 10 },
        parentId: 'A',
        extent: 'parent',
    },
    {
        id: 'A-2',
        data: { label: 'Child Node 2' },
        position: { x: 10, y: 290 },
        parentId: 'A',
        extent: 'parent',
    },
    {
        id: 'B',
        type: 'output',
        position: { x: -100, y: 200 },
        data: null,
        style: {
            width: 170,
            height: 140,
            backgroundColor: 'rgba(240,240,240,0.25)',
        },
    },
    {
        id: 'B-1',
        data: { label: 'Child 1' },
        position: { x: 50, y: 10 },
        parentId: 'B',
        extent: 'parent',
        draggable: false,
        style: {
            width: 60,
        },
    },
    {
        id: 'B-2',
        data: { label: 'Child 2' },
        position: { x: 10, y: 90 },
        parentId: 'B',
        extent: 'parent',
        draggable: false,
        style: {
            width: 60,
        },
    },
    {
        id: 'B-3',
        data: { label: 'Child 3' },
        position: { x: 100, y: 90 },
        parentId: 'B',
        extent: 'parent',
        draggable: false,
        style: {
            width: 60,
        },
    },
    {
        id: 'C',
        type: 'output',
        position: { x: 100, y: 200 },
        data: { label: 'Node C' },
    },
    {"id":"group_1722148374297","type":"ai_node","position":{"x":-74.54545454545456,"y":85.68181818181819},"style":{"width":856,"height":359},"data":{"nodeType":"group","selectable_context_option":false},"measured":{"width":856,"height":405},"selected":true,"dragging":false}
,
    {
        "id":"1722137090398",
        "position":{"x":0,"y":0},
        "data":{"label":"New","nodeType":"prompt","queryType":"ask","userInput":"abc","selectable_context_option":false,"backgroundTasks":{"trigger":0.9193943436073386,"query_optimize":"triggered"},"aigc_triggered":true,"aigc_done":false,"showActionBox":false,"query_optimize":{"items":[{"description":"Research Twitter promotion strategies for AI extensions","priority":"high","dueDate":null},{"description":"Create promotional content for FunBlocks AI Extension on Twitter","priority":"high","dueDate":null},{"description":"Schedule tweets to promote FunBlocks AI Extension","priority":"medium","dueDate":null},{"description":"Monitor and analyze the performance of the Twitter promotion campaign","priority":"medium","dueDate":null}]},"optimized_query":"abc"},
        "type":"ai_node",
        "measured":{"width":120,"height":35},
        "selected":false,
        "dragging":false,
        "parentId": "group_1722148374297",
        "extent":"parent"
    },
];

const initialEdges = [
    { id: 'a1-a2', source: 'A-1', target: 'A-2' },
    { id: 'a2-b', source: 'A-2', target: 'B' },
    { id: 'a2-c', source: 'A-2', target: 'C' },
    { id: 'b1-b2', source: 'B-1', target: 'B-2' },
    { id: 'b1-b3', source: 'B-1', target: 'B-3' },
];

function Flow() {
    const [nodes, setNodes] = useState(initialNodes);
    const [edges, setEdges] = useState(initialEdges);

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes],
    );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges],
    );
    const onConnect = useCallback(
        (connection) => setEdges((eds) => addEdge(connection, eds)),
        [setEdges],
    );


    return (
        <div className='fill-available simple-floatingedges' style={{ height: '100vh', position: 'relative' }}>

            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                fitView
                style={rfStyle}
            // attributionPosition="top-right"
            >
                <Background />
            </ReactFlow>
        </div>
    );
}


export default () => (
    <ReactFlowProvider>
        <Flow />
    </ReactFlowProvider>
);
