import React, { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { deletePrompt, fetchPrompts, fetchPromptsPublic, pinPrompt } from "src/actions/ticketAction";
import { getStateByUser } from "src/reducers/listReducer";
import PromptCard from "./PromptCard";

const PromptsPublic = ({ container }) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const loginUser = useSelector(state => state.loginIn && state.loginIn.user);
    const prompt_lists = useSelector(state => getStateByUser(state.public_prompt_lists, loginUser));
    const lng_list = useSelector(state => state.uiState.lng_list);
    const lng = useSelector(state => state.uiState.lng) == 'cn' ? 'zh' : 'en';
    const pageBy = 'createdAt';
    const [fetch_params, set_fetch_params] = useState({ pageSize: 30, pageBy, locale: lng });

    useEffect(() => {
        dispatch(fetchPromptsPublic(fetch_params));
    }, [fetch_params]);

    const handleLoadMore = (list_data) => {
        if (list_data.isFetching || list_data.isEnd || !list_data.items || list_data.items.length === 0) {
            return;
        }

        let pageFrom = list_data.items[list_data.items.length - 1][pageBy];

        set_fetch_params(prevState => ({
            ...prevState,
            pageFrom
        }));
    };

    return (
        <div className="prompts-container">
            <div className="language-select-container">
                <label htmlFor="locale-select" className="language-label">
                    {intl.formatMessage({ id: 'prompt_language' })}
                </label>
                <select
                    name="locales"
                    id="locale-select"
                    className="language-select"
                    onChange={(e) => {
                        set_fetch_params(prev => {
                            let newState = {
                                ...prev,
                                locale: e.target.value
                            }

                            if(newState.locale === 'all') {
                                delete newState.locale;
                            }

                            return newState;
                        })
                    }}
                    value={fetch_params.locale || 'all'}
                >
                    <option key='all' value={'all'}>{intl.formatMessage({ id: 'all' })}</option>
                    {lng_list?.map((lng, index) => (
                        <option key={index + ''} value={lng.Symbol}>
                            {lng.Language}
                        </option>
                    ))}
                </select>
            </div>

            <div className="prompts-grid">
                {prompt_lists?.items?.filter(item => container !== 'drafter_panel' || item.args?.length > 0)
                    .map((item, index) => (
                        <PromptCard key={index} item={item} board={'market'} container={container} />
                    ))
                }
            </div>

            {prompt_lists && !prompt_lists.isEnd && (
                <div className="load-more-button" onClick={() => handleLoadMore(prompt_lists)}>
                    {intl.formatMessage({ id: 'loadmore' })}
                </div>
            )}
        </div>
    );
}

export default PromptsPublic;