import { Button, Divider, MenuItem, Select, Switch, TextField } from "@mui/material";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CONFIRM_DIALOG, PROMPT_DIALOG } from "src/constants/actionTypes";
import { PromptModal } from "./PromptModal";
import { useEffect, useState } from "react";
import { fetchPromptsValidate, pinPrompt } from "src/actions/ticketAction";
import { getStateByUser } from "src/reducers/listReducer";
import PromptCard from "./PromptCard";

const PromptsValidate = ({ container }) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const loginUser = useSelector(state => state.loginIn && state.loginIn.user);
    const prompt_lists = useSelector(state => getStateByUser(state.validate_prompt_lists, loginUser));
    const pageBy = 'createdAt';
    const [fetch_params, set_fetch_params] = useState({ pageSize: 30, pageBy});

    useEffect(() => {
        dispatch(fetchPromptsValidate(fetch_params));
    }, [fetch_params]);

    const handleLoadMore = (list_data) => {
        if (list_data.isFetching || list_data.isEnd || !list_data.items || list_data.items.length === 0) {
            return;
        }

        let pageFrom = list_data.items[list_data.items.length - 1][pageBy];

        set_fetch_params(prevState => {
            return {
                ...prevState,
                pageFrom
            }
        })
    };

    return (
        <div style={{ paddingTop: '20px', paddingBottom: '20px', paddingLeft: '10px', display: 'flex', flexDirection: 'column', rowGap: '20px' }} >
            <div className="prompts-container">
                {
                    prompt_lists?.items?.filter(item => {
                        return container !== 'drafter_panel' || item.args?.length > 0
                    }).map((item, index) => {
                        return <PromptCard key={index} item={item} board={'validate'} container={container} />
                    })
                }
            </div>

            {
                prompt_lists && !prompt_lists.isEnd &&
                <div
                    className="hoverStand"
                    style={{
                        color: 'dodgerblue',
                        fontSize: '18px',
                        // backgroundColor: 'LightSkyBlue',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                    }}
                    onClick={() => handleLoadMore(prompt_lists)}>
                    {intl.formatMessage({ id: 'loadmore' })}
                </div>
            }
        </div>
    );
}

export default PromptsValidate;