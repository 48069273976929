import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { TabPanel, a11yProps } from '../tab/TabPanel';
import ReferredUsersTable from './ReferredUsersTable'; // Assume this component exists
import PaymentRecordsTable from './PaymentRecordsTable'; // Assume this component exists

const Affiliate = () => {
    const intl = useIntl();
    const [tab, setTab] = React.useState(0);

    const loginUser = useSelector(state => state.loginIn.user);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    if (!loginUser?.isAffiliate) {
        return (
            <Box sx={{ padding: 2, paddingTop: 5 }}>
                <Typography variant="h6" gutterBottom>
                    {intl.formatMessage({ id: 'affiliate_program_title' })}
                </Typography>
                <Typography variant="body1">
                    {intl.formatMessage({ id: 'affiliate_program_description' })}
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleChange} aria-label="affiliate tabs">
                    <Tab label={intl.formatMessage({ id: 'referred_users' })} {...a11yProps(0)} />
                    <Tab label={intl.formatMessage({ id: 'payment_records' })} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
                <ReferredUsersTable />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <PaymentRecordsTable />
            </TabPanel>
        </Box>
    );
}

export default Affiliate; 