import { useEffect, useState, useCallback, useMemo } from 'react';
import { Menu, MenuItem, Popover, Tooltip } from '@mui/material';


import { KeyboardArrowDown } from '@styled-icons/material';

export const Selector = ({ options, value, onChange, triggerElement, menuHeader, inputStyle, popoverStyle, labelStyle, itemStyle, dropdownIconSize, extraMenuItems, disabled, tooltip, placeholder, transformOrigin, anchorOrigin }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [item_hovered, set_item_hovered] = useState();

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleItemClick = (value) => {
        setAnchorEl(null);
        onChange(value);
    }

    const selectedOption = value && options && options.find(p => p.value === value);

    const trigger = useMemo(() => <div
        className='round-corner-input'
        style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ...inputStyle
        }}
        onClick={(e) => {
            if (disabled) {
                return;
            }
            setAnchorEl(e.currentTarget);
        }}
    >
        {
            !!triggerElement && triggerElement
        }
        {
            !triggerElement &&
            <>
                <div
                    style={{
                        // display: 'flex',
                        // flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        columnGap: '6px',
                        fontSize: !selectedOption ? '13px' : '14px',
                        color: (disabled ? '#555' : '#333'),
                        userSelect: 'none',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        ...labelStyle
                    }}
                >
                    {!selectedOption && placeholder} {selectedOption?.icon} {selectedOption?.label}
                </div>

                <div style={{
                    width: dropdownIconSize || 17,
                    height: dropdownIconSize || 17,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    <KeyboardArrowDown size={dropdownIconSize || 17} style={{ paddingLeft: 2, marginTop: 1, color: 'gray' }} />
                </div>
            </>
        }
    </div>, [selectedOption, setAnchorEl, placeholder, triggerElement, inputStyle])

    return (<>
        {
            !tooltip ? trigger : <Tooltip title={tooltip} placement="bottom">
                {trigger}
            </Tooltip>
        }
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}

            anchorOrigin={anchorOrigin || {
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={transformOrigin || {
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <div
                style={{
                    maxHeight: 500,
                    ...popoverStyle
                }}
            >
                {
                    menuHeader
                }
                {
                    options && options.map((option, index) => {
                        return (<Tooltip key={index + ''} title={option.tips} placement='right' arrow>
                            <div>
                                {
                                    option.type === 'title' &&
                                    <>
                                        {
                                            (!!index || menuHeader) &&
                                            <div
                                                style={{
                                                    height: 1,
                                                    backgroundColor: '#ddd',
                                                    marginLeft: 6,
                                                    marginRight: 6
                                                }}
                                            />
                                        }
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                color: 'gray',
                                                fontSize: 12,
                                                height: 28,
                                                paddingLeft: 6
                                            }}
                                        >
                                            {option.label}
                                        </div>
                                    </>
                                }
                                {
                                    option.type !== 'title' &&
                                    <div
                                        className='hoverStand'
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '-webkit-fill-available',
                                            height: 30,
                                            paddingTop: '2px',
                                            paddingBottom: '2px',
                                            borderRadius: 0,
                                            backgroundColor: option.value == value ? 'rgba(25,118,210, 0.2)' : undefined,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontSize: '14px',
                                            color: '#333',
                                            ...itemStyle
                                        }}
                                        value={option.value}
                                        onClick={() => {
                                            if (option.onClick) {
                                                option.onClick();
                                                return handleClose();
                                            }

                                            handleItemClick(option.value);
                                        }}
                                        onMouseEnter={() => {
                                            set_item_hovered(index);
                                        }}
                                        onMouseLeave={() => {
                                            set_item_hovered(null);
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                columnGap: option.label ? '6px' : '0px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {option.icon}
                                            <div style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>{option.label}</div>
                                        </div>
                                        {
                                            item_hovered === index &&
                                            <div
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setAnchorEl(null);
                                                }}
                                            >{option.rightElement}
                                            </div>
                                        }
                                    </div>
                                }

                            </div>
                        </Tooltip>
                        );
                    })
                }
                {
                    extraMenuItems
                }
            </div>
        </Popover>
    </>
    )
}


const styles = {
    menuItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    menuContent: {
        display: 'flex',
        width: '130px',
        margin: '0px',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent: 'center',
        alignItems: 'center'
    },

    icon: {
        marginRight: 4
    }
}