import React, { Component, useEffect, useMemo } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import '@/styles/globals.css';
import './scss/style.scss';
import 'tippy.js/dist/tippy.css'
import './scss/editor.css'
import './App.css'
import messages from './messages';
import { IntlProvider } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { GOOGLE_ANALYTICS_TRACKID, isCNDomain } from './utils/constants';
import FlowTest from './components/flow/FlowTest';
import ReactGA from "react-ga4";
import AIFlow from './components/flow/AIFlow';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Activate = React.lazy(() => import('./views/pages/login/Activate'))
const CreateWorkspace = React.lazy(() => import('./views/pages/CreateWorkspace'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Embed = React.lazy(() => import('./components/EmbeddedContent'))
const InvitationLanding = React.lazy(() => import('./views/pages/InvitationLanding'))
const Invitation = React.lazy(() => import('./views/pages/Invitation'))
const ExtensionUninstalled = React.lazy(() => import('./views/pages/ExtensionUninstalled'))
const ExtensionAIPlans = React.lazy(() => import('./views/pages/ExtensionAIPlans'))
const RedirectTo = React.lazy(() => import('./views/pages/Redirect'))

const App = (props) => {
  const dispatch = useDispatch();

  const loggedIn = useSelector(state => {
    return state.loginIn.user._id
  });

  let lng = useSelector(state => state.uiState.lng);
  if (!lng) {
    lng = navigator.language || navigator.userLanguage;

    if (lng && lng.indexOf('zh-') > -1) {
      lng = 'cn';
    }
  }

  if (lng != 'cn') {
    lng = 'en';
  }

  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_TRACKID);
    // ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
}, [])

  return (
    <IntlProvider locale={lng} messages={messages[lng]}>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              path="/register"
              name="Register Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/resetpswd"
              name="Reset Password"
              render={(props) => <Login {...props} />}
            />
            <Route path="/activate" name="Acitvate account" render={(props) => <Activate {...props} />} />
            <Route path="/newworkspace" name="New Workspace" render={(props) => <CreateWorkspace {...props} />} />
            <Route path="/home" name="Home" render={(props) => <DefaultLayout {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/embed" name="Embed" render={(props) => <Embed {...props} />} />
            <Route path="/uninstalled" name="Uninstalled" render={(props) => <ExtensionUninstalled {...props} />} />
            <Route path="/invitation-landing" name="InvitationLanding" render={(props) => <InvitationLanding {...props} />} />
            <Route path="/invitation-event" name="InvitationEvent" render={(props) => <Invitation {...props} />} />
            <Route path="/aiplans" name="aiplans" render={(props) => <ExtensionAIPlans {...props} />} />
            <Route path="/aiflow" name="aiflow" render={(props) => <AIFlow {...props} />} />
            <Route path="/flowtest" name="aiflow" render={(props) => <FlowTest {...props} />} />
            <Route path="/redirect" name="aiplans" render={(props) => <RedirectTo {...props} />} />
            <Route path="/" name="Login Page" render={loggedIn ? (props) => <DefaultLayout {...props} /> : (props) => <Login {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </IntlProvider>
  )
}

export default App
