import React, { useState } from 'react';
import { Panel, useReactFlow, getNodesBounds, getViewportForBounds, useViewport } from '@xyflow/react';
import { toPng } from 'html-to-image';
import { Download } from '@styled-icons/bootstrap/Download';
import { useIntl } from 'react-intl';
import { Tooltip, CircularProgress } from '@mui/material';
import QRCode from 'qrcode';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from "react-ga4";
import { EXPORT_DOC_DIALOG } from '@/constants/actionTypes';

function downloadImage(dataUrl, title) {
    const a = document.createElement('a');
    a.setAttribute('download', title + '.png');
    a.setAttribute('href', dataUrl);
    a.click();
}

function getImageInfo(node, nodes, isGroup = false) {
    if (node && !isGroup) {
        return {
            imageWidth: (node.measured?.width || node.computed?.width),
            imageHeight: (node.measured?.height || node.computed?.height),
            target: document.querySelector(`.react-flow__node[data-id="${node.id}"]`),
            transform: [0, 0, 1]
        };
    } else {
        const nodesBounds = isGroup ?
            {
                ...node.position,
                ...(node.measured || node.computed)
            }
            : getNodesBounds(nodes, { nodeOrigin: [0, 0] });
        
        // 为边界添加一些padding以确保完整捕获内容
        const padding = 20;
        nodesBounds.width += padding * 2;
        nodesBounds.height += padding * 2;
        nodesBounds.x -= padding;
        nodesBounds.y -= padding;

        const { x, y, zoom } = getViewportForBounds(nodesBounds, nodesBounds.width, nodesBounds.height, 0.2, 2, 0);
        const transform = [x, y, zoom];
        
        return {
            imageWidth: nodesBounds.width,
            imageHeight: nodesBounds.height,
            target: document.querySelector('.react-flow__viewport'),
            transform,
        };
    }
}

async function toPngAndDownload(target, options, imageType, withoutQrcode) {
    const scale = window.devicePixelRatio || 1;
    
    // 配置html-to-image的选项以获得高清图片
    const enhancedOptions = {
        ...options,
        pixelRatio: scale,
        fontEmbedCSS: '',
        skipFonts: false,
        backgroundColor: '#FFFFFF',
        width: options.width,
        height: options.height,
        style: {
            ...options.style,
            fontSmooth: 'antialiased',
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale'
        }
    };

    const dataUrl = await toPng(target, enhancedOptions);
    const image = new Image();
    image.src = dataUrl;
    await new Promise((resolve) => {
        image.onload = resolve;
    });

    const imageWidth = image.width;
    const imageHeight = image.height;
    // 不对信息区域高度应用缩放
    const infoRectHeight = withoutQrcode ? 52 : (imageType === 'board' ? 220 : 150);

    // Canvas的宽度要考虑缩放后的主图像尺寸
    const canvasWidth = imageWidth;
    const canvasHeight = imageHeight + infoRectHeight;

    const canvas = document.createElement('canvas');
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    const ctx = canvas.getContext('2d');

    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    // 设置背景
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    
    // 绘制主图像
    ctx.drawImage(image, 0, 0);

    if (withoutQrcode) {
        ctx.font = '22px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif';
        ctx.fillStyle = 'gray';
        ctx.fillText(options.info.product, 12, canvas.height - 20);
    } else {
        // QR码处理
        const qrCodeUrl = options.info.shareUrl;
        const qrCode = await QRCode.toDataURL(qrCodeUrl, {
            errorCorrectionLevel: 'H',
            margin: 1,
            scale: 10
        });

        const qrCodeSize = infoRectHeight * 0.9;
        const padding = (infoRectHeight - qrCodeSize) / 2 + 11;
        const textX = canvas.width - padding - qrCodeSize - 14;

        // 绘制QR码
        const qrCodeImage = new Image();
        qrCodeImage.src = qrCode;
        await new Promise((resolve) => {
            qrCodeImage.onload = resolve;
        });

        const qrCodeX = canvas.width - qrCodeSize - padding;
        const qrCodeY = canvas.height - qrCodeSize - padding;

        // 绘制QR码阴影
        ctx.shadowColor = 'rgba(0, 0, 0, 0.1)';
        ctx.shadowBlur = 5;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.drawImage(qrCodeImage, qrCodeX, qrCodeY, qrCodeSize, qrCodeSize);

        // 重置阴影
        ctx.shadowColor = 'transparent';
        ctx.shadowBlur = 0;

        // 设置文字渲染
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'right';

        // 绘制slogan
        const fontSize = imageType === 'board' ? 26 : 20;
        ctx.font = `${fontSize}px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif`;
        ctx.fillStyle = '#555555';
        ctx.fillText(options.info.slogan, textX, qrCodeY + (imageType === 'board' ? 78 : 38));

        // 绘制产品名称（使用渐变）
        const gradientWidth = 200;
        const gradient = ctx.createLinearGradient(
            textX - gradientWidth,
            0,
            textX,
            0
        );
        gradient.addColorStop(0, '#4361ee');
        gradient.addColorStop(1, '#7209b7');
        ctx.fillStyle = gradient;
        
        const productFontSize = imageType === 'board' ? 34 : 26;
        ctx.font = `bold ${productFontSize}px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif`;
        ctx.fillText(options.info.product, textX, qrCodeY + qrCodeSize / 2 + (imageType === 'board' ? 18 : 6));

        // 绘制二维码提示文字
        const tipsFontSize = imageType === 'board' ? 26 : 20;
        ctx.font = `${tipsFontSize}px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif`;
        ctx.fillStyle = '#444444';
        ctx.fillText(options.info.qrCodeTips, textX, qrCodeY + qrCodeSize - (imageType === 'board' ? 30 : 20));
    }

    const newDataUrl = canvas.toDataURL('image/png');
    downloadImage(newDataUrl, options.title);
}

function DownloadButton({ title, node, onClick, shareUrl, imageType = 'board', isGroup = false, iconStyle }) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { getNodes } = useReactFlow();
    const [isDownloading, setIsDownloading] = useState(false);
    const flow_export_image_without_qrcode = useSelector(state => state.uiState.flow_export_image_without_qrcode);

    const onBtnClick = async () => {
        !!onClick && onClick();

        if (node?.data?.nodeType === 'slides') {
            dispatch({ type: EXPORT_DOC_DIALOG, value: { visible: true, doc: { title: node.data.title, type: node.data.nodeType, hid: node.data.hid } } });
            return;
        }

        setIsDownloading(true);

        const { imageWidth, imageHeight, target, transform } = getImageInfo(node, getNodes(), isGroup);
        const options = {
            title,
            backgroundColor: 'white',
            width: imageWidth + 20,
            height: imageHeight + 20,
            info: {
                product: intl.formatMessage({ id: 'funblocks_aiflow' }),
                slogan: intl.formatMessage({ id: 'aiflow_slogan' }),
                qrCodeTips: intl.formatMessage({ id: 'qr_code_tips' }),
                shareUrl
            },
            quality: 1,
            style: {
                width: imageWidth,
                height: imageHeight,
                margin: '10px',
                transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
            },
            filter: (node) => {
                const exclusionClasses = ['circle-button'];
                return !exclusionClasses.some((classname) => node.classList?.contains(classname));
            },
        };
        await toPngAndDownload(target, options, imageType, flow_export_image_without_qrcode);
        setIsDownloading(false);

        ReactGA.event({ category: 'BoardButtons', action: 'download', label: isGroup ? 'group' : (!!node ? 'node' : 'board') });
    };

    return (
        <Tooltip title={intl.formatMessage({ id: node?.data?.nodeType === 'slides' ? 'export_to_pdf' : 'export_to_image' })} placement="top">
            <div className="hoverStand" style={{ padding: 6, ...iconStyle }} onClick={isDownloading ? undefined : onBtnClick}>
                {isDownloading ? <CircularProgress size={18} /> : <Download color='#555' size={18} />}
            </div>
        </Tooltip>
    );
}

export default DownloadButton;