import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ReactFlow, MiniMap, Controls, Background, Panel, useReactFlow, ReactFlowProvider, reconnectEdge, getIncomers, useViewport, BackgroundVariant, ControlButton } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import './context-menu.css';
import './simple-floatingedges.css';
import './node.css';

import AINode from './AINode';
import SimpleFloatingEdge from './SimpleFloatingEdge';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import { fetchPrompts, fetchPromptsPinned, fetchPromptsWorkspace, getDoc, getLngList, logout_of_server, upsertDoc } from '@/actions/ticketAction';
import ContextMenu from './ContextMenu';
import useStoreWithUndo, { useStore } from './store';
import { useShallow } from 'zustand/react/shallow';
import { getStateByUser } from '@/reducers/listReducer';
import { getNodeContent, onContextMenu } from './utils';

import { Message } from '@styled-icons/boxicons-regular/Message';
import { List } from '@styled-icons/fluentui-system-filled/List';
import { Magic } from '@styled-icons/remix-line/Magic'
import DownloadButton from './Download';
import { ArrowUndo } from '@styled-icons/fluentui-system-filled/ArrowUndo';
import { ArrowRedo } from '@styled-icons/fluentui-system-filled/ArrowRedo';
import { StickyNote } from '@styled-icons/remix-line/StickyNote';
import { FileCopy } from '@styled-icons/remix-line/FileCopy';
import { LoginBox } from '@styled-icons/remix-line/LoginBox';
import { LogoutBoxR } from '@styled-icons/remix-line/LogoutBoxR'
import { Install } from '@styled-icons/remix-line/Install'
import { Image } from '@styled-icons/remix-line/Image'
import { Link } from '@styled-icons/entypo/Link'
import { BookOpen } from '@styled-icons/remix-line/BookOpen'
import { LightUp } from '@styled-icons/entypo/LightUp'
import { Search } from '@styled-icons/remix-line/Search'
import { Settings } from '@styled-icons/remix-line/Settings'
import { Tooltip, CircularProgress } from '@mui/material';
import { Gift } from '@styled-icons/bootstrap/Gift'
import { useIntl } from 'react-intl';
import { node_color_themes } from './ColorMenu';
import { Selector } from '../common/Selector';
import { ShareButton } from './Share';
import { DOC_PERMISSION } from '@/constants/constants';
import { FLOW_INPUT_MODAL, FLOW_MODAL, IMAGE_UPLOAD_DIALOG, SETTINGS_DIALOG, UNSAVED_FLOW } from '@/constants/actionTypes';
import InfoModal from './Modal';
import { useMediaQuery } from 'react-responsive';
import { getInviteCode } from '@/actions/ticketAction';
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import { ListTask } from '@styled-icons/bootstrap';
import InputModal from './InputModal';
import TurndownService from 'turndown';
import { Save3 } from '@styled-icons/remix-line/Save3'
import { uploadImgs } from '../../actions/ticketAction';
import { ADD_FLOW_NODE, AI_API_MODEL, INVITE_FRIENDS_DIALOG, LLM_API_KEYS, LLM_API_KEY_MODAL, OPERATION_FAILED, PAGE_CHOOSER_DIALOG } from '../../constants/actionTypes';
import { EditableMenuItemActions } from './EditableMenuItemActions';
import ModelSelector from './ModelSelector';
import ReactGA from "react-ga4";
import { MOBILE_MEDIA_QUERY } from '../../utils/constants';
import { isMac } from '../../constants/constants';
import HintPanel from './HintPanel';
import urlRegex from 'url-regex';
import TempNoteBook from './TempNoteBook';
import { useLayoutedElements as useLayoutedElementsELK } from './LayoutELK';
import { useLayoutedElements as useLayoutedElementsD3 } from './LayoutD3';
import { LayoutMasonry } from '@styled-icons/remix-fill/LayoutMasonry'
import { AlignHorizontalLeft } from '@styled-icons/material/AlignHorizontalLeft'
import { AlignVerticalTop } from '@styled-icons/material-outlined/AlignVerticalTop'
import { ScatterPlot } from '@styled-icons/material-outlined/ScatterPlot'
import { Scales3 } from '@styled-icons/remix-line/Scales3'
import PageChooserModal from '../PageChooserModal';
import { FileText } from '@styled-icons/bootstrap/FileText'
import { Movie } from '@styled-icons/boxicons-regular/Movie'
import PreventSwipeNavigation from '../common/PreventSwipeNavigation';
import { get_server_host } from '../../utils/serverAPIUtil';
import { getProxiedImageUrl } from '../../utils/url';
const nodeTypes = { ai_node: AINode };
const edgeTypes = { float_edge: SimpleFloatingEdge };

const selector = (state) => ({
  nodes: state.nodes,
  edges: state.edges,
  newNode: state.newNode,
  getNode: state.getNode,
  getNodeEdges: state.getNodeEdges,
  addNode: state.addNode,
  addSubNode: state.addSubNode,
  deleteNode: state.deleteNode,
  setNodes: state.setNodes,
  setEdges: state.setEdges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  onEdgeUpdate: state.onEdgeUpdate,
  updateNodeData: state.updateNodeData,
  updateNode: state.updateNode
});

let lng = navigator.language || navigator.userLanguage

if (lng && lng.indexOf('zh-') > -1) {
  lng = 'cn'
}

if (lng != 'cn') {
  lng = 'en'
}

const FlowEditor = ({ standAlone, initNodeData }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const docs = useSelector(state => state.docs);
  const page_history = useSelector(state => state.page_history);

  const loginUser = useSelector(state => state.loginIn && state.loginIn.user);
  const orgs = useSelector(state => getStateByUser(state.org_lists, loginUser));
  const workingSpace = orgs.items.find(org => org._id === loginUser.workingOrgId);

  const uiLng = useSelector(state => state.uiState.lng);
  const lng_list_origin = useSelector(state => state.uiState.lng_list);
  const [lng_list, set_lng_list] = useState();

  const inputModalState = useSelector(state => state.uiState.flow_input_modal) || {};
  const actionModalState = useSelector(state => state.uiState.flow_modal);
  const flow_settings = useSelector(state => state.uiState.flow_settings);
  const add_flow_node = useSelector(state => state.uiState.flow_node);

  const history = useHistory();
  const location = useLocation();
  const params = new Proxy(new URLSearchParams(location.search), {
    get: (searchParams, prop) => searchParams.get(prop) || '',
  });

  // const { hid } = params;
  const [hid, setHid] = useState();

  const doc = docs?.byId[hid];
  const unsaved_flow = useSelector(state => state.uiState.unsaved_flow);

  const [inviteCode, setInviteCode] = useState();
  const [fingerprint, set_fingerprint] = useState()

  const [title, setTitle] = useState('');
  const [buttonHovered, setButtonHovered] = useState();

  const operationStatus = useSelector(state => state.operationStatus);
  const [loading, setLoading] = useState();
  const [action_to_history, set_action_to_history] = useState();
  const [copiedNode, setCopiedNode] = useState(null);
  const { getIntersectingNodes } = useReactFlow();
  const [rfInstance, setRfInstance] = useState(null);
  const { setViewport } = useReactFlow();

  const { getLayoutedElements } = useLayoutedElementsELK();
  const { getLayoutedElementsD3 } = useLayoutedElementsD3();
  // const [ready, { runLayout }] = useLayoutedElementsD3();


  const ref = useRef(null);

  useEffect(() => {
    if (!operationStatus?.inProgress) {
      setLoading(false);
    }
  }, [operationStatus])

  useEffect(() => {
    // ReactGA.initialize(GOOGLE_ANALYTICS_TRACKID);
    ReactGA.send({ hitType: "pageview", page: "/flow_editor", title: "FlowEditor" });
  }, [])

  useEffect(() => {
    setHid(location.state && location.state.hid || params.hid);
  }, [location, params?.hid]);

  const { x, y, zoom } = useViewport();
  const [selectedNodes, setSelectedNodes] = useState([]);

  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY)

  const { nodes, edges, newNode, getNode, getNodeEdges, addNode, addSubNode, deleteNode, setNodes, setEdges, updateNodeData, updateNode, onNodesChange, onEdgesChange, onConnect } = useStoreWithUndo(
    useShallow(selector),
  );
  const { setContextMenu, context_menu, lang, setLang, isReadOnly, setReadOnly, setShareUrl, shareUrl, savingTrigger, setSavingTrigger, tempLlmModel, setTempLlmModel, addTempNote, tempNotes, setTempNotes } = useStore(useShallow(state => ({
    context_menu: state.context_menu,
    setContextMenu: state.setContextMenu,
    lang: state.lang,
    setLang: state.setLang,
    isReadOnly: state.readOnly,
    setReadOnly: state.setReadOnly,
    setShareUrl: state.setShareUrl,
    shareUrl: state.shareUrl,
    setSavingTrigger: state.setSavingTrigger,
    savingTrigger: state.savingTrigger,
    tempLlmModel: state.tempLlmModel,
    setTempLlmModel: state.setTempLlmModel,
    addTempNote: state.addTempNote,
    tempNotes: state.tempNotes,
    setTempNotes: state.setTempNotes
  })))

  const { undo, redo, clear, futureStates, pastStates } = useStoreWithUndo.temporal.getState();

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    setBoardChanged(true);
    setEdges(reconnectEdge(oldEdge, newConnection, edges));
  }, [edges]);

  const getSelectionBounds = useCallback(() => {
    const selection = document.querySelector('.react-flow__selection');
    if (!selection) return null;

    const rect = selection.getBoundingClientRect();
    return {
      left: rect.left,
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
    };
  }, [])

  const isNodeInsideSelection = useCallback((node, selectionBounds) => {
    if (!selectionBounds) return false;

    const nodeElement = document.querySelector(`[data-id="${node.id}"]`);
    if (!nodeElement) return false;

    let nodeRect = nodeElement.getBoundingClientRect();

    const node_screen_position = rfInstance?.flowToScreenPosition(node.position || node.computed?.positionAbsolute);

    nodeRect = {
      left: node_screen_position?.x,
      right: node_screen_position?.x + nodeRect.width,
      top: node_screen_position?.y,
      bottom: node_screen_position?.y + nodeRect.height,
    }

    return (
      nodeRect.left >= selectionBounds.left &&
      nodeRect.right <= selectionBounds.right &&
      nodeRect.top >= selectionBounds.top &&
      nodeRect.bottom <= selectionBounds.bottom
    );
  }, [rfInstance])

  const onSelectionChange = useCallback(({ nodes, edges }) => {
    // console.log('selected nodes..............', nodes, nodes.filter(node => !node.parentId))

    setSelectedNodes(nodes.filter(node => !node.parentId));
  }, [])

  useEffect(() => {
    if (selectedNodes.length > 1 && !isReadOnly) {
      const bbox = selectedNodes.reduce(
        (acc, node) => {
          acc.left = Math.min(acc.left, node.position.x);
          acc.top = Math.min(acc.top, node.position.y);
          acc.right = Math.max(acc.right, node.position.x + (node.measured?.width || node.computed?.width));
          acc.bottom = Math.max(acc.bottom, node.position.y + (node.measured?.height || node.computed?.height));
          return acc;
        },
        { left: Infinity, top: Infinity, right: -Infinity, bottom: -Infinity }
      );

      const sect = {
        ...bbox,
        width: bbox.right - bbox.left,
        height: bbox.bottom - bbox.top
      };

      const pos = rfInstance?.flowToScreenPosition({
        x: (bbox.left + bbox.right) / 2,
        y: bbox.top - 56,
      });

      setContextMenu({
        left: pos.x,
        top: pos.y,
        type: 'nodes_selection',
        selected_nodes_sect: sect
      });
    }
  }, [selectedNodes, isReadOnly]);

  const onGroup = useCallback((selected_nodes_sect) => {
    const selectedAncestorNodes = selectedNodes.filter(node => !node.parentId);
    if (selectedAncestorNodes.length < 2) return;

    const group_position = {
      x: selected_nodes_sect.left - 25,
      y: selected_nodes_sect.top - 25
    };

    const groupNode = {
      id: new Date().getTime() + '',
      type: 'ai_node',
      // position: { x: 0, y: 0 },
      position: group_position,
      style: {
        width: selected_nodes_sect.width + 50,
        height: selected_nodes_sect.height + 50,
      },
      data: {
        nodeType: 'group',
      },
    };

    const updatedNodes = [
      groupNode,
      ...nodes.map(node => {
        const selected = selectedAncestorNodes.find(item => item.id == node.id);
        if (!selected) return node;

        let position = {
          x: node.position.x - group_position.x,
          y: node.position.y - group_position.y
        };

        return {
          ...node,
          parentId: groupNode.id,
          extent: 'parent',
          position,
          selected: false
        }
      }).filter(n => !!n),
    ];

    setNodes(updatedNodes);
    setSelectedNodes([]);

    setSavingTrigger(Math.random())
  }, [selectedNodes, nodes, setNodes]);

  const getAncestorIds = useCallback((node, nodes) => {
    if (!node?.parentId) return [];

    const parentNode = nodes.find(n => n.id === node.parentId);
    if (!parentNode) return [];

    const ancestors = getAncestorIds(parentNode, nodes);
    ancestors.push(node.parentId);

    return ancestors;
  }, []);

  const getIntersectingGroup = useCallback((node, nodes) => {
    const intersectingNodes = getIntersectingNodes(node);
    return intersectingNodes.filter(n => {
      if (n.data.nodeType !== 'group' || node.parentId == n.id) return false;

      const ancestorIds = getAncestorIds(n, nodes);
      if (ancestorIds.includes(node.id)) return false;

      const childGroups = nodes.filter(cn => cn.data.nodeType === 'group' && cn.parentId === n.id);
      return !childGroups.some(item => intersectingNodes.includes(item));
    })
  }, [])

  const onNodeDrag = useCallback((_, node) => {
    nodes?.filter(n => n.data.nodeType == 'group').map(n => {
      const nodeEle = document.querySelector(`.react-flow__node[data-id="${n.id}"]`);
      if (nodeEle.style.border != 'none') {
        nodeEle.style.border = 'none'
      }
    });

    getIntersectingGroup(node, nodes).map(n => {
      const nodeEle = document.querySelector(`.react-flow__node[data-id="${n.id}"]`);
      if (nodeEle.style.border != '1px solid dodgerblue') {
        nodeEle.style.border = '1px solid dodgerblue'
      }
    });
  }, [nodes]);

  const onNodeDragStop = useCallback((_, node) => {
    // if (node.parentId) return;

    const groupNode = getIntersectingGroup(node, nodes)[0];

    if (groupNode) {
      updateNode({
        ...node,
        parentId: groupNode.id,
        extent: 'parent',
        position: {
          x: node.position.x - groupNode.position.x,
          y: node.position.y - groupNode.position.y
        }
      })

      setSavingTrigger(Math.random())
    }

    nodes?.filter(n => n.data.nodeType == 'group').map(n => {
      const nodeEle = document.querySelector(`.react-flow__node[data-id="${n.id}"]`);
      if (nodeEle.style.border != 'none') {
        nodeEle.style.border = 'none'
      }
    });
  }, [nodes]);

  useEffect(() => {
    dispatch(fetchPrompts({}));
    dispatch(fetchPromptsPinned({}));
  }, []);

  useEffect(() => {
    dispatch(getLngList({ locale: uiLng || lng }));
  }, [uiLng]);

  useEffect(() => {
    set_saved(null);
    setBoardChanged(false);
    hid && dispatch(getDoc({ hid }, null, null, 'editor'));
  }, [hid])

  useEffect(() => {
    setReadOnly(doc?.userId !== loginUser?._id && doc?.permission === DOC_PERMISSION.view)
  }, [loginUser, doc])

  useEffect(() => {
    dispatch(getInviteCode({}, (code) => {
      setInviteCode(code);
    }))

    getCurrentBrowserFingerPrint().then((fingerprint) => {
      set_fingerprint(fingerprint)
    })
  }, [])

  const llm_api_keys = useSelector(state => state.uiState.llm_api_keys);
  const apiKeyDialogState = useSelector(state => state.uiState.llmApiKeyDialog);

  useEffect(() => {
    if (!apiKeyDialogState?.visible && apiKeyDialogState?.confirmedModelId) {
      llm_api_keys?.find(item => item.id === apiKeyDialogState.confirmedModelId) && setTempLlmModel(apiKeyDialogState.confirmedModelId);

      dispatch({
        type: LLM_API_KEY_MODAL,
        value: {
          visible: false
        }
      })
    }
  }, [apiKeyDialogState])

  useEffect(() => {
    setTempLlmModel(null);
    let url = `https://${window.location.host}/#/aiflow?hid=${hid}`;

    if (inviteCode) {
      url = url + '&inviteCode=' + inviteCode;
    };

    if (fingerprint) {
      url = url + '&fp=' + fingerprint;
    }

    setShareUrl(url);
  }, [fingerprint, inviteCode, hid])

  useEffect(() => {
    if (!initNodeData || !rfInstance) return;

    let newNode = {
      id: new Date().getTime() + '',
      type: 'ai_node',
      position: getNewInitNodePosition(),
      data: {
        ...initNodeData,
        nodeType: initNodeData.nodeType || 'aigc',
        showActionBox: ['query', 'initNode', 'summary'].includes(initNodeData.action),
      }
    }

    if (initNodeData.nodeType == 'image') {
      newNode.data.content = {
        src: initNodeData.src
      }
    } else if (initNodeData.queryType === 'link') {
      newNode.data.context = {
        title: initNodeData.title,
        content: initNodeData.content,
        url: initNodeData.url
      }

      newNode.data.content = undefined;
    } else {
      newNode.data.content = initNodeData.content
    }

    setTimeout(() => addNode(newNode), 1000)
  }, [initNodeData, !!rfInstance])

  useEffect(() => {
    if (!add_flow_node) return;

    addNode({
      id: new Date().getTime() + '',
      type: 'ai_node',
      position: getNewInitNodePosition(),
      data: {
        nodeType: 'aigc',
        ai_action: add_flow_node.action,
        title: add_flow_node.title,
        originNodeType: 'prompt'
      }
    })

    dispatch({
      type: ADD_FLOW_NODE,
      value: null
    })
  }, [add_flow_node])

  useEffect(() => {
    if (workingSpace?.users?.length < 2) return;

    dispatch(fetchPromptsWorkspace({}))
  }, [workingSpace])

  useEffect(() => {
    if (!lng_list_origin?.length) return;

    let new_list = [...lng_list_origin];
    new_list.unshift({
      Symbol: 'as_context',
      label: intl.formatMessage({ id: 'set_lang_as_context' })
    })

    set_lng_list(new_list);

    if (!lang?.Symbol) {
      setLang(new_list.find(l => l.Symbol == (lng == 'cn' ? 'zh' : lng)))
    }

  }, [lng_list_origin, intl])

  const onSave = useCallback(() => {
    if (rfInstance) {
      let flow = rfInstance.toObject();
      if (!flow.nodes?.length) {
        return;
      }

      flow.clips = tempNotes;
      const jsonString = JSON.stringify(flow);

      // console.log('will onsave............', hid, jsonString != lastSavedContent, JSON.stringify(jsonString, null, 2))
      if (!loginUser?._id) {
        dispatch({
          type: UNSAVED_FLOW,
          value: {
            hid,
            jsonString
          }
        })
      } else {
        let updatedDoc = { hid: hid, jsonString, type: 'flow' };
        if (!doc?.title || doc.title == 'untitled') {
          updatedDoc.title = flow.nodes[0].data.title || flow.nodes[0].data.userInput;
        }

        setLoading('saving');
        dispatch(upsertDoc({ data: { doc: updatedDoc } }, (updatedDoc) => {
          if (!updatedDoc) return;

          if (!hid) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('hid', updatedDoc.hid);

            const newSearch = searchParams.toString();
            const newPath = `${location.pathname}?${newSearch}`;

            history.replace(newPath);
          }
          set_saved(updatedDoc.hid);

          setBoardChanged(false);
        }, false, 'flow_editor'))
      }
    }
  }, [rfInstance, hid, doc, tempNotes]);

  const onRestore = useCallback((title, jsonString) => {
    if (!rfInstance) return;

    const flow = JSON.parse(jsonString || '{}');
    const { x = 0, y = 0, zoom = 1 } = flow.viewport || {};

    const nodes = flow.nodes || [{
      id: new Date().getTime() + '',
      type: 'ai_node',
      data: {
        nodeType: 'prompt',
        queryType: flow_settings?.defaultQueryType || 'brainstorming',
        userInput: ''
      },
      position: getNewInitNodePosition()
    }];

    setNodes(nodes);
    setEdges(flow.edges || []);

    setTempNotes(flow.clips);

    if (flow.viewport) {
      setViewport({ x, y, zoom });
    } else {
      rfInstance && setTimeout(() => rfInstance.fitView({ maxZoom: 1 }), 500);
    }

    setBoardChanged(false);
    clear();  //clear history
  }, [setNodes, setViewport, rfInstance]);

  useEffect(() => {
    if (inputModalState?.visible || !inputModalState?.value) return;

    const turndownService = new TurndownService();
    const mrkd = turndownService.turndown(inputModalState.value || '')?.trim();

    if (inputModalState.id == 'node_title' && inputModalState.nodeId) {
      updateNodeData(inputModalState.nodeId, {
        title: mrkd
      });

      setSavingTrigger(Math.random());
    } else if (inputModalState.id == 'image_caption' && inputModalState.nodeId) {
      const node = getNode(inputModalState.nodeId);
      updateNodeData(inputModalState.nodeId, {
        content: {
          ...node.data.content,
          caption: mrkd
        },
      });

      setSavingTrigger(Math.random());
    } else if (inputModalState.id == 'board_title' && inputModalState.hid) {
      dispatch(upsertDoc({
        data: {
          doc: {
            hid: inputModalState.hid,
            title: mrkd,
            type: 'flow'
          },
        }
      }, null, false, 'AIFlow'));
    } else if (inputModalState.id == 'item_text') {
      const node = getNode(inputModalState.nodeId);
      const listType = inputModalState.listType;

      if (!node.data) return;

      let items = [...(listType == 'related_questions' && node.data.related_questions_topics.related_questions
        || listType == 'related_topics' && node.data.related_questions_topics.related_topics
        || listType == 'improvement_plan' && node.data.reflect.improvement_plan
        || listType == 'optimized' && node.data.query_optimize.optimized
        || node.data.items)];

      if (inputModalState.parentIndex == undefined) {
        let item = items[inputModalState.index];
        if (typeof item === 'object') {
          item.name = inputModalState.value;
        } else {
          items[inputModalState.index] = inputModalState.value;
        }
      } else {
        items[inputModalState.parentIndex].branches[inputModalState.index] = inputModalState.value
      }

      let updatedData = { items };
      if (listType == 'related_questions') {
        updatedData = {
          related_questions_topics: {
            ...node.data.related_questions_topics,
            related_questions: items
          }
        }
      } else if (listType == 'related_topics') {
        updatedData = {
          related_questions_topics: {
            ...node.data.related_questions_topics,
            related_topics: items
          }
        }
      } else if (listType == 'improvement_plan') {
        updatedData = {
          reflect: {
            ...node.data.reflect,
            improvement_plan: items
          }
        }
      } else if (listType == 'optimized') {
        updatedData = {
          query_optimize: {
            ...node.data.query_optimize,
            optimized: items
          }
        }
      }

      updateNodeData(inputModalState.nodeId, updatedData);

      setSavingTrigger(Math.random());
    } else if (inputModalState.id == 'todo_text') {
      const node = getNode(inputModalState.nodeId);

      let todos = [...node.data.todos];
      todos[inputModalState.index].description = inputModalState.value;

      updateNodeData(inputModalState.nodeId, {
        todos
      });

      setSavingTrigger(Math.random());
    }

    dispatch({
      type: FLOW_INPUT_MODAL,
      value: {
        visible: false
      }
    })
  }, [inputModalState])

  const copy_board = useCallback(() => {
    const copiedContent = JSON.stringify(rfInstance.toObject())
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('hid');

    const newSearch = searchParams.toString();
    const newPath = `${location.pathname}?${newSearch}`;

    history.replace(newPath);

    setTimeout(() => {
      onRestore('', copiedContent)
    }, 500);
  }, [rfInstance, location, history, onRestore])

  useEffect(() => {
    if (actionModalState?.visible || !actionModalState?.action_confirmed) return;

    if (actionModalState.action_confirmed === 'login') {
      window.open('/#/login?source=flow', '_blank');
    } else if (actionModalState.action_confirmed === 'copy_to_editable') {
      copy_board();
    } else if (actionModalState.action_confirmed === 'saved_to_workspace' && actionModalState.data?.hid) {
      window.open('/#/editor?hid=' + actionModalState.data?.hid, '_blank');
    }

    dispatch({
      type: FLOW_MODAL,
      value: {
        visible: false
      }
    })
  }, [actionModalState])

  useEffect(() => {
    if (rfInstance?.viewportInitialized) {
      rfInstance.zoomTo(0.2)
      // rfInstance.fitView();
      setTimeout(() => {
        rfInstance.fitView({ maxZoom: 0.1 });

        setTimeout(() => {
          const nodes = rfInstance.getNodes();
          const edges = rfInstance.getEdges();
          const firstOriginNode = edges && nodes?.find(n => !getIncomers(n, nodes, edges)?.length);
          rfInstance.fitView({ maxZoom: 0.88, nodes: firstOriginNode ? [firstOriginNode] : undefined });
        }, 500);
      }, 200)

    }
  }, [rfInstance])

  const onPaneClick = useCallback(() => {
    setContextMenu(null)

    const selection = window.getSelection();
    selection?.removeAllRanges();
  }, [setContextMenu]);

  const onLayout = useCallback(
    (direction) => {
      if (direction == 'horizon') {
        getLayoutedElements({
          'elk.algorithm': 'layered',
          'elk.direction': 'DOWN',
        })
      } else if (direction == 'vertical') {
        getLayoutedElements({
          'elk.algorithm': 'layered',
          'elk.direction': 'RIGHT',
        })
      } else if (direction == 'force') {
        // getLayoutedElements({
        //   'elk.algorithm': 'org.eclipse.elk.force',
        //   'elk.force.repulsion': 0.5,
        //   'elk.padding': 10,
        //   'elk.spacing.nodeNode': 10,
        //   'force.iterations': 2000
        // })
        getLayoutedElementsD3()
      }
    },
    [nodes, edges, rfInstance],
  );

  const [saved, set_saved] = useState();

  const [boardChanged, setBoardChanged] = useState();

  useEffect(() => {
    // console.log('saving triggered.................', savingTrigger)
    savingTrigger && onSave();
  }, [savingTrigger]);

  const addInitNode = useCallback(({ nodeType = 'prompt', queryType, ai_action, hid, title, content, position, userInput, fitView, trigger, useCase }) => {
    ReactGA.event({ category: 'promptNodes', action: 'add_init_node', label: queryType });

    const id = new Date().getTime() + '';

    addNode({
      id,
      position: getNewInitNodePosition(position),
      data: {
        label: `New`,
        nodeType,
        queryType,
        ai_action,
        userInput,
        hid,
        title,
        content,
        trigger,
        useCase
      },
      type: 'ai_node'
    })

    fitView && rfInstance && setTimeout(() => rfInstance.fitView({ maxZoom: 1, nodes: [{ id }] }), 500)
  }, [rfInstance]);

  const isValidUrl = useCallback((string) => {
    return urlRegex({ exact: true }).test(string?.trim());
  }, []);

  const isImageUrl = useCallback(async (url) => {
    try {
      const urlObj = new URL(url);
      const pathname = urlObj.pathname;

      const imageExtensionPattern = /\.(jpeg|jpg|gif|png|bmp)$/i;
      return imageExtensionPattern.test(pathname);
    } catch (error) {
      console.error('Invalid URL:', error);
      return false;
    }
  }, []);

  const uploadImage = useCallback((file, position) => {
    dispatch(uploadImgs({ files: [file], hid, enctype: 'multipart' }, (files) => {
      if (!files || !files.length || !files[0].uri) {
        return dispatch({
          type: OPERATION_FAILED,
          message: intl.formatMessage({ id: 'upload_failed' })
        });
      }

      addInitNode({
        nodeType: 'image',
        content: {
          src: files[0].uri,
        },
        position
      })
    }, 'editor'));
  }, [hid, addInitNode]);


  const handlePasteContent = useCallback(async () => {
    const clipboardItems = await navigator.clipboard.read();
    for (const clipboardItem of clipboardItems) {
      for (const type of clipboardItem.types) {
        if (type.startsWith('image/')) {
          const blob = await clipboardItem.getType(type);
          const file = new File([blob], 'pasted-image.png', { type: blob.type });
          return ({ type: 'image', content: file });
        } else if (type === 'text/plain') {
          const text = await clipboardItem.getType(type);
          const textString = await text.text();
          if (isValidUrl(textString)) {
            const isImageUrlResult = await isImageUrl(textString);
            if (isImageUrlResult) {
              return ({ type: 'image-url', content: textString });
            } else {
              return ({ type: 'url', content: textString });
            }
          } else {
            return ({ type: 'text', content: textString });
          }
        }
      }
    }
  }, []);

  const parseString = useCallback((text) => {
    const lines = text?.trim()?.split('\n');
    let id;
    let title;
    let content;
    let contentStartIndex = 0;

    if (lines.length > 0 && lines[0].startsWith('id: ')) {
      id = lines[0].substring(4).trim();
      contentStartIndex = 1;

      for (let i = 1; i < lines.length; i++) {
        if (lines[i].trim() === '') {
          contentStartIndex++;
        } else if (lines[i].startsWith('# ')) {
          title = lines[i].substring(2).trim();
          contentStartIndex = i + 1;
          break;
        } else {
          break;
        }
      }
    }

    content = lines.slice(contentStartIndex).join('\n').trim();

    return { id, title, content };
  }, []);

  const handlePaste = useCallback(async (position) => {

    const pasted = await handlePasteContent();
    if (!pasted) return;

    let content_parsed;
    if (pasted.type === 'text') {
      content_parsed = parseString(pasted.content)
    }

    const selected_node = selectedNodes?.length === 1 && selectedNodes[0];
    if (selected_node && content_parsed?.content && selected_node.id == content_parsed.id) {
      let updateData = {};

      if (selected_node.data.nodeType === 'prompt') {
        updateData.userInput = content_parsed.content;
      } else {
        updateData.title = content_parsed.title;
        updateData.content = content_parsed.content;
      }

      updateNodeData(selected_node.id, updateData);
      return;
    }

    if (copiedNode?.id && copiedNode.id == content_parsed?.id) {
      const newNode = {
        ...copiedNode,
        id: new Date().getTime() + '',
        position: getNewInitNodePosition(position),
        parentId: undefined,
        extent: undefined
      };

      addNode(newNode);
      return;
    }

    if (pasted.type === 'url') {
      return addInitNode({
        queryType: 'link',
        userInput: pasted.content,
        position
      })
    }

    if (pasted.type === 'image-url') {
      return addInitNode({
        nodeType: 'image',
        content: {
          src: getProxiedImageUrl(pasted.content, service_endpoint)
        },
        position
      })
    }

    if (pasted.type === 'image') {
      return uploadImage(pasted.content, position)
    }

    if (pasted.type === 'text') {
      return addInitNode({ queryType: 'dynamic', userInput: pasted.content, position })
    }

  }, [updateNodeData, selectedNodes, copiedNode, addInitNode]);

  const isElementInput = useCallback((event) => {
    const checkElement = (el) => {
      // 检查元素本身是否为输入类型
      if (el.tagName === 'INPUT' || el.tagName === 'TEXTAREA' || el.isContentEditable) {
        return true;
      }

      // 特别检查 contenteditable 属性
      if (el.getAttribute && el.getAttribute('contenteditable') === 'true') {
        return true;
      }

      return false;
    };

    let element = event.target;

    while (element && element !== document.body) {
      if (checkElement(element)) {
        return true;
      }

      // 检查 shadowRoot
      if (element.shadowRoot) {
        if (isElementInput({ target: element.shadowRoot })) {
          return true;
        }
      }

      // 使用 composedPath 来获取事件路径
      if (event.composedPath && element === event.target) {
        const path = event.composedPath();
        for (let el of path) {
          if (el === window) break; // 到达顶层，停止检查
          if (checkElement(el)) {
            return true;
          }
        }
        // 如果 composedPath 中没找到，就不再继续查找
        break;
      }

      // 常规的父元素检查
      // 使用 parentNode 而不是 parentElement，以确保能处理所有类型的节点
      element = element.parentNode;
    }

    return false;
  }, []);

  const handlePasteEvent = useCallback(async (event) => {
    const element = event.target;
    if (isElementInput(event)) {
      event.stopPropagation();
    } else {
      handlePaste();
    }

  }, [handlePaste]);

  const copyNode = useCallback((node) => {
    setCopiedNode(node);

    let content = 'id: ' + node.id;

    let nodeContent = getNodeContent(node);
    if (nodeContent) {
      content = content + '\n\n' + nodeContent;
    }

    navigator.clipboard.writeText(content);
  }, [setCopiedNode])

  const isCtrlCommand = useCallback((event, key) => {
    return (event.ctrlKey && event.key === key) ||
      (event.metaKey && event.key === key)
  }, []);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (isCtrlCommand(event, 'z')) {
        if (event.shiftKey) {
          set_action_to_history(true)
          redo();
        } else {
          set_action_to_history(true)
          undo();
        }
        return;
      }

      if (isCtrlCommand(event, 's')) {
        event.stopPropagation();
        event.preventDefault()

        onSave();
        return;
      }

      if (isCtrlCommand(event, 'c') && !window.getSelection()?.toString()) {
        if (selectedNodes?.length != 1) return;

        const node = selectedNodes[0];

        if (event.shiftKey) {
          navigator.clipboard.writeText(node.data.nodeType !== 'image' ? JSON.stringify(node.data, null, 2) : node.data.content.src).then(() => { });
        } else {
          copyNode(node);
        }
      }

    };

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('paste', handlePasteEvent);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('paste', handlePasteEvent);
    };
  }, [onSave, handlePasteEvent, selectedNodes]);

  useEffect(() => {
    // console.log('should restore?..............', hid, saved, doc?.jsonString, rfInstance)
    if (hid) {
      saved != hid && onRestore(!doc?.untitled && doc?.title, doc?.jsonString)
    }
  }, [doc?.jsonString, !!rfInstance, hid])

  useEffect(() => {
    !loginUser._id && !!unsaved_flow?.jsonString && hid === unsaved_flow.hid && !params?.action && onRestore('', unsaved_flow.jsonString)
  }, [unsaved_flow?.jsonString, !!rfInstance])

  useEffect(() => {
    if (!newNode?.position) return;

    setTimeout(() => rfInstance?.setCenter(newNode.position.x + 80, newNode.position.y + 220, { zoom: 0.9 }), 300);
  }, [newNode])

  const getNewInitNodePosition = (position) => {
    const pane = ref?.current?.getBoundingClientRect() || { width: window.innerWidth, height: window.innerHeight };

    if (!position) {
      position = {
        x: pane.width / 2 - 100,
        y: pane.height / 2 - 100
      }
    }

    return rfInstance?.screenToFlowPosition(position);
  }

  const addNote = useCallback((position, note) => {
    addNode({
      id: new Date().getTime() + '',
      position: getNewInitNodePosition(position),
      data: {
        label: `New`,
        nodeType: 'note',
        color_theme: 'yellow',
        content: note || ''
      },
      type: 'ai_node',
    });

    ReactGA.event({ category: 'non_ai_nodes', action: 'add_notes', label: 'new notes' });
  }, [getNewInitNodePosition]);

  const addEmptyTodos = useCallback(
    (position) => {
      addNode({
        id: new Date().getTime() + '',
        position: getNewInitNodePosition(position),
        data: {
          title: `New Todo list`,
          nodeType: 'aigc',
          color_theme: 'green',
          queryType: 'todos',
          contentType: 'todos',
          content: intl.formatMessage({ id: 'empty_todo_list_tips' }),
          priorities: ['high', 'medium', 'low']
        },
        type: 'ai_node',
      })

      ReactGA.event({ category: 'non_ai_nodes', action: 'add_todos', label: 'new todos' });
    }, [getNewInitNodePosition, intl]);

  const addImage = useCallback((position) => {
    ReactGA.event({ category: 'promptNodes', action: 'prompt_upload_image', label: 'image' });

    dispatch({ type: IMAGE_UPLOAD_DIALOG, value: { visible: true, trigger: 'flow_editor', position } });
  }, []);

  const addFunBlocksPage = useCallback((position) => {
    ReactGA.event({ category: 'promptNodes', action: 'prompt_choose_funblocks_page', label: 'funblocks_pages' });

    dispatch({ type: PAGE_CHOOSER_DIALOG, value: { visible: true, trigger: 'flow_editor', position } });
  }, []);

  const [service_endpoint, set_service_endpoint] = useState();
  useEffect(() => {
    get_server_host().then(host => set_service_endpoint(host));
  }, [])

  const imageUploadState = useSelector(state => state.uiState.imageUploadDialog) || {};
  useEffect(() => {
    const { image } = imageUploadState;
    if (imageUploadState.visible || imageUploadState.trigger != 'flow_editor' || !image?.link) {
      return;
    }

    ReactGA.event({ category: 'promptNodes', action: 'add_init_node', label: 'image' });

    addInitNode({
      nodeType: 'image',
      position: imageUploadState.position,
      content: {
        src: getProxiedImageUrl(image.link, service_endpoint),
        caption: image.altText
      }
    })

    setSavingTrigger(Math.random())

    dispatch({
      type: IMAGE_UPLOAD_DIALOG,
      value: {
        visible: false
      }
    })
  }, [imageUploadState])

  const pageChooserState = useSelector(state => state.uiState.page_chooser_dialog) || {};
  useEffect(() => {
    const { item, position } = pageChooserState;
    if (pageChooserState.visible || pageChooserState.trigger != 'flow_editor' || !item) {
      return;
    }

    ReactGA.event({ category: 'promptNodes', action: 'add_init_node', label: item.type });

    addInitNode({
      nodeType: item.type === 'slides' ? 'slides' : 'funblocks_doc',
      position,
      title: item.title,
      hid: item.hid
    })

    setSavingTrigger(Math.random())

    dispatch({
      type: PAGE_CHOOSER_DIALOG,
      value: {
        visible: false
      }
    })
  }, [pageChooserState])


  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.type.startsWith('image/')) {
        uploadImage(file);
      }
    }
  }, [uploadImage]);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const ai_lng_selector = useMemo(() => {
    if (!lng_list) return null;

    return <div className='output-lng-selector'>
      <Selector
        options={lng_list.filter(lng => !!lng).map(lang => {
          return { label: lang.label, value: lang.Symbol }
        })}
        value={lang.Symbol}
        tooltip={intl.formatMessage({ id: 'set_ai_response_lang' })}
        onChange={(value) => {
          setLang(lng_list.find(l => l.Symbol == value))
        }}
        inputStyle={{
          marginRight: isMobile ? undefined : 30,
          border: 'none',
          borderRadius: '0px',
          borderBottom: '1px solid #aaa',
          borderRight: isMobile ? undefined : '1px solid #aaa',
          borderLeft: '1px solid #aaa',
          borderBottomRightRadius: isMobile ? undefined : 3,
          borderBottomLeftRadius: 3,
          boxShadow: '0px 0px 8px #bbb',
          padding: isMobile ? 3 : undefined,
          paddingLeft: isMobile ? 6 : undefined
        }}
        dropdownIconSize={22}
      />
    </div>;
  }, [lng_list, lang, isMobile])

  const ai_model_selector = useMemo(() => (
    <div className='model-selector'>
      <ModelSelector
        inputStyle={{
          border: 'none',
          borderRadius: '0px',
          borderBottom: '1px solid #aaa',
          borderRight: '1px solid #aaa',
          borderLeft: '1px solid #aaa',
          borderBottomRightRadius: 3,
          borderBottomLeftRadius: 3,
          boxShadow: '0px 0px 8px #bbb',
          marginRight: isMobile ? undefined : 30,
          padding: isMobile ? 3 : undefined,
          paddingLeft: isMobile ? 6 : undefined,
        }}
        dropdownIconSize={22}
        value={tempLlmModel}
        onSelect={(value) => {
          setTempLlmModel(value);
        }}
      />
    </div>), [tempLlmModel, isMobile])

  const button_group_style = useMemo(() => ({
    borderBottom: '1px solid #aaa',
    borderRight: isMobile ? undefined : '1px solid #aaa',
    borderLeft: '1px solid #aaa',
    borderTop: isMobile ? '1px solid #aaa' : undefined,
    borderTopLeftRadius: isMobile ? 3 : undefined,
    borderBottomRightRadius: isMobile ? undefined : 3,
    borderBottomLeftRadius: 3,
    boxShadow: '0px 0px 8px #bbb',
    backgroundColor: 'white',
    marginRight: isMobile ? undefined : 30,
    marginBottom: isMobile ? 30 : undefined,
    display: 'flex',
    flexDirection: isMobile ? 'column-reverse' : 'row',
  }), [isMobile]);

  const [user_behavior, set_user_behavior] = useState();
  const [tempNoteBookPosition, setTempNoteBookPosition] = useState({ top: 0, left: 0 });
  const [showTempNoteBook, setShowTempNoteBook] = useState();

  useEffect(() => {
    const updatePanelPosition = () => {
      const minimap = document.querySelector('.react-flow__minimap');
      if (minimap) {
        const { top, right } = minimap.getBoundingClientRect();
        setTempNoteBookPosition({
          top: top - 50, // 40px above the minimap, adjust as needed
          // left: left,
          right: window.innerWidth - right
        });
      }
    };

    // Initial position
    updatePanelPosition();

    // Update position on window resize
    window.addEventListener('resize', updatePanelPosition);

    return () => {
      window.removeEventListener('resize', updatePanelPosition);
    };
  }, []);

  return (
    <PreventSwipeNavigation>
      <div className='fill-available simple-floatingedges'
        style={{
          height: '100vh',
          position: 'relative'
        }}

        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <ReactFlow
          id='flow_container'
          ref={ref}
          nodes={nodes}
          edges={edges}
          nodesDraggable={!isReadOnly}
          nodesConnectable={!isReadOnly}
          onNodesChange={(changes) => {
            !isReadOnly &&
              // (changes[0].type != "dimensions" || !action_to_history) &&   //Todo: 还是有问题，回退时如何避免让AI重新开始写！
              onNodesChange(changes);
            setBoardChanged(true);
            set_action_to_history(false)
          }}
          onEdgesChange={(changes) => {
            !isReadOnly && onEdgesChange(changes);
          }}
          onConnect={(connection) => {
            !isReadOnly && onConnect(connection);
          }}
          onEdgeUpdate={!isReadOnly ? onEdgeUpdate : undefined}
          onPaneClick={onPaneClick}
          onNodeContextMenu={(event, node) => !['note'].includes(node.data.nodeType) && onContextMenu({ type: 'node', event, nodeId: node.id, setContextMenu })}
          onPaneContextMenu={(event) => onContextMenu({ type: 'pane', event, setContextMenu })}
          onSelectionChange={onSelectionChange}
          onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
          onMoveStart={() => {
            set_user_behavior('drag_selection');
          }}
          onMoveEnd={() => {
            set_user_behavior(null);
          }}
          onInit={setRfInstance}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          minZoom={0.4}
          style={{
            touchAction: 'auto',
            zoom: 1
          }}

          panOnDrag={flow_settings?.dragBehavior !== 'selection'}
          selectionMode={'full'}
          selectionOnDrag={flow_settings?.dragBehavior == 'selection'}

          panOnScroll={flow_settings?.scrollBehavior !== 'zoom'}
          panOnScrollMode='free'

          zoomOnPinch={!flow_settings?.disablePinchToZoom}
          deleteKeyCode={!flow_settings?.deleteNodeDisabled ? 'Backspace' : null}
        // selectionKeyCode={flow_settings?.dragBehavior != 'selection' ? 'Shift': null}
        // zoomActivationKeyCode={flow_settings?.zoomKey || (isMac ? 'Meta' : 'Control')}
        // panActivationKeyCode={flow_settings?.panKey || 'Space'}
        >
          {
            !!tempNotes?.length &&
            <div style={{
              backgroundColor: '#f8f8f8',
              position: 'absolute',
              top: `${tempNoteBookPosition.top}px`,
              right: `${tempNoteBookPosition.right}px`,
              // backgroundColor: 'dodgerblue',
              boxShadow: '0px 0px 8px #bbb',
              border: '1px solid #ddd',
              borderRadius: 3,
              zIndex: 9999
            }}>
              <div
                className='hoverStand'
                style={{
                  padding: 5,
                }}
                onClick={() => setShowTempNoteBook(prevState => !prevState)}
              >
                <TempNoteBook
                  showNoteBook={showTempNoteBook}
                  addNote={addNote}
                  addInitNode={addInitNode}
                  onclose={() => setShowTempNoteBook(false)}
                />
              </div>
            </div>
          }
          <MiniMap
            className='minimap'
            pannable
            zoomable
            style={{
              boxShadow: '0px 0px 8px #bbb',
              border: '1px solid #ddd'
            }}
            nodeColor={useCallback((node) => {
              let color = node_color_themes.find(theme => theme.id === (node.data.color_theme || 'blue'))?.title_bg
              return color;
            }, [node_color_themes])}
            onClick={(event, position) => {
              const currentViewPort = rfInstance.getViewport();

              rfInstance.setCenter(position.x, position.y, { zoom: currentViewPort.zoom, duration: 200 })
            }}
          />

          <Controls
            className='board-viewport-operations'
            style={{
              boxShadow: '0px 0px 8px #bbb',
              border: '1px solid #aaa',
              marginLeft: isMobile ? 0 : undefined,
            }}
          >

            <Selector
              triggerElement={
                <Tooltip title={intl.formatMessage({ id: 'layout_boards' })} placement='right'>
                  <div>
                    <LayoutMasonry size={12} color='#000' />
                  </div>
                </Tooltip>
              }
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}

              inputStyle={{
                border: 'none',
                height: 26,
                width: 26,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,
                margin: 0
              }}

              itemStyle={{
                justifyContent: 'center'
              }}

              options={[{
                value: 'horizon',
                icon: <AlignVerticalTop size={14} />
              }, {
                value: 'vertical',
                icon: <AlignHorizontalLeft size={14} />
              }, {
                value: 'force',
                icon: <ScatterPlot size={14} />
              }]}

              onChange={onLayout}
            />

          </Controls>

          <Background />

          <Panel position="left" style={{
            marginTop: 100,
            marginLeft: isMobile ? 0 : undefined
          }}>

            <Tooltip title={intl.formatMessage({ id: `${flow_settings?.defaultQueryType || 'brainstorming'}_tips` })} placement="right">
              <div
                className='dynamic-ai-nodes hoverButtonBlue'
                style={{
                  // backgroundColor: 'dodgerblue',
                  boxShadow: '0px 0px 8px #bbb',
                  padding: 6
                }}

                onClick={() => addInitNode({ queryType: flow_settings?.defaultQueryType || 'brainstorming' })}
              >
                {
                  flow_settings?.defaultQueryType === 'dynamic' &&
                  <Magic size={19} color='white' />

                }
                {
                  flow_settings?.defaultQueryType !== 'dynamic' &&
                  <LightUp size={18} color='white' />
                }
              </div>
            </Tooltip>

            <div
              className='more-ai-nodes'
              style={{
                border: '1px solid #aaa',
                boxShadow: '0px 0px 8px #bbb',
                backgroundColor: 'white',
                marginTop: 30,
                borderRadius: 3
              }}>
              <Tooltip title={intl.formatMessage({ id: flow_settings?.defaultQueryType === 'dynamic' ? 'brainstorming_tips' : 'dynamic_tips' })} placement="right">
                <div
                  className='hoverStand'
                  style={{
                    padding: 6,
                  }}
                  onClick={() => addInitNode({ queryType: flow_settings?.defaultQueryType === 'dynamic' ? 'brainstorming' : 'dynamic' })}
                >
                  {
                    flow_settings?.defaultQueryType !== 'dynamic' &&
                    <Magic size={19} />

                  }
                  {
                    flow_settings?.defaultQueryType === 'dynamic' &&
                    <LightUp size={18} />
                  }
                </div>
              </Tooltip>

              <div
                style={{
                  width: '100%',
                  borderTop: '1px solid #ddd',
                }}
              />

              <Tooltip title={intl.formatMessage({ id: 'ask_question_tips' })} placement="right">
                <div
                  className='hoverStand'
                  style={{
                    padding: 6
                  }}
                  onClick={() => addInitNode({ queryType: 'ask' })}
                >
                  <Message size={18} />
                </div>
              </Tooltip>

              <div
                style={{
                  width: '100%',
                  borderTop: '1px solid #ddd',
                }}
              />

              <Tooltip title={intl.formatMessage({ id: 'generate_todo_list_tips' })} placement="right">
                <div
                  className='hoverStand'
                  style={{
                    padding: 6,
                  }}
                  onClick={() => addInitNode({ queryType: 'todos' })}
                >
                  <ListTask size={18} />
                </div>
              </Tooltip>

              <div
                style={{
                  width: '100%',
                  borderTop: '1px solid #ddd',
                }}
              />

              <Tooltip title={intl.formatMessage({ id: 'breakdown_topics_tips' })} placement="right">
                <div
                  className='hoverStand'
                  style={{
                    padding: 6,
                  }}
                  onClick={() => addInitNode({ queryType: 'breakdown' })}
                >
                  <List size={18} />
                </div>
              </Tooltip>

              <div
                style={{
                  width: '100%',
                  borderTop: '1px solid #ddd',
                }}
              />

              <Tooltip title={intl.formatMessage({ id: 'rationale_decision' })} placement="right">
                <div
                  className='hoverStand'
                  style={{
                    padding: 6
                  }}
                  onClick={() => addInitNode({ queryType: 'brainstorming', useCase: 'decision_analysis' })}
                >
                  <Scales3 size={18} />
                </div>
              </Tooltip>

              {/* <div
                style={{
                  width: '100%',
                  borderTop: '1px solid #ddd',
                }}
              />

              <Tooltip title={intl.formatMessage({ id: 'search_web' })} placement="right">
                <div
                  className='hoverStand'
                  style={{
                    padding: 6
                  }}
                  onClick={() => addInitNode({ queryType: 'search' })}
                >
                  <Search size={18} />
                </div>
              </Tooltip> */}
            </div>

            <div
              className='more-content-nodes'
              style={{
                border: '1px solid #aaa',
                boxShadow: '0px 0px 8px #bbb',
                backgroundColor: 'white',
                marginTop: 30,
                borderRadius: 3
              }}>

              <Tooltip title={intl.formatMessage({ id: 'generate_book_mindmap' })} placement="right">
                <div
                  className='hoverStand'
                  style={{
                    padding: 6,
                  }}
                  onClick={() => addInitNode({ queryType: 'book' })}
                >
                  <BookOpen size={18} />
                </div>
              </Tooltip>

              <div
                style={{
                  width: '100%',
                  borderTop: '1px solid #ddd',
                }}
              />

              <Tooltip title={intl.formatMessage({ id: 'generate_movie_mindmap' })} placement="right">
                <div
                  className='hoverStand'
                  style={{
                    padding: 6,
                  }}
                  onClick={() => addInitNode({ queryType: 'movie' })}
                >
                  <Movie size={18} />
                </div>
              </Tooltip>

              <div
                style={{
                  width: '100%',
                  borderTop: '1px solid #ddd',
                }}
              />

              <Tooltip title={intl.formatMessage({ id: 'add_link' })} placement="right">
                <div
                  className='hoverStand'
                  style={{
                    padding: 6,
                  }}
                  onClick={() => addInitNode({ queryType: 'link' })}
                >
                  <Link size={18} />
                </div>
              </Tooltip>

              <div
                style={{
                  width: '100%',
                  borderTop: '1px solid #ddd',
                }}
              />

              <Tooltip title={intl.formatMessage({ id: 'add_image' })} placement="right">
                <div
                  className='hoverStand'
                  style={{
                    padding: 6,
                  }}
                  onClick={() => addImage(getNewInitNodePosition())}
                >
                  <Image size={18} />
                </div>
              </Tooltip>

              <div
                style={{
                  width: '100%',
                  borderTop: '1px solid #ddd',
                }}
              />

              <Tooltip title={intl.formatMessage({ id: 'add_funblocks_page' })} placement="right">
                <div
                  className='hoverStand'
                  style={{
                    padding: 6,
                  }}
                  onClick={() => addFunBlocksPage(getNewInitNodePosition())}
                >
                  <FileText size={18} />
                </div>
              </Tooltip>
            </div>

            <div
              className='non-ai-nodes'
              style={{
                border: '1px solid #aaa',
                boxShadow: '0px 0px 8px #bbb',
                backgroundColor: 'white',
                marginTop: 30,
                borderRadius: 3
              }}>
              <Tooltip title={intl.formatMessage({ id: 'add_note' })} placement="right">
                <div
                  className='hoverStand'
                  style={{
                    padding: 6
                  }}
                  onClick={() => addNote()}
                >
                  <StickyNote size={18} />
                </div>
              </Tooltip>
              <div
                style={{
                  width: '100%',
                  borderTop: '1px solid #ddd',
                }}
              />
              <Tooltip title={intl.formatMessage({ id: 'empty_todo_list' })} placement="right">
                <div
                  className='hoverStand'
                  style={{
                    padding: 6
                  }}
                  onClick={() => addEmptyTodos()}
                >
                  <ListTask size={18} />
                </div>
              </Tooltip>
            </div>
          </Panel>

          {
            !isMobile && !flow_settings?.hideHintPanel &&
            <Panel
              className='board-interaction-hint'
              position='bottom-left'
              style={{
                marginLeft: 120
              }}
            >
              <HintPanel user_behavior={user_behavior} />
            </Panel>
          }

          {
            !standAlone &&
            <Panel
              position='top-left'
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 0,
                marginLeft: 0,
              }}>
              <div
                style={{
                  borderBottom: '1px solid #aaa',
                  borderRight: '1px solid #aaa',
                  boxShadow: '0px 0px 8px #bbb',
                  backgroundColor: 'white',
                  marginRight: isMobile ? undefined : 30,
                  borderBottomRightRadius: 3,
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  maxWidth: 400,
                  minWidth: 200,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>
                <span>
                  {doc?.title || intl.formatMessage({ id: 'untitled' })}
                </span>
              </div>
            </Panel>
          }


          {
            isMobile &&
            <Panel
              position='top-right'
              style={{
                display: 'flex',
                marginTop: 0,
                marginRight: 0,
                columnGap: 10
              }}>
              {
                ai_model_selector
              }
              {
                ai_lng_selector
              }
            </Panel>
          }

          <Panel
            position='top-right'
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column-reverse' : 'row',
              marginTop: isMobile ? 80 : 0,
              marginRight: isMobile ? 0 : undefined,
            }}>
            {
              !isMobile && ai_model_selector
            }

            {
              !isMobile && ai_lng_selector
            }

            {
              !isReadOnly &&
              <div
                className='board-operations'
                style={button_group_style}
              >
                <Tooltip title={intl.formatMessage({ id: 'undo' })} placement="bottom">
                  <div
                    className='hoverStand'
                    style={{
                      padding: 6,
                    }}
                    onClick={() => {
                      set_action_to_history(true)
                      undo()
                    }}
                  >
                    <ArrowUndo color={!!pastStates.length ? undefined : 'gray'} size={18} />
                  </div>
                </Tooltip>
                <div
                  style={{
                    width: '100%',
                    borderLeft: '1px solid #ddd',
                  }}
                />
                <Tooltip title={intl.formatMessage({ id: 'redo' })} placement="bottom">
                  <div
                    className='hoverStand'
                    style={{
                      padding: 6,
                    }}
                    onClick={() => {
                      set_action_to_history(true)
                      redo()
                    }}>
                    <ArrowRedo color={!!futureStates.length ? undefined : 'gray'} size={18} />
                  </div>
                </Tooltip>
                <div
                  style={{
                    width: '100%',
                    borderLeft: '1px solid #ddd',
                  }}
                />
                <Tooltip title={intl.formatMessage({ id: 'save_page' })} placement="bottom">
                  <div
                    className='hoverStand'
                    style={{
                      padding: 6,
                    }}
                    onClick={() => {
                      if (!boardChanged || loading === 'saving') return;

                      onSave()
                    }}>
                    {loading === 'saving' ? <CircularProgress size={18} /> : <Save3 color={boardChanged ? undefined : 'gray'} size={18} />}
                  </div>
                </Tooltip>
              </div>
            }

            {
              isReadOnly &&
              <Tooltip title={intl.formatMessage({ id: 'copy_to_edit' })} placement="bottom">

                <div
                  style={{
                    backgroundColor: buttonHovered == 'copy' ? 'dodgerblue' : 'deepskyblue',
                    color: 'white',
                    padding: 6,
                    borderBottom: `1px solid ${buttonHovered == 'copy' ? 'dodgerblue' : 'deepskyblue'}`,
                    borderRight: isMobile ? undefined : `1px solid ${buttonHovered == 'copy' ? 'dodgerblue' : 'deepskyblue'}`,
                    borderLeft: `1px solid ${buttonHovered == 'copy' ? 'dodgerblue' : 'deepskyblue'}`,
                    borderTopLeftRadius: isMobile ? 3 : undefined,
                    borderBottomRightRadius: isMobile ? undefined : 3,
                    borderBottomLeftRadius: 3,
                    boxShadow: '0px 0px 8px #bbb',
                    marginRight: isMobile ? undefined : 30,
                    marginBottom: isMobile ? 30 : undefined,
                    display: 'flex',
                    flexDirection: 'row',
                    cursor: 'pointer'
                  }}

                  onClick={() => copy_board()}
                  onMouseEnter={() => setButtonHovered('copy')}
                  onMouseLeave={() => setButtonHovered(null)}
                >
                  <FileCopy size={18} />
                </div>
              </Tooltip>
            }

            <div
              className='share-board'
              style={button_group_style}
            >
              <DownloadButton title={doc?.title} shareUrl={shareUrl} />
              <div
                style={{
                  width: '100%',
                  borderLeft: '1px solid #ddd',
                }}
              />
              <ShareButton
                isMobile={isMobile}
                shareData={{ title: doc?.title, url: shareUrl }}
                beforeOnClick={() => new Promise((resolve) => {
                  setTimeout(() => {
                    resolve("Shareit!");
                  }, 2000);
                })}
              />
            </div>

            {
              standAlone &&
              <div
                className='download-extension'
                style={button_group_style}>
                <Tooltip title={intl.formatMessage({ id: 'invite_to_earn' }, { coins: 200 })} placement="bottom">
                  <div
                    className='hoverStand'
                    style={{
                      padding: 6,
                      position: 'relative'
                    }}
                    onClick={() => {
                      dispatch({ type: INVITE_FRIENDS_DIALOG, value: { visible: true } });
                    }}
                  >
                    <Gift size={16} />
                    <div
                      style={{
                        position: 'absolute', // 使用绝对定位
                        top: 2,
                        right: 2,
                        width: 6,
                        height: 6,
                        borderRadius: '50%',
                        backgroundColor: 'tomato',
                      }}
                    />
                  </div>
                </Tooltip>
              </div>
            }

            {
              !isMobile && standAlone &&
              <div
                className='download-extension'
                style={button_group_style}>
                <Tooltip title={intl.formatMessage({ id: 'install_funblocks_ai_extension' })} placement="bottom">
                  <div
                    className='hoverStand'
                    style={{
                      padding: 6,
                    }}
                    onClick={() => {
                      const userAgent = navigator.userAgent;
                      if (userAgent.includes("Edg")) {
                        window.open('https://microsoftedge.microsoft.com/addons/detail/funblocks-ai-your-ultim/lmmlojdklhcdiefaniakpkhhdmamnigk', '_blank')
                      } else if (userAgent.includes("Chrome")) {
                        window.open('https://chromewebstore.google.com/detail/funblocks-ai-your-ultimat/coodnehmocjfaandkbeknihiagfccoid', '_blank')
                      } else {
                        dispatch({
                          type: FLOW_MODAL,
                          value: {
                            visible: true,
                            action: 'extension_not_supported_for_current_browser'
                          }
                        })
                      }
                    }}
                  >
                    <Install size={18} />
                  </div>
                </Tooltip>
              </div>
            }

            {
              standAlone &&
              <div
                className='settings'
                style={button_group_style}
              >
                <Tooltip title={intl.formatMessage({ id: 'flow_settings' })} placement="bottom">
                  <div
                    className='hoverStand'
                    style={{
                      padding: 6,
                    }}
                    onClick={() => {
                      dispatch({ type: SETTINGS_DIALOG, value: { visible: true, app: 'flow' } });
                    }}
                  >
                    <Settings size={18} />
                  </div>
                </Tooltip>
                <div
                  style={{
                    width: '100%',
                    borderLeft: '1px solid #ddd',
                  }}
                />
                <Tooltip title={intl.formatMessage({ id: loginUser?._id ? 'logout' : 'login' })} placement="bottom">
                  <div
                    className='hoverStand '
                    style={{
                      padding: 6,
                    }}
                    onClick={() => {
                      if (!loginUser?._id) {
                        window.open('/#/login?source=flow', '_blank')
                      } else {
                        dispatch(logout_of_server({}));
                      }
                    }}
                  >
                    {
                      loginUser?._id &&
                      <LogoutBoxR size={18} />
                    }
                    {
                      !loginUser?._id &&
                      <LoginBox size={18} />
                    }

                  </div>
                </Tooltip>
              </div>
            }
          </Panel>

          {
            context_menu &&
            <ContextMenu
              onClick={onPaneClick}
              {...context_menu}
              nodes={nodes}
              edges={edges}
              getNodeEdges={getNodeEdges}
              setNodes={setNodes}
              setEdges={setEdges}
              getNode={getNode}
              addNode={addNode}
              addNote={addNote}
              addImage={addImage}
              paste={handlePaste}
              copyNode={copyNode}
              deleteNode={deleteNode}
              addSubNode={addSubNode}
              addInitNode={addInitNode}
              setSavingTrigger={setSavingTrigger}
              groupNodes={onGroup}
              addTempNote={addTempNote}
            />
          }
        </ReactFlow>
        <InfoModal />
        <InputModal />
        <PageChooserModal />
      </div>
    </PreventSwipeNavigation>

  );
}

export default ({ standAlone, initNodeData }) => {
  return (<ReactFlowProvider>
    <FlowEditor standAlone={standAlone} initNodeData={initNodeData} />
  </ReactFlowProvider>
  )
};
