import { Dialog, DialogTitle, DialogContent, TextField, Button, DialogActions } from '@mui/material';
import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { addGroup, updateRIL } from 'src/actions/ticketAction';
import { ADD_GROUP_DIALOG, RIL_TITLE_DIALOG } from 'src/constants/actionTypes';

const AddGroupModal = ({ orgId }) => {
  const dialogState = useSelector(state => state.uiState.addGroupDialog);
  const loginUser = useSelector(state => state.loginIn.user);

  const dispatch = useDispatch();
  const [name, setName] = React.useState('');
  const [nameValid, setNameValid] = React.useState(true);

  const addNewGroup = () => {
    if(!name || name.length<2) {
      setNameValid(false);
      return;
    }
    dispatch(addGroup({ orgId: loginUser.workingOrgId, name }, handleClose, 'addGroup'))
  }

  const handleClose = () => {
    dispatch({ type: ADD_GROUP_DIALOG, value: { visible: false } });
  }

  return (
    <Dialog
      open={!!dialogState && dialogState.visible}
      onClose={handleClose}
      scroll='paper'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>Add Group</span>
      </DialogTitle>
      <DialogContent dividers={true}>
        <TextField
          required
          id="name"
          label="Name"
          style={{width: '360px'}}
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={!nameValid}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={addNewGroup}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}


export default AddGroupModal;
