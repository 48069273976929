import React, { useEffect, useRef } from 'react';

const ComprehensivePreventNavigation = ({ children }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const preventDefaultForEvent = (e) => {
      if (e.touches && e.touches.length > 1) return; // Allow multi-touch gestures
      e.preventDefault();
    };

    const preventHistoryNavigation = (e) => {
      if (e.type === 'popstate') {
        window.history.pushState(null, '', document.URL);
      }
    };

    // Prevent default for various touch and mouse events
    const eventsToPrevent = [
      'touchstart', 'touchmove', 'touchend', 'touchcancel',
      // 'mousewheel', 'wheel', 'DOMMouseScroll'
    ];

    eventsToPrevent.forEach(eventType => {
      container.addEventListener(eventType, preventDefaultForEvent, { passive: false });
    });

    // Prevent back/forward navigation
    window.addEventListener('popstate', preventHistoryNavigation);

    // Disable context menu
    container.oncontextmenu = (e) => e.preventDefault();

    return () => {
      eventsToPrevent.forEach(eventType => {
        container.removeEventListener(eventType, preventDefaultForEvent);
      });
      window.removeEventListener('popstate', preventHistoryNavigation);
    };
  }, []);

  return (
    <div 
      ref={containerRef}
      style={{
        // overscrollBehavior: 'none',
        touchAction: 'none',
        // WebkitOverflowScrolling: 'touch',
        // overflow: 'auto',
        height: '100%',
        width: '100%'
      }}
    >
      {children}
    </div>
  );
};

export default ComprehensivePreventNavigation;