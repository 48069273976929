export const linkToPage = (doc, params = {}) => {
  let linkTo = `/editor`;

  if (doc) {
    if (doc.type === 'slides') {
      linkTo = `/slidesViewer`;
    } else if (doc.type === 'db') {
      linkTo = `/db`;
    } else if (doc.type === 'flow') {
      linkTo = '/flow';
    }

    linkTo += `?hid=${doc.hid}`
  }

  if (params && Object.keys(params)) {
    linkTo += (doc ? '&' : '?') + Object.keys(params).map(key => `${key}=${params[key]}`).join('&')
  }

  return linkTo;
}