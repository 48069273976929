import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from './store';
import { useShallow } from 'zustand/react/shallow';
import { Notebook } from '@styled-icons/fluentui-system-regular/Notebook';
import { X, Trash2, CheckSquare } from 'lucide-react';
import { useIntl } from 'react-intl';
import { Tooltip } from '@mui/material';
import { node_color_themes } from './ColorMenu';
import { SortableCard } from '../dndlist/SortableCard';
import update from 'immutability-helper';

const clarify_notes_prompt = `As a note organization assistant, transform a set of scattered notes into a coherent, well-structured document. Follow these steps:

1. Review all provided notes.
2. Identify main themes and key concepts.
3. Create a logical structure, grouping related notes.
4. Connect notes into cohesive paragraphs.
5. Add transitions for smooth flow.
6. Ensure comprehensiveness and coherence.
7. Include a title, brief introduction and conclusion if needed.

Generate a well-organized, easily understandable document based on the given notes.

Given scattered notes: {{notes}}`;

const NoteItem = ({ index, note, moveCard, handleCheckboxChange, handleDelete, selectedNotes }) => {
    const noteStyle = useMemo(() => {
        const theme_yellow = node_color_themes.find(theme => theme.id == 'yellow');
        return {
            position: 'relative',
            padding: '6px',
            margin: '4px',
            marginRight: 8,
            marginLeft: 2,
            backgroundColor: theme_yellow.content_bg,
            border: `1px solid ${theme_yellow.border}`,
            boxShadow: `0px 0px 6px #ccc`
        }
    }, [])

    return <SortableCard
        index={index}
        id={note.id}
        moveCard={moveCard}
    >
        <div key={note.id} style={styles.noteItem}>
            <input
                type="checkbox"
                checked={selectedNotes.includes(note.id)}
                onChange={() => handleCheckboxChange(note.id)}
                style={styles.checkbox}
            />
            <div
                className='fill-available'
                style={noteStyle}
                onMouseEnter={(e) => e.currentTarget.querySelector('button').style.opacity = 1}
                onMouseLeave={(e) => e.currentTarget.querySelector('button').style.opacity = 0}
            >
                <p>{note.content}</p>
                <button
                    className='transparent-background'
                    onClick={() => handleDelete(note.id)}
                    style={styles.deleteButton}
                >
                    <X size={16} />
                </button>
            </div>
        </div>
    </SortableCard>
}

const TempNoteBook = ({ showNoteBook, addNote, addInitNode, onclose }) => {
    const intl = useIntl();
    const { tempNotes, setTempNotes, setSavingTrigger } = useStore(
        useShallow((state) => ({
            tempNotes: state.tempNotes,
            setTempNotes: state.setTempNotes,
            setSavingTrigger: state.setSavingTrigger
        }))
    );

    const [items, setItems] = React.useState(tempNotes);
    React.useEffect(() => {
        setItems(tempNotes);
    }, [tempNotes]);

    const [selectedNotes, setSelectedNotes] = useState([]);

    useEffect(() => {
        if(showNoteBook) {
            setSelectedNotes(tempNotes?.map(note => note.id))
        }
    }, [showNoteBook])

    const handleDelete = (id) => {
        setTempNotes(tempNotes.filter((note) => note.id !== id));
        setSavingTrigger(Math.random())
    };

    const handleCheckboxChange = (id) => {
        setSelectedNotes((prev) =>
            prev.includes(id) ? prev.filter((noteId) => noteId !== id) : [...prev, id]
        );
    };

    const handleMultiDelete = () => {
        setTempNotes(tempNotes.filter(note => !selectedNotes.includes(note.id)));
        setSelectedNotes([]);
        setSavingTrigger(Math.random());
    };

    const handleMerge = useCallback(() => {
        if (!selectedNotes?.length) return;
        addNote(null, tempNotes.filter(note => selectedNotes.includes(note.id)).map(note => note.content).join('\n\n'));
    }, [selectedNotes, tempNotes]);

    const handleSummary = useCallback(() => {
        if (!selectedNotes?.length) return;
        const content = JSON.stringify(tempNotes.filter(note => selectedNotes.includes(note.id)).map(note => ({ note: note.content })));
        console.log('Summarizing notes:', content);

        addInitNode({ ai_action: 'query', nodeType: 'aigc', queryType: 'clarify_notes', userInput: clarify_notes_prompt.replace('{{notes}}', content) })
    }, [selectedNotes, tempNotes]);

    const moveCard = React.useCallback((dragIndex, hoverIndex) => {
        setItems((prevCards) => update(prevCards, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, prevCards[dragIndex]],
            ],
        })
        );
    }, []);

    const handleToggleAll = useCallback(() => {
        if (selectedNotes.length === tempNotes.length) {
            // If all notes are selected, deselect all
            setSelectedNotes([]);
        } else {
            // Otherwise, select all
            setSelectedNotes(tempNotes.map(note => note.id));
        }
    }, [selectedNotes, tempNotes]);

    return (
        <div style={styles.container}>
            <Tooltip title={intl.formatMessage({ id: 'dynamic_tips' })} placement="right">
                <div className='fill-available'>
                    <Notebook size={20} />
                    {tempNotes.length > 0 && (
                        <div style={styles.countBadge}>
                            {tempNotes.length}
                        </div>
                    )}
                </div>
            </Tooltip>
            {showNoteBook && (
                <div style={styles.notebookContainer}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <div style={styles.header}>
                        <h2 style={styles.title}>{intl.formatMessage({id: 'temp_notesbook'})}</h2>
                        <button
                            className='transparent-background'
                            style={{
                                padding:4
                            }}
                            onClick={() => onclose()}
                        >
                            <X size={18} />
                        </button>
                    </div>
                    <div
                        style={styles.notesList}
                        onDrop={(event) => {
                            setTempNotes(items);
                        }}
                    >
                        {items?.map((note, index) => (<NoteItem
                            key={note.id + ''}
                            index={index}
                            note={note}
                            selectedNotes={selectedNotes}
                            moveCard={moveCard}
                            handleDelete={handleDelete}
                            handleCheckboxChange={handleCheckboxChange}
                        />))}
                    </div>
                    <div style={styles.actionBar}>
                        <div style={styles.leftActions}>
                        <Tooltip title={intl.formatMessage({id: "delete_selected_clips"})}>
                            <div
                                className='hoverStand'
                                onClick={handleMultiDelete}
                                style={styles.clearButton}
                            >
                                <Trash2 size={18} />
                            </div>
                            </Tooltip>
                            <Tooltip title={intl.formatMessage({id: selectedNotes.length === tempNotes.length ? "deselect_all" : "select_all"})}>
                                <div
                                    className='hoverStand'
                                    onClick={handleToggleAll}
                                    style={styles.toggleAllButton}
                                >
                                    <CheckSquare size={18} />
                                </div>
                            </Tooltip>
                        </div>
                        <div>
                            <button
                                onClick={handleMerge}
                                style={styles.actionButton}
                            >
                                {intl.formatMessage({id: 'merge_notes'})}
                            </button>
                            <button
                                onClick={handleSummary}
                                style={{ ...styles.actionButton, backgroundColor: '#4caf50' }}
                            >
                                {intl.formatMessage({id: 'summarize'})}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        position: 'relative',
        zIndex: 9999
    },
    notebookContainer: {
        position: 'absolute',
        right: '-6px',
        top: 'calc(-100% + 4px)',
        transform: 'translateY(-100%)',
        width: '320px',
        backgroundColor: 'white',
        boxShadow: `0px 0px 6px #ccc`,
        borderRadius: '5px',
        padding: '10px',
        paddingRight: '2px',
        cursor: 'default'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '10px',
        padding: 8,
        paddingTop: 0,
    },

    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    notesList: {
        maxHeight: '320px',
        overflowY: 'auto',
    },
    noteItem: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '6px',
        cursor: 'move'
    },
    checkbox: {
        marginTop: '4px',
        marginRight: '8px',
    },
    deleteButton: {
        position: 'absolute',
        top: '0px',
        right: '0px',
        opacity: 0,
        transition: 'opacity 0.2s',
        border: 'none',
        cursor: 'pointer',
        color: 'gray'
    },
    actionBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px',
        marginRight: 8
    },
    clearButton: {
        border: 'none',
        cursor: 'pointer',
        color: 'gray',
    },
    actionButton: {
        backgroundColor: '#2196f3',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        padding: '4px 6px',
        marginLeft: '8px',
        cursor: 'pointer',
    },
    leftActions: {
        display: 'flex',
        alignItems: 'center',
    },
    toggleAllButton: {
        border: 'none',
        cursor: 'pointer',
        color: 'gray',
        marginLeft: '8px',
    },
    iconContainer: {
        position: 'relative',
        display: 'inline-block',
    },
    countBadge: {
        position: 'absolute',
        top: '-12px',
        right: '-12px',
        backgroundColor: 'dodgerblue',
        color: 'white',
        borderRadius: '50%',
        width: '18px',
        height: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        fontWeight: 'bold',
    },
};

export default TempNoteBook;