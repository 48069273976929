import { CircularProgress, Popover, Tooltip } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import DownloadButton from './Download';
import { FLOW_MODAL, OPERATION_FAILED } from '@/constants/actionTypes';
import { useDispatch } from 'react-redux';
import { uploadFiles } from '@/actions/ticketAction';
import { Save } from '@styled-icons/bootstrap/Save';
import { SlideMultiple } from '@styled-icons/fluentui-system-regular/SlideMultiple';

export const NodeExportMenu = (props) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const { children, node, shareUrl, onClick, form, getNodeContent, generateSlides, iconStyle } = props;
    const { data = {} } = node;
    const { nodeType, title } = data;

    const [saving, set_saving] = useState();


    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);



    const saveToWorkspace = useCallback(() => {
        const markdown = getNodeContent(node, 'content');

        const file = {
            content: markdown,
            name: node.data?.title
        }

        let data = { files: [file], fileType: 'md', space: 'private' };

        set_saving(true);
        dispatch(uploadFiles({
            data,
            enctype: 'multipart'
        }, (doc) => {
            set_saving(false);

            if (!doc) {
                return dispatch({ type: OPERATION_FAILED, message: 'Failed to import page' });
            }

            dispatch({
                type: FLOW_MODAL,
                value: {
                    visible: true,
                    action: 'saved_to_workspace',
                    data: {
                        hid: doc.hid
                    }
                }
            })
        }, () => {
            set_saving(false);
        }, 'editor'));

        handleClose();
    }, [node])

    return (
        <div>
            <Tooltip title={intl.formatMessage({ id: 'node_export' })} placement="top">
                <div
                    className='hoverStand'
                    style={iconStyle}
                    onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        !!onClick && onClick();
                    }}
                >
                    {children}
                </div>
            </Tooltip>
            <Popover
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        // height: 40,
                        padding: 1,
                        justifyContent: 'space-evenly',
                    }}
                >
                    {
                        !['slides', 'image'].includes(nodeType) && !form && (data.content || data.items || data.todos || nodeType == 'group') &&
                        <Tooltip title={intl.formatMessage({ id: 'generate_slides' })} placement="top">
                            <div
                                className='hoverStand'
                                style={iconStyle}
                                onClick={(event) => generateSlides()}
                            >
                                <SlideMultiple color='#555' size={20} />
                            </div>
                        </Tooltip>
                    }
                    {
                        !['group', 'slides', 'image', 'funblocks_doc'].includes(nodeType) && !form && (data.content || data.items || data.todos) &&
                        <Tooltip title={intl.formatMessage({ id: 'save_to_workspace' })} placement="top">
                            <div
                                className='hoverStand'
                                onClick={(event) => saveToWorkspace()}
                                style={iconStyle}
                            >
                                {!saving && <Save color='#555' size={18} />}
                                {saving && <CircularProgress size={16} />}
                            </div>
                        </Tooltip>
                    }
                    {
                        <DownloadButton
                            node={node}
                            title={title || 'ai_flow'}
                            shareUrl={shareUrl}
                            imageType={nodeType == 'group' ? 'group' : 'node'}
                            isGroup={nodeType == 'group'}
                            iconStyle={iconStyle}
                        />
                    }
                </div>
            </Popover>
        </div>
    );
}