
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Tooltip } from "@mui/material";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AI_ASSISTANT_DIALOG, PROMOTE_VIP, PROMPTS_DIALOG, PROMPT_DIALOG, SETTINGS_DIALOG, WORKSPACE_PROMPTS_ACTIONS } from 'src/constants/actionTypes';
import { upsertPrompt } from 'src/actions/ticketAction';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { getStateByUser } from 'src/reducers/listReducer';
import { PromptForm } from './PromptForm';
import { PromptsModalDataHandler } from './PromptsModalDataHandler';
import { useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MEDIA_QUERY } from '../../utils/constants';
import { ADD_FLOW_NODE } from '../../constants/actionTypes';
import { pinPrompt } from '../../actions/ticketAction';

export const PromptModal = ({ }) => {
    const intl = useIntl();
    const dialogState = useSelector(state => state.uiState.promptDialog) || { visible: false };
    const promptsDialogState = useSelector(state => state.uiState.promptsDialog) || {};

    const promote_vip = useSelector(state => state.uiState.promote_vip) || {};
    const operationStatus = useSelector(state => state.operationStatus);
    const loginUser = useSelector(state => state.loginIn && state.loginIn.user);
    const orgs = useSelector(state => getStateByUser(state.org_lists, loginUser));
    const readOnly = ['read', 'share'].includes(dialogState.mode)
    const dispatch = useDispatch();

    const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
    const currentLocation = useLocation();
    const params = new Proxy(new URLSearchParams(currentLocation.search), {
        get: (searchParams, prop) => searchParams.get(prop) || '',
    });
    const docs = useSelector(state => state.docs);

    const [data, setData] = useState({
        name: '',
        prompt: ''
    });
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!dialogState?.visible) {
            return;
        }

        setData(dialogState.data || {
            name: '',
            prompt: ''
        });

        setError(null);
    }, [dialogState])

    useEffect(() => {
        if (!operationStatus?.inProgress) {
            setLoading(false);
        }
    }, [operationStatus])

    const handleClose = () => {
        dispatch({ type: PROMPT_DIALOG, value: { visible: false } });

        if (promote_vip?.visible) {
            dispatch({
                type: PROMOTE_VIP,
                value: {
                    visible: false
                }
            })
        }
    }

    const handleConfirm = () => {
        if (!loginUser?._id) {
            return setError(intl.formatMessage({ id: 'flow_info_login' }));
        }

        if (!data.prompt?.trim()) {
            return setError(intl.formatMessage({ id: 'not_null' }, { field: intl.formatMessage({ id: 'prompt' }) }));
        }
        if (!data.name?.trim()) {
            return setError(intl.formatMessage({ id: 'not_null' }, { field: intl.formatMessage({ id: 'title' }) }));
        }

        setError(null);

        data.args = data.args?.map((arg, index) => {
            arg.name = 'var_' + index;
            return arg;
        }) || [];

        setLoading(true);
        dispatch(upsertPrompt({ data }, (item) => {
            dispatch({
                type: item.visibility === 1 ? WORKSPACE_PROMPTS_ACTIONS.added : WORKSPACE_PROMPTS_ACTIONS.deleted,
                _id: item._id,
                item: Object.assign({}, item),
                params: { data },
                key: loginUser?._id
            })
            handleClose();
        }, 'prompt'));
    }

    const handleRunPrompt = useCallback((item) => {
        handleClose();
        dispatch({ type: SETTINGS_DIALOG, value: { visible: false } });

        const docType = params.hid && docs.byId[params.hid]?.type;

        if (docType === 'doc') {
            dispatch({
                type: AI_ASSISTANT_DIALOG,
                value: {
                    caller: 'plate',
                    visible: true,
                    trigger: 'cmd',
                    hid: params.hid,
                    action: item._id
                }
            });
        } else if (docType === 'flow' || window.location.href.includes('/flow') || window.location.href.includes('/aiflow')) {
            dispatch({
                type: ADD_FLOW_NODE,
                value: {
                    action: item._id,
                    title: item.name
                }
            })
        } else {
            dispatch({
                type: PROMPTS_DIALOG,
                value: {
                    ...promptsDialogState,
                    visible: false,
                    prompt: item,
                    refresher: Math.random()
                }
            })
        }
    }, [params.hid, docs]);

    const togglePinPrompt = useCallback((item) => {

        if (!loginUser?._id) {
            setError(intl.formatMessage({ id: 'flow_info_login' }))
            return;
        }

        handleClose();

        dispatch(pinPrompt({ _id: item._id, pin: true }, null, 'prompts'));
    }, [loginUser]);

    return (
        <Dialog
            open={!!dialogState && dialogState.visible}
            onClose={handleClose}
            scroll='paper'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth={isMobile ? 'sm' : 'md'}
            fullScreen={isMobile}
        >
            {
                readOnly &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: isMobile ? '10px' : '10px 20px',
                    fontWeight: 'bold',
                    fontSize: isMobile ? 16 : 18,
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word'
                }}>
                    {
                        intl.formatMessage({ id: 'prompt_app' })
                    }
                </div>
            }
            {
                !readOnly && dialogState.mode &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: isMobile ? '10px' : '10px 20px',
                    fontWeight: 'bold',
                    fontSize: isMobile ? 16 : 18,
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word'
                }}>
                    {
                        intl.formatMessage({ id: dialogState.mode }) + (dialogState.data?.name ? (': ' + dialogState.data?.name) : '')
                    }
                </div>
            }
            <DialogContent
                dividers={true}
                style={{
                    width: isMobile ? '100%' : '720px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    padding: 0,
                    backgroundColor: 'white',
                    overflowX: 'hidden'
                }}
            >
                <PromptForm
                    modalVisibility={dialogState?.visible}
                    readOnly={readOnly}
                    trigger={dialogState.mode == 'share' && 'shared'}
                    data={data}
                    setData={setData}
                    error={error}
                />
            </DialogContent>
            <DialogActions>
                <div style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    padding: isMobile ? '10px' : '0 16px',
                    width: isMobile ? '100%' : 'auto',
                    rowGap: isMobile ? '10px' : '0',
                    columnGap: isMobile ? '0' : '20px'
                }}>
                    <Button
                        fullWidth={isMobile}
                        variant='text'
                        onClick={handleClose}
                    >
                        {intl.formatMessage({ id: readOnly ? 'close' : 'cancel' })}
                    </Button>
                    {
                        !readOnly &&
                        <LoadingButton
                            fullWidth={isMobile}
                            disabled={loading}
                            loading={loading}
                            variant='contained'
                            onClick={handleConfirm}
                        >
                            {intl.formatMessage({ id: 'confirm' })}
                        </LoadingButton>
                    }
                    {
                        readOnly &&
                        <Tooltip title={intl.formatMessage({ id: 'prompt_pin_tooltip' })} placement="top">
                            <LoadingButton
                                fullWidth={isMobile}
                                disabled={loading}
                                loading={loading}
                                variant='contained'
                                onClick={() => togglePinPrompt(data)}
                            >
                                {intl.formatMessage({ id: 'pin' })}
                            </LoadingButton>
                        </Tooltip>
                    }
                    {
                        readOnly &&
                        (params.hid && docs.byId[params.hid]?.type == 'flow' || window.location.href.includes('/flow') || window.location.href.includes('/aiflow')) &&
                        <Button
                            fullWidth={isMobile}
                            variant='contained'
                            color="success"
                            onClick={() => handleRunPrompt(data)}
                        >
                            {intl.formatMessage({ id: 'run_prompt' })}
                        </Button>
                    }
                </div>
            </DialogActions>
            <PromptsModalDataHandler />
        </Dialog>
    );
}