import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AI_API_MODEL, LLM_API_KEYS, LLM_API_KEY_MODAL } from "../../constants/actionTypes";
import { useDispatch, useSelector } from 'react-redux';
import { Selector } from '../common/Selector';
import { EditableMenuItemActions } from './EditableMenuItemActions';
import { useIntl } from 'react-intl';

const ModelSelector = ({ inputStyle, dropdownIconSize, onSelect, value }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const appConfigs = useSelector(state => state.uiState.app_config);
    const llm_api_keys = useSelector(state => state.uiState.llm_api_keys);
    const selected_ai_api_model = useSelector(state => state.uiState.ai_api_model);
    const [ai_api_model, set_ai_api_model] = useState();
    const [models, set_models] = useState();

    useEffect(() => {
        let model = !!selected_ai_api_model && (llm_api_keys?.find(item => item.id === selected_ai_api_model) || appConfigs?.ai_api_models?.find(item => item.value === selected_ai_api_model))

        if (!model) {
            dispatch({
                type: AI_API_MODEL,
                value: appConfigs?.ai_api_models?.find(item => item.level == 't2')?.value
            })
        } else {
            set_ai_api_model(selected_ai_api_model)
        }
    }, [appConfigs?.ai_api_models, selected_ai_api_model, llm_api_keys])

    useEffect(() => {
        if (!appConfigs?.ai_api_models) return;

        let ai_models = [...appConfigs.ai_api_models];

        ai_models.push({
            type: 'title',
            label: intl.formatMessage({ id: 'self_owned_ai_api' })
        })

        if (llm_api_keys) {
            ai_models = ai_models.concat(llm_api_keys.map(item => ({
                label: item.name,
                value: item.id,
                rightElement: <EditableMenuItemActions
                    item={item}
                    onDelete={(item) => {
                        let keys = [...llm_api_keys];

                        dispatch({
                            type: LLM_API_KEYS,
                            value: keys.filter(it => it.id != item.id)
                        })
                    }}
                    onEdit={(item) => {
                        dispatch({
                            type: LLM_API_KEY_MODAL,
                            value: {
                                visible: true,
                                item
                            }
                        })
                    }}
                />
            })));
        }

        ai_models = ai_models.concat({
            label: `+ ${intl.formatMessage({ id: 'add_private_llm_key' })}`,
            onClick: () => {
                dispatch({
                    type: LLM_API_KEY_MODAL,
                    value: {
                        visible: true
                    }
                })
            }
        }).map(model => ({
            ...model,
            tips: model.desc
        }));

        set_models(ai_models);
    }, [appConfigs?.ai_api_models, llm_api_keys, intl])

    return <Selector
        options={models}
        value={!!onSelect && value || ai_api_model}
        tooltip={intl.formatMessage({ id: 'set_ai_model' })}
        onChange={(value) => {
            if (!value) return;

            !!onSelect && onSelect(value);

            !onSelect && dispatch({
                type: AI_API_MODEL,
                value
            });
        }}
        inputStyle={inputStyle}
        itemStyle={{
            paddingLeft: 12,
            minWidth: 240
        }}
        dropdownIconSize={dropdownIconSize}
    />
}

export default ModelSelector;