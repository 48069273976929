import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { List, ListItem, ListItemText, Container } from '@mui/material';
import UsersTable from './UsersTable';
import { getStateByUser } from 'src/reducers/listReducer';
import { useDispatch, useSelector } from 'react-redux';
import InviteToOrg from './InviteToOrg';
import GroupsTable from './GroupsTable';
import { fetchGroups } from 'src/actions/ticketAction';
import { useIntl } from 'react-intl';
import { TabPanel, a11yProps } from '../tab/TabPanel';

const Members = ({ }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [tab, setTab] = React.useState(0);

    const loginUser = useSelector(state => state.loginIn.user);
    const orgs = useSelector(state => getStateByUser(state.org_lists, loginUser));
    const workingSpace = orgs.items.find(org => org._id === loginUser.workingOrgId) || { users: [] };
    const isAdmin = !!(workingSpace.users && workingSpace.users.find(user => !!user.user && user.user._id == loginUser._id && user.role === 'admin'));

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const members = workingSpace.users.filter(user => !!user.user && user.role === 'admin' || user.role === 'member');
    const guests = workingSpace.users.filter(user => !!user.user && user.role === 'guest');

    React.useEffect(() => {
        dispatch(fetchGroups({ orgId: workingSpace._id }));
    }, [workingSpace._id]);

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={intl.formatMessage({ id: 'member' })} {...a11yProps(0)} />
                    <Tab label={intl.formatMessage({ id: 'group' })} {...a11yProps(1)} />
                    <Tab label={intl.formatMessage({ id: 'guest' })} {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
                <div style={{ paddingTop: '10px' }}>
                    <UsersTable isAdmin={isAdmin} users={members} descText={intl.formatMessage({ id: 'member_tooltip' })} type={'members'} />
                </div>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <div style={{ paddingTop: '10px' }}>
                    <GroupsTable isAdmin={isAdmin} descText={intl.formatMessage({ id: 'group_tooltip' })} />
                </div>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <div style={{ paddingTop: '10px' }}>
                    <UsersTable isAdmin={isAdmin} users={guests} descText={intl.formatMessage({ id: 'guest_tooltip' })} type={'guests'} />
                </div>
            </TabPanel>

            <InviteToOrg workingSpace={workingSpace} />
        </Box>
    );
}

export default Members;