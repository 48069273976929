import React, { useEffect } from 'react';
import { Button, Divider, MenuItem, Select, Switch, TextField } from "@mui/material";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CONFIRM_DIALOG, PROMPT_DIALOG } from "src/constants/actionTypes";
import { deletePrompt, fetchPrompts, fetchPromptsPinned, fetchPromptsWorkspace } from "src/actions/ticketAction";
import { getStateByUser } from "src/reducers/listReducer";
import PromptCard from "./PromptCard";

const PromptsMine = ({ container }) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const loginUser = useSelector(state => state.loginIn && state.loginIn.user);
    const prompt_lists = useSelector(state => getStateByUser(state.prompt_lists, loginUser));
    const pinned_prompt_lists = useSelector(state => getStateByUser(state.pinned_prompt_lists, loginUser));
    const workspace_prompt_lists = useSelector(state => getStateByUser(state.workspace_prompt_lists, loginUser));

    useEffect(() => {
        dispatch(fetchPrompts({}));
        dispatch(fetchPromptsWorkspace({}));
        dispatch(fetchPromptsPinned({}));
    }, []);

    const showPromptModal = (item) => {
        dispatch({
            type: PROMPT_DIALOG,
            value: {
                visible: true,
                data: item,
                mode: 'new'
            }
        })
    };

    return (
        <div className="prompts-container">
            {container !== 'drafter_panel' && (
                <div className="prompts-desc">
                    {intl.formatMessage({ id: "prompts_mine_desc" })}
                </div>
            )}

            <div className="prompts-section">
                <h2 className="prompts-title">
                    {intl.formatMessage({ id: 'prompts_developed' })}
                </h2>
                {container !== 'drafter_panel' && (
                    <div
                        className="add-prompt-button"
                    >
                        <Button
                            variant="contained"
                            onClick={() => showPromptModal()}
                        >
                            {intl.formatMessage({ id: "add_prompt" })}
                        </Button>
                    </div>
                )}

                <div className="prompts-grid">
                    {prompt_lists?.items?.map((item, index) => (
                        <PromptCard key={index} item={item} board={'mine'} container={container} />
                    ))}
                </div>
            </div>

            <Divider />

            <div className="prompts-section">
                <h2 className="prompts-title">
                    {intl.formatMessage({ id: 'prompts_pinned' })}
                </h2>
                <div className="prompts-desc">
                    {intl.formatMessage({ id: 'prompts_pinned_desc' })}
                </div>
                {pinned_prompt_lists?.items?.length > 0 && (
                    <div className="prompts-grid">
                        {pinned_prompt_lists.items.map((item, index) => (
                            <PromptCard key={index} item={item} board={'pinned'} container={container} />
                        ))}
                    </div>
                )}
            </div>

            {workspace_prompt_lists?.items?.filter(item => container !== 'drafter_panel' || item.args?.length > 0).length > 0 && (
                <>
                    <Divider />
                    <div className="prompts-section">
                        <h2 className="prompts-title">
                            {intl.formatMessage({ id: 'prompts_workspace' })}
                        </h2>
                        <div className="prompts-grid">
                            {workspace_prompt_lists.items.map((item, index) => (
                                <PromptCard key={index} item={item} board={'workspace'} container={container} />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default PromptsMine;