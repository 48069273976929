import { PAGE_HISTORY_ACTIONS } from '../constants/actionTypes'

export function pages(state=[], action) {
    let oldState = [...state];
    switch (action.type) {
        case PAGE_HISTORY_ACTIONS.added:
            oldState = oldState.filter(x => x.hid!==action.item.hid);
            oldState.push(action.item);
            return oldState;
       
        default:
            return state;
    }
}


