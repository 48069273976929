import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

const LoadingScreen = ({ style }) => {
  return (
    <div className='spinner' style={style}>
      <CircularProgress />
    </div>
  )
}

export default React.memo(LoadingScreen)
