import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CONFIRM_DIALOG } from 'src/constants/actionTypes';
import { useEffect, useRef } from 'react';

const ConfirmDialog = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const dialogState = useSelector(state => state.uiState.confirmDialog) || {};

  const cancel_btn_ref = useRef();
  const confirm_btn_ref = useRef();

  useEffect(() => {
    cancel_btn_ref?.current?.focus(); // 设置左按钮初始焦点
  }, [dialogState, cancel_btn_ref]);

  const handleClose = () => {
    dispatch({ type: CONFIRM_DIALOG, value: { visible: false } });
  }

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {
      event.preventDefault();
      cancel_btn_ref.current.focus();
    } else if (event.key === 'ArrowRight') {
      event.preventDefault();
      confirm_btn_ref.current.focus();
    }
  };

  return (
    <Dialog
      open={dialogState.visible}
      onClose={handleClose}
      scroll='paper'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"

      onKeyDown={handleKeyDown} 
      tabIndex={0}
    >
      <DialogTitle id="scroll-dialog-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogContentText id="alert-dialog-description">
          {dialogState.title}
          {
            !dialogState.title && intl.formatMessage({ id: 'confirm_title' })
          }
        </DialogContentText>
      </DialogTitle>
      <DialogContent style={{ width: '400px' }} dividers={true}>
        {dialogState.content}
        {
          !dialogState.content && intl.formatMessage({ id: 'confirm_delete_content' })
        }
      </DialogContent>
      <DialogActions>
        <Button ref={cancel_btn_ref} onClick={handleClose}>{intl.formatMessage({ id: 'cancel' })}</Button>
        <Button ref={confirm_btn_ref} onClick={() => {
          dialogState.handleConfirm();
          handleClose();
        }}>{intl.formatMessage({ id: 'confirm' })}</Button>
      </DialogActions>
    </Dialog>
  );
};


export default ConfirmDialog;
