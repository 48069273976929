import { upsertDoc, updateDbData, newDbData, deleteDbData } from "src/actions/ticketAction";
import { VIEW_SORT_ACTIONS } from "src/constants/actionTypes";
import { DB_PROPERTY_TYPES, OPTION_BG_COLORS } from "src/constants/constants";
import { FILTER_RULE_OP_TYPES } from 'src/constants/constants';
const equal = require('fast-deep-equal/react');

export const updateDocProperties = (dispatch, doc, space) => {
    dispatch(upsertDoc({ data: { doc, space } }, (updatedDoc) => {
        console.log('doc saved');
    }, false, 'editor'));
}

export const insertColumn = (dispatch, intl, hid, meta, index, property) => {
    const properties = meta.properties;

    if (!property || !property.name) {
        const name = 'f' + (properties ? properties.length : 0) + '' + Math.floor(Math.random() * 100);
        const propertyPrefix = intl.formatMessage({ id: 'propertyPrefix' }) + ' ';

        const propertySuffix = !properties ? 1 : (properties.map(property => {
            if (property.label.startsWith(propertyPrefix)) {
                const suffix = property.label.substring(propertyPrefix.length);
                if (!isNaN(Number(suffix))) {
                    return Number(suffix);
                }

                return 0;
            }
            return 0;
        }).reduce((acc, curr) => Math.max(acc, curr), 0) + 1);

        property = {
            name,
            type: 'Text',
            label: propertyPrefix + propertySuffix,
            ...property,
        }
    }


    const newProperties = [...properties];
    newProperties.splice(index, 0, property);

    updateDocProperties(dispatch, {
        hid: hid,
        meta: {
            ...meta,
            properties: newProperties,
        }
    })
}

export const deleteColumn = (dispatch, hid, meta, index) => {
    const newProperties = [...meta.properties];
    newProperties.splice(index, 1);

    updateDocProperties(dispatch, {
        hid: hid,
        meta: {
            ...meta,
            properties: newProperties,
        }
    })
}

export const setColumnType = (dispatch, hid, meta, type, index) => {
    const newProperties = [...meta.properties];

    newProperties[index].type = type;

    updateDocProperties(dispatch, {
        hid: hid,
        meta: {
            ...meta,
            properties: newProperties,
        }
    })
}

export const updateDBProperty = (dispatch, hid, meta, property) => {
    const oldProperty = meta.properties.find(p => p.name === property.name);

    if (!equal(property, oldProperty)) {
        updateDocProperties(dispatch, {
            hid,
            meta: {
                ...meta,
                properties: meta.properties.map(p => {
                    if (p.name === property.name) {
                        return property;
                    }
                    return p;
                })
            }
        });
    }
}

export const updateDBProperties = (dispatch, hid, meta, properties) => {
    updateDocProperties(dispatch, {
        hid: hid,
        meta: {
            ...meta,
            properties,
        }
    })
}

export const initNewOption = (label, options) => {
    return {
        value: 'o_' + options.length + Math.floor(Math.random() * 1000),
        label: label,
        bgColor: OPTION_BG_COLORS[options.length % OPTION_BG_COLORS.length].value
    }
}

export const addOptionToProperty = (label, property) => {
    let options = property.options || [];
    if (!options.find(o => o.label === label)) {
        options.push(initNewOption(label, options));

        property.options = options;

        return true;
    }

    return false;
};


export const getViewProperties = (dbProperties, view) => {
    let viewProperties = (view.properties || []).map(p => {
        let origin = dbProperties.find(dp => dp.name === p.name);
        if (origin) {
            return {
                ...origin,
                hide: p.hide
            };
        }
    }).filter(p => p);

    dbProperties.forEach(p => {
        if (!viewProperties.find(vp => vp.name === p.name)) {
            viewProperties.push({ ...p, hide: !view.showNewProperties });
        }
    });

    return viewProperties;
}

export const updateRowCellData = (dispatch, hid, rowData, columnId, value) => {
    if ((!value && (!rowData.data || !rowData.data[columnId])) || rowData.data && (value == rowData.data[columnId])) {
        return;
    }

    const newData = {
        ...rowData.data,
        [columnId]: value
    };

    if (value === null) {
        delete newData[columnId];
    }

    updateRowData(dispatch, hid, {
        _id: rowData._id,
        data: newData
    });
}

export const updateRowData = (dispatch, hid, data) => {
    dispatch(updateDbData({
        hid,
        pageBy: 'orderFactor',
        data
    }, () => { }, 'update'));
}

export const addDBRow = (dispatch, hid, dbdata, data, index) => {
    let orderFactor = 0;
    if (dbdata && dbdata.length > 0) {
        if (index === 0) {
            orderFactor = dbdata[0].orderFactor + 10 * Math.random();
        } else if (index > 0 && index < dbdata.length) {
            orderFactor = (dbdata[index - 1].orderFactor + dbdata[index].orderFactor) / 2;
        } else {
            orderFactor = dbdata[dbdata.length - 1].orderFactor - 10 * Math.random();
        }
    }

    dispatch(newDbData({
        hid,
        pageBy: 'orderFactor',
        data: {
            hid,
            orderFactor: orderFactor,
            data
        }
    }, () => { }, 'new'));
}

export const deleteDBRow = (dispatch, hid, dataId) => {
    dispatch(deleteDbData({
        hid,
        pageBy: 'orderFactor',
        _id: dataId,
    }, () => { }, 'delete'));
}

export const onInsertRow = (hid, view, groupByProperty, dbdata, laneId, dataId, rowsCount, sortSettingsState, dispatch, intl) => {
    if (sortSettingsState && sortSettingsState.sorts && sortSettingsState.sorts.length > 0) {
        confirmToRemoveSortSettings(dispatch, view._id, intl);
        return;
    }

    const rowIndex = dataId ? dbdata.findIndex(d => d._id === dataId) : (rowsCount - 1);
    addDBRow(dispatch, hid, dbdata,
        view.groupBy ? {
            [view.groupBy]: laneId === nonTagId ? '' : (groupByProperty.type === 'Select' ? laneId : [laneId]),
        } : null,
        rowIndex + 1);
}

export const onDuplicateRow = (hid, view, dbdata, dataId, sortSettingsState, dispatch, intl) => {
    if (sortSettingsState && sortSettingsState.sorts && sortSettingsState.sorts.length > 0) {
        confirmToRemoveSortSettings(dispatch, view._id, intl);
        return;
    }

    const rowIndex = dbdata.findIndex(d => d._id === dataId);
    if (rowIndex < 0 || rowIndex > dbdata.length - 1) {
        return;
    }

    addDBRow(dispatch, hid, dbdata, dbdata[rowIndex].data, rowIndex);
}

export const onDeleteRow = (hid, dataId, dispatch) => {
    deleteDBRow(dispatch, hid, dataId);
}

export const nonTagId = 'noTag';

export const getGroupByOptions = (intl, doc, view, dbdata, nonTagFirst) => {
    const groupByProperty = doc.meta.properties.find(p => p.name == view.groupBy);
    if (!groupByProperty) {
        return [];
    }

    let options = view.groupByOptions || [];

    let allOptions = [];
    if (groupByProperty.type === 'Select' || groupByProperty.type === 'MultiSelect') {
        allOptions = groupByProperty.options || [];
    } else if (groupByProperty.type === 'Person') {
        allOptions = [].concat(...dbdata.map(d => d.data && d.data[view.groupBy]).filter(d => d));
        allOptions = allOptions.filter((v, i, a) => a.indexOf(v) === i);
        allOptions = allOptions.map(o => ({
            value: o,
            label: o
        }));

        allOptions.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            } else if (a.label > b.label) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    options = options.filter(o => o.value == nonTagId || allOptions.find(op => op.value == o.value));
    allOptions.forEach(o => {
        if (!options.find(o2 => o2.value == o.value)) {
            options.push({ ...o, hide: false });
        }
    });

    if (!options.find(o => o.value == nonTagId)) {
        let nonTaggedOption = {
            value: nonTagId,
            label: intl.formatMessage({ id: 'notag_label' }, { label: groupByProperty.label }),
            hide: false
        };
        nonTagFirst ? options.unshift(nonTaggedOption) : options.push(nonTaggedOption);
    }

    return options;
}

export const removeSortSettings = (dispatch, viewId) => {
    dispatch({
        type: VIEW_SORT_ACTIONS.updated,
        item: {
            viewId,
            sorts: [],
            visible: false,
        }
    })
}

export const confirmToRemoveSortSettings = (dispatch, viewId, intl) => {
    dispatch({
        type: 'CONFIRM_DIALOG', value: {
            visible: true,
            handleConfirm: () => {
                removeSortSettings(dispatch, viewId);
            },
            content: intl.formatMessage({ id: 'operation_need_to_delete_sort' })
        }
    });

}


export const filterDbData = (dbdata, group, viewProperties) => {
    const { filters } = group;
    if (!filters || !filters.length) {
        return dbdata;
    }

    dbdata = dbdata.map(d => {
        let data = d.data || {};

        viewProperties.forEach(p => {
            if (p.type === 'Number') {
                data[p.name] = Number(data[p.name]);
                if (isNaN(data[p.name])) {
                    data[p.name] = null;
                }
            } else if (p.type === 'Date') {
                if (p.hasEndDate) {
                    let value = data[p.name];
                    if (value && Array.isArray(value)) {
                        data[p.name] = [new Date(value[0]), new Date(value[1])];
                    } else {
                        data[p.name] = null;
                    }
                } else {
                    data[p.name] = new Date(data[p.name]);
                    if (data[p.name] == "Invalid Date" || isNaN(data[p.name])) {
                        data[p.name] = null;
                    } else {
                        data[p.name] = data[p.name];
                    }
                }
            }
        });

        return d;
    });

    dbdata = filters.reduce((acc, filter) => {
        const toFilterData = group.op === 'and' ? acc : dbdata;
        const { property, value, type, op } = filter;

        let filteredData;
        if (type === 'group') {
            filteredData = filterDbData(toFilterData, filter, viewProperties);
        } else {
            if (!property) {
                console.log('property is required');
                return acc;
            }
            if ((value === null || value === undefined || value === '') && op != 'is_empty' && op != 'is_not_empty') {
                console.log('value is required');
                return acc;
            }

            let propertyData = viewProperties.find(p => p.name === property);
            if (!propertyData) {
                console.log('property not found');
                return acc;
            }

            if (op != 'is_empty' && op != 'is_not_empty') {
                const validRuleOps = FILTER_RULE_OP_TYPES.find(opType => opType.toProperties.includes(propertyData.type)).ruleOps;
                if (!validRuleOps.find(ruleOp => ruleOp.value === op)) {
                    console.log('op not found for property type', validRuleOps, op);
                    return acc;
                }
            }

            filteredData = toFilterData.filter(row => {
                row = row || {};
                const fieldValue = row.data && row.data[property];

                if (fieldValue === null || fieldValue === undefined || fieldValue === '') {
                    if (op === 'is_empty' || op === 'ne' || op === 'not_contains') {
                        return true;
                    }

                    return false;

                }

                if (op === 'is_empty') {
                    return false;
                }

                if (op === 'is_not_empty') {
                    return true;
                }

                if (op === 'eq') {
                    return fieldValue == value;
                }

                if (op === 'ne') {
                    return fieldValue != value;
                }

                if (op === 'lt') {
                    if (propertyData.type === 'Date' && propertyData.hasEndDate) {
                        return fieldValue[1] < value;
                    }

                    return fieldValue < value;
                }

                if (op === 'le') {
                    if (propertyData.type === 'Date' && propertyData.hasEndDate) {
                        return fieldValue[1] <= value;
                    }

                    return fieldValue <= value;
                }

                if (op === 'gt') {
                    if (propertyData.type === 'Date' && propertyData.hasEndDate) {
                        return fieldValue[0] > value;
                    }

                    return fieldValue > value;
                }

                if (op === 'ge') {
                    if (propertyData.type === 'Date' && propertyData.hasEndDate) {
                        return fieldValue[0] >= value;
                    }

                    return fieldValue >= value;
                }

                if (op === 'starts_with') {
                    return fieldValue.startsWith(value);
                }

                if (op === 'ends_with') {
                    return fieldValue.endsWith(value);
                }

                if (op === 'contains') {
                    if (propertyData.type === 'MultiSelect' || propertyData.type === 'Person') {
                        return value.every(v => fieldValue.includes(v));
                    } else {
                        return fieldValue.includes(value);
                    }
                }

                if (op === 'not_contains') {
                    if (propertyData.type === 'MultiSelect' || propertyData.type === 'Person') {
                        return value.every(v => !fieldValue.includes(v));
                    } else {
                        return !fieldValue.includes(value);
                    }
                }

                if (op === 'between') {
                    if (Array.isArray(value)) {
                        if (propertyData.type === 'Date' && propertyData.hasEndDate && Array.isArray(fieldValue)) {
                            return fieldValue[0] >= value[0] && fieldValue[1] <= value[1];
                        } else {
                            return fieldValue >= value[0] && fieldValue <= value[1];
                        }
                    }
                }

                return true;
            });
        }

        // console.log('filteredData.....................', filteredData);

        if (group.op === 'and') {
            return filteredData;
        } else {
            return acc.concat(filteredData);
        }
    }, group.op === 'or' ? [] : dbdata);


    // console.log('reduced data.....................', dbdata);

    let newDbData = [];
    dbdata.forEach(row => {
        if (!newDbData.find(r => r._id === row._id)) {
            newDbData.push(row);
        }
    });

    // console.log('newDbData.....................', newDbData);
    return newDbData;
}

export const fillAdvancedDbData = (dbdata, viewProperties) => {
    const advancedTypes = DB_PROPERTY_TYPES.filter(t => !!t.advancedType).map(t => t.advancedType);
    const advancedProperties = viewProperties.filter(p => advancedTypes.includes(p.advancedType));
    if (!advancedProperties || !advancedProperties.length) {
        return dbdata;
    }

    return dbdata.map(item => {
        const newItem = {
            ...item,
            data: {
                ...(item.data || {}),
            }
        };
        advancedProperties.forEach(property => {
            if (property.type === 'Person') {
                newItem.data[property.name] = item[property.advancedType] ? [item[property.advancedType]] : null;
            } else if (property.advancedType === 'tags') {
                const tags = item[property.advancedType];
                newItem.data[property.name] = tags && tags.map(t => {
                    return property.options.find(o => o.label === t)?.value;
                }).filter(t => t);
            } else {
                newItem.data[property.name] = item[property.advancedType];
            }
        });

        return newItem;
    });
}

export const fillDbData = (dbdata, viewProperties, members, view, intl) => {
    return dbdata.map(item => {
        const newItem = {
            ...item,
            data: {
                ...(item.data || {}),
            }
        };
        viewProperties.forEach(property => {
            if (property.type === 'Number') {
                newItem.data[property.name] = Number(newItem.data[property.name]);
            } else if (property.type === 'Date') {
                let dateData;

                if (!property.hasEndDate) {
                    dateData = new Date(newItem.data[property.name]);
                } else {
                    dateData = newItem.data[property.name] ? new Date(newItem.data[property.name][0]) : null;
                }

                if (!dateData || dateData == "Invalid Date" || isNaN(dateData)) {
                    newItem.data[property.name] = '';
                } else {
                    newItem.data[property.name] = dateData.getTime();
                }
            } else if (property.type === 'Select') {
                let option = property.options?.find(option => option.value === newItem.data[property.name]);
                newItem.data[property.name] = option && option.label || '';
            } else if (property.type === 'MultiSelect') {
                let values = newItem.data[property.name] || [];
                if(Array.isArray(values)) {
                    newItem.data[property.name] = values.map(value => {
                        let option = property.options?.find(option => option.value === value);
                        return option && option.label || '';
                    });
                }
            } else if (property.type === 'Person') {
                let values = newItem.data[property.name] || [];
                newItem.data[property.name] = values.map(value => {
                    let person = members.find(m => m._id === value);
                    return person?.nickname || person?.username || '';
                });
            } else {
                newItem.data[property.name] = newItem.data[property.name] || '';
            }
        });
        return newItem;
    });
}

export const sortDbData = (dbdata, filledDbData, sortSettingsState, docProperties, view_data_orders) => {
    if (sortSettingsState.sorts && sortSettingsState.sorts.length > 0) {
        let sorted = filledDbData.sort((a, b) => {
            for (let sort of sortSettingsState.sorts) {
                const property = docProperties.find(p => p.name === sort.name);
                if (!property) {
                    continue;
                }

                const aValue = ((property.type === 'MultiSelect' || property.type === 'Person')
                    ? a.data[sort.name].join(', ')
                    : a.data[sort.name]) || '';
                const bValue = ((property.type === 'MultiSelect' || property.type === 'Person')
                    ? b.data[sort.name].join(', ')
                    : b.data[sort.name]) || '';

                if (property.type === 'Date' || property.type === 'Number') {
                    if (sort.order === 'asc') {
                        if (aValue > bValue) {
                            return 1;
                        } else if (aValue < bValue) {
                            return -1;
                        }
                    } else {
                        if (aValue > bValue) {
                            return -1;
                        } else if (aValue < bValue) {
                            return 1;
                        }
                    }
                } else {
                    if (sort.order === 'asc') {
                        let r = aValue.localeCompare(bValue);
                        if (r !== 0) {
                            return r;
                        }
                    } else {
                        let r = bValue.localeCompare(aValue);
                        if (r !== 0) {
                            return r;
                        }
                    }
                }
            }
        });

        dbdata = sorted.map(item => {
            return dbdata.find(dbItem => dbItem._id === item._id);
        });
    } else {
        if (!view_data_orders || view_data_orders.length === 0) {
            return dbdata;
        }

        return dbdata.map(d => {
            const dataOrder = view_data_orders.find(vdo => vdo.dataId === d._id);
            if (!dataOrder) {
                return d;
            }

            return {
                ...d,
                orderFactor: dataOrder.orderFactor
            };
        }).sort((a, b) => b.orderFactor - a.orderFactor);
    }

    return dbdata;
}