const urlRegex = require('url-regex');
var _ = require('underscore');

export const validateEmail = (text) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(text);
}

export const validatePhone = (text) => {
    var re = /^\d{6,11}$/;

    return re.test(text);
}

export const validateUrl = (text) => {
    var re = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

    return re.test(text);
}

export const extractUrl = (text) => {
    const urls = text.match(urlRegex());

    if (!urls || !urls[0] || !urls[0].trim()) {
      return;
    }
    return urls[0].trim();
}

export const getHostFromUrl = (url) => {
    if (!url || typeof url != 'string') {
        return '';
    }
    if (url.indexOf('https://') < 0 && url.indexOf('http://') < 0) {
        return '';
    }
    url = url.replace(/https?:\/\//g, "");

    //域名的规范，由26位英文字母，数字或-构成，且必须以字母或数字开头
    var reg = /^[a-z0-9][a-z0-9-]*$/;
    var urlSplit = url.split('.');
    var reservedArray = ['www', 'html', 'com', 'cn', 'org', 'net', 'htm', 'shtml'];

    return _.compact(_.map(urlSplit, function (text) {
        if (reg.test(text) && !_.contains(reservedArray, text)) {
            return text;
        }
        return null;
    })).join('.');
}