import moment from "moment";

export const formatFromNow = (time, format = 'YYYY-MM-DD HH:mm:ss') => {
  let date = new Date(time);
  let timeString = moment(date).format(format);
  if (moment(date).diff(moment(new Date()), 'days') > -3) {
    timeString = moment(date).fromNow();
  }

  return timeString;
}

export const formatDate = (time, format) => {
  // let date = new Date(time);
  let timeString = moment(time).format(format || "ll");

  return timeString;
}

export const formatDateTime = (time, format) => {
  let date = new Date(time);
  let timeString = moment(date).format(format || "lll");

  return timeString;
}