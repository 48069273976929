import { Button, Divider } from "@mui/material";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { SETTINGS_DIALOG } from "src/constants/actionTypes";

const BottomActionBar = ({ handleConfirm }) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const handleClose = () => {
        dispatch({ type: SETTINGS_DIALOG, value: { visible: false } });
    };
    return (
        <div style={{ position: 'absolute', bottom: 0, right: 0, display: 'flex', flexDirection: 'row', columnGap: 16,  paddingRight: 30, paddingBottom: 8 }}>
            <Button onClick={handleClose}>{intl.formatMessage({ id: 'cancel' })}</Button>
            <Button variant="contained" onClick={() => {
                !!handleConfirm && handleConfirm();
            }}>
                {intl.formatMessage({ id: 'update' })}
            </Button>
        </div>
    );
}

export default BottomActionBar;