import mermaid from "mermaid";
import Modal from '@mui/material/Modal'
import { useEffect, useRef } from "react";

mermaid.initialize({
    startOnLoad: true,
    theme: 'default',
    securityLevel: 'loose',
});

const MermaidRenderer = ({ source, id }) => {
    const mermaidRef = useRef(null);

    useEffect(() => {
        const initializeMermaid = async () => {
            if (mermaidRef.current) {
                mermaidRef.current.innerHTML = source;
                const { svg, bindFunctions } = await mermaid.render(`mermaid-diagram-${id}`, source);
                if (mermaidRef.current) {
                    mermaidRef.current.innerHTML = svg;
                    bindFunctions?.(mermaidRef.current);
                }
            }
        };

        initializeMermaid();

        // Clean up mermaid instance when unmounting; doing nothing at the momemt
        return () => {

        };
    }, [source]);

    return (
        <div 
            className="fill-available"
            style={{ 
                padding: 10,
                height: '-webkit-fill-available',
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }} 
            id={id} 
            ref={mermaidRef}
        ></div>
    );
};

export default MermaidRenderer;