import { Box, Dialog, DialogTitle, DialogContent, TextField, Button, DialogActions, Chip, Divider, ListItem, ListItemText } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { addUsersToGroup } from 'src/actions/ticketAction';
import { ADD_USER_TO_GROUP_DIALOG } from 'src/constants/actionTypes';
import { getStateByUser } from 'src/reducers/listReducer';

const AddUserToGroupModal = ({ }) => {
  const dialogState = useSelector(state => state.uiState.addUserToGroupDialog);
  const group = dialogState && dialogState.group;
  const dispatch = useDispatch();
  const intl = useIntl();

  const loginUser = useSelector(state => state.loginIn.user);
  const orgs = useSelector(state => getStateByUser(state.org_lists, loginUser));
  const workingSpace = orgs.items.find(org => org._id === loginUser.workingOrgId) || {};

  const [selectedItems, setSelectedItems] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [itemTargeted, setItemTargeted] = React.useState(0);
  const [selectedValid, setSelectedValid] = React.useState(true);
  const [hovered, setHovered] = React.useState();

  React.useEffect(() => {
    if (dialogState && !dialogState.visible) {
      setSelectedItems([]);
    }
  }, [dialogState]);

  const handleDelete = (chipToDelete) => () => {
    setSelectedItems(selectedItems.filter(chip => chip._id !== chipToDelete._id));
  }

  const handleClose = () => {
    dispatch({ type: ADD_USER_TO_GROUP_DIALOG, value: { visible: false } });
  }

  const handleChange = (item) => {
    if (!selectedItems.find(i => i._id === item._id)) {
      setSelectedItems([...selectedItems, item]);
    }
  }

  function handleInputChange(event) {
    setSearchText(event.target.value);
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (itemTargeted < 0 || itemTargeted >= filteredUsers.length) {
        return;
      }

      if (itemTargeted >= 0 && selectedItems.indexOf(filteredUsers[itemTargeted]) === -1) {
        setSelectedItems([...selectedItems, filteredUsers[itemTargeted]]);
        setItemTargeted(0);
      }

      setSearchText("");
    }
  }

  const onKeyDown = (event) => {
    if (event.key === 'Backspace') {
      if (searchText.length === 0) {
        setSelectedItems(selectedItems.slice(0, selectedItems.length - 1));
      }
    } else if (event.key === 'ArrowDown') {
      if (itemTargeted < filteredUsers.length - 1) {
        setItemTargeted(itemTargeted + 1);
      } else {
        setItemTargeted(0);
      }
    } else if (event.key === 'ArrowUp') {
      if (itemTargeted > 0) {
        setItemTargeted(itemTargeted - 1);
      } else {
        setItemTargeted(filteredUsers.length - 1);
      }
    }
  }

  const [invitableUsers, setInvitableUsers] = React.useState([]);
  const [filteredUsers, setFilteredUsers] = React.useState([]);

  React.useEffect(() => {
    if (!dialogState.visible) {
      return;
    }

    const invitedIds = group ? group.users.map(item => item._id) : [];
    const selectedIds = selectedItems.map(item => item._id);
    setInvitableUsers(workingSpace.users.filter(item => {
      return invitedIds.indexOf(item.user._id) === -1 && selectedIds.indexOf(item.user._id) === -1;
    }).map(item => item.user));
  }, [workingSpace.users, group, selectedItems]);

  React.useEffect(() => {
    const usersFiltered = invitableUsers.filter(user => {
      return (user.username || user.nickname).toLowerCase().includes(searchText.toLowerCase());
    });

    setFilteredUsers(usersFiltered);
  }, [searchText, invitableUsers]);

  const handleConfirm = () => {
    if (selectedItems.length === 0) {
      setSelectedValid(false);
      return;
    }
    dispatch(addUsersToGroup({ orgId: loginUser.workingOrgId, groupId: group._id, objs: selectedItems.map(item => item._id) }, () => {
      handleClose();
    }, 'addUsersToGroup'));
  }

  return (
    <Dialog
      open={!!dialogState && dialogState.visible}
      onClose={handleClose}
      scroll='paper'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
    >
      <DialogTitle id="scroll-dialog-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>Add users to group</span>
      </DialogTitle>
      <DialogContent dividers={true} style={{ height: '60vh' }}>
        <TextField
          autoComplete='off'
          required
          id="invite"
          label="email, user, groups..."
          style={{ width: '480px' }}
          value={searchText}
          InputProps={{
            startAdornment: <Box sx={{ display: 'flex', flexWrap: 'wrap' }}> {selectedItems.map((item, index) => (
              <Chip
                key={index}
                tabIndex={-1}
                label={item.name || item.nickname || item.username}
                onDelete={handleDelete(item)}
              />
            ))}
            </Box>,
            // onBlur,
            onChange: event => {
              handleInputChange(event);
            },

            autoFocus: true,
            error: !selectedValid,
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
          {
            filteredUsers.map((user, index) => (<div
              key={user._id}
              button
              onClick={() => handleChange(user)}
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(-1)}
              style={{ backgroundColor: index === itemTargeted ? '#eee' : (index === hovered ? '#f8f8f8' : 'transparent'), paddingLeft: 12, cursor: 'pointer' }}
            >
              <ListItemText style={{ width: '320px' }} primary={user.name || user.nickname || user.username} secondary={(user.username || user.name)} />
            </div>))
          }
        </div>
      </DialogContent>
      <DialogActions style={{ paddingRight: 12 }}>
        <Button onClick={handleClose}>{intl.formatMessage({ id: 'cancel' })}</Button>
        <Button variant='contained' onClick={handleConfirm}>{intl.formatMessage({ id: 'confirm' })}</Button>
      </DialogActions>
    </Dialog>
  )
}


export default AddUserToGroupModal;
