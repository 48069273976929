import * as React from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useIntl } from 'react-intl';

const PaymentRecordsTable = () => {
    const intl = useIntl();
    const loginUser = useSelector(state => state.loginIn.user);
    // const paymentRecords = useSelector(state => state.affiliate.paymentRecords); // Assume this data is available in the state
    const [paymentRecords, setPaymentRecords] = React.useState([]);

    

    return (<>
        <div style={{ marginTop: 20, fontWeight: 'bold', fontSize: '16px' }}>
            Your commission rate
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px' }}>
            <span style={{ marginRight: '10px', color: '#555' }}>{loginUser?.commission}% of your referred user payment</span>
        </div>

        <div style={{ marginTop: 20, paddingBottom: 10, fontWeight: 'bold', fontSize: '16px' }}>
            Referred user payments
        </div>
        <div style={{ border: '1px solid #ccc', borderRadius: '4px', overflow: 'hidden', marginRight: 20 }}>
            {paymentRecords.map((user) => (
                <div key={user.id} style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #eee' }}>
                    <span style={{ color: '#333' }}>{user.username}</span>
                    <span style={{ color: '#777' }}>{user.createdAt}</span>
                </div>
            ))}
        </div>
    </>
    );
}

export default PaymentRecordsTable; 