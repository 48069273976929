import { Check } from '@styled-icons/material';

const PrivilegeItem = ({ text, desc }) => {
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 6,
        paddingTop: 8,
        paddingBottom: 8,
    }}>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: 6,
            fontSize: 14,
        }}>
            <div style={{
                width: 18
            }}>
                <Check size={18} />
            </div>
            {text}
        </div>
        {
            desc &&
            <span style={{ color: '#999', fontSize: 13, paddingLeft: 28 }}>{desc}</span>
        }

    </div>
}

export default PrivilegeItem