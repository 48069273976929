import { useDispatch, useSelector } from "react-redux";
import { getAppConfig, getLngSetting, lngSetting, updateRILBindedOrgId, updateReceiveUpdateEmails } from "src/actions/ticketAction";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react"; // 添加 useState
import { Checkbox, FormControlLabel } from "@mui/material";
import { FLOW_EXPORT_IMAGE_WITHOUT_QRCODE } from "../../constants/actionTypes"; // 添加 RECEIVE_UPDATE_EMAILS
import { APP_CONFIG } from "src/constants/actionTypes";
import { Selector } from "../common/Selector";
import { getReceiveUpdateEmails } from "../../actions/ticketAction";

const MoreSettings = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const lng = useSelector(state => state.uiState.lng);
    const flow_export_image_without_qrcode = useSelector(state => state.uiState.flow_export_image_without_qrcode);

    const settingsDialogState = useSelector(state => state.uiState.settingsDialog) || {};
    const [receive_update_emails, setReceiveUpdateEmails] = useState(true);

    const { app } = settingsDialogState;

    const LNGS = [{
        value: "en",
        label: "English"
    }, {
        value: "cn",
        label: "中文"
    }];

    useEffect(() => {
        dispatch(getReceiveUpdateEmails({
        }, (data) => {
            setReceiveUpdateEmails(data);
        }));
    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <div style={styles.item} >
                <div>{intl.formatMessage({ id: 'settings_language_desc' }) + ':'}</div>
                <Selector
                    options={LNGS}
                    value={lng}
                    inputStyle={{
                        border: '1px solid #ccc',
                        borderRadius: '3px',
                    }}

                    onChange={(value) => {
                        dispatch(lngSetting({ lng: value }, () => {
                            dispatch(getAppConfig({ service: 'funblocks' }, (data) => {
                                dispatch({
                                    type: APP_CONFIG,
                                    value: data
                                })
                            }));
                        }, "lngSetting"));
                    }}
                />
            </div>

            <div style={styles.item} >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!flow_export_image_without_qrcode}
                            onChange={(event) => {
                                dispatch({
                                    type: FLOW_EXPORT_IMAGE_WITHOUT_QRCODE,
                                    value: !event.target.checked
                                })
                            }}
                        />
                    }
                    label={intl.formatMessage({ id: 'flow_exported_image_watermark_qrcode' })}
                />
            </div>
            <div style={{ ...styles.item, paddingTop: 3, color: '#888', fontSize: 14 }} >
                <div>{intl.formatMessage({ id: 'flow_exported_image_watermark_qrcode_tips' })}</div>
            </div>

            <div style={styles.item} >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={receive_update_emails}
                            onChange={(event) => {
                                dispatch(updateReceiveUpdateEmails({
                                    emailOptIn: event.target.checked
                                }, (data) => {
                                    setReceiveUpdateEmails(data);
                                }));

                            }}
                        />
                    }
                    label={intl.formatMessage({ id: 'receive_update_emails' })}
                />
            </div>
            <div style={{ ...styles.item, paddingTop: 3, color: '#888', fontSize: 14 }} >
                <div>{intl.formatMessage({ id: 'receive_update_emails_tips' })}</div>
            </div>

        </div>
    );
}

const styles = {
    item: {
        paddingTop: '30px',
        columnGap: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: 450,
        fontSize: 17
    }
}

export default MoreSettings;