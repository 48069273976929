import { FLOW_INPUT_MODAL } from '@/constants/actionTypes';
import React, { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import TurndownService from 'turndown';
import { useMediaQuery } from 'react-responsive';
import ContentEditable from 'react-contenteditable';
import ReactDraggable from 'react-draggable'
import { MOBILE_MEDIA_QUERY } from '../../utils/constants';

const modalStyles = {
  position: 'fixed',
  left: 'calc(50% - 180px)',
  top: '20%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'azure',
  padding: 0,
  borderRadius: '4px',
  boxShadow: `5px 5px 0px rgba(0, 0, 0, 10%)`,
  border: '1px solid gray',
  zIndex: '1000',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  rowGap: 10,
  cursor: 'pointer'
};

const InputModal = () => {
  const modalState = useSelector(state => state.uiState.flow_input_modal) || {};
  const { visible, value } = modalState;
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isValid, setIsValid] = useState();
  const [content, setContent] = useState();
  const textInputRef = React.useRef();
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  useEffect(() => {
    setContent(value);
  }, [value])

  useEffect(() => {
    setTimeout(() => {
      const editableDiv = textInputRef.current;
      if (editableDiv) {
        editableDiv.focus();

        if (modalState.multiline) {
          const range = document.createRange();
          range.selectNodeContents(editableDiv);
          range.collapse(false); // 将光标移动到末尾
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }, 100)

  }, [textInputRef?.current]);

  useEffect(() => {
    const turndownService = new TurndownService();
    const mrkd = turndownService.turndown(content || '')?.trim();

    let isValid = !!mrkd?.trim();

    setIsValid(isValid);
  }, [content])

  const handlePaste = React.useCallback((e) => {
    e.preventDefault();

    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  }, [])

  const handleClose = () => {
    dispatch({
      type: FLOW_INPUT_MODAL,
      value: {
        visible: false
      }
    })
  }

  const handleChange = (event) => {
    setContent(event.target.value)
  }

  const handleConfirm = () => {
    dispatch({
      type: FLOW_INPUT_MODAL,
      value: {
        ...modalState,
        value: content,
        visible: false
      }
    })
  }

  if (!visible) {
    return null;
  }

  return (
    <ReactDraggable handle=".handle">
      <div
        className="handle"
        style={modalStyles}
      >
        <div
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        >
          {
            !modalState?.multiline &&
            <input
              id={'promptInput_single'}
              ref={textInputRef}
              className={`fill-available ${isMobile && 'nodrag' || ''}`}
              style={{
                border: '0px',
                outline: 'none',
                fontSize: 15,
                alignContent: 'flex-end',
                cursor: 'text',
                width: 360,
                backgroundColor: 'transparent',
                padding: 10,
                paddingBottom: 0
              }}
              value={content || ''}
              onChange={(event) => {
                handleChange(event)
              }}
            />
          }
          {
            modalState?.multiline &&
            <ContentEditable
              id={'promptInput_multi'}
              innerRef={textInputRef}
              className={`fill-available nodrag`}
              style={{
                border: '0px',
                outline: 'none',
                fontSize: 15,
                alignContent: 'flex-end',
                cursor: 'text',
                width: 360,
                maxHeight: 400,
                padding: 10,
                paddingBottom: 0,
                overflowY: 'auto'
              }}
              html={content || ''}
              onChange={(event) => {
                handleChange(event)
              }}

              onPaste={handlePaste}
            />
          }
        </div>

        <div
          className='fill-available'
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', height: 'fit-content', padding: 10, columnGap: 10 }}
        >
          <div
            className='transparent-background'
            style={{
              padding: 5,
              paddingRight: 10,
              paddingLeft: 10,
              borderRadius: 4,
              cursor: 'pointer',
              borderRadius: '4px',
              whiteSpace: 'nowrap',
              color: 'GrayText',
            }}
            onClick={() => handleClose()}
          >
            {intl.formatMessage({ id: 'cancel' })}
          </div>
          <div
            className='transparent-background'
            style={{
              color: isValid ? undefined : 'GrayText',
              border: `1px solid ${isValid ? 'gray' : '#eee'}`,
              boxShadow: '0px 0px 8px #bbb',
              padding: 5,
              paddingRight: 10,
              paddingLeft: 10,
              borderRadius: 4,
              cursor: 'pointer'
            }}
            onClick={() => isValid && handleConfirm()}
          >
            {intl.formatMessage({ id: 'confirm' })}
          </div>
        </div>
      </div>
    </ReactDraggable>
  );
};

export default InputModal;