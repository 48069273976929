import { Button, Divider, MenuItem, Select, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getServingProducts, getAvailableProducts, initPayment, checkPaymentStatus } from "src/actions/ticketAction";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { SERVING_PRODUCT } from "src/constants/actionTypes";
import { formatDate } from "src/utils/timeFormater";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

const ServiceSubscribe = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const available_products = useSelector(state => state.uiState.available_products);
    const serving_products = useSelector(state => state.uiState.serving_products) || [];
    const loginUser = useSelector(state => state.loginIn && state.loginIn.user);
    const [paying, setPaying] = useState();
    const [spUpdated, setSpUpdated] = useState();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState();

    const services = ['ril', 'ai'];

    useEffect(() => {
        dispatch(getAvailableProducts({ services }, null, 'servicesubscribe'));
    }, []);

    useEffect(() => {
        loginUser?._id && dispatch(getServingProducts({ services }, null, 'servicesubscribe'));
    }, [loginUser]);

    useEffect(() => {
        if (!available_products?.products) {
            return;
        }

        const products = [{
            id: 'free',
            name: intl.formatMessage({ id: 'service_product_name' }, { service: intl.formatMessage({ id: 'service_name_funblocks' }), level: intl.formatMessage({ id: 'service_level_free' }) }),
            privileges: {}
        }];

        Object.keys(available_products.products).forEach(service => {
            Object.keys(available_products.products[service]).forEach(level => {
                if (level == 'free') {
                    Object.keys(available_products.products[service][level].privileges).forEach(privilege => {
                        products[0].privileges[privilege] = available_products.products[service][level].privileges[privilege]
                    })
                    return;
                }

                let product = available_products.products[service][level];
                product.id = `${service}_${level}`;
                product.service = service;
                product.name = intl.formatMessage({ id: 'service_product_name' }, { service: intl.formatMessage({ id: 'service_name_' + service }), level: intl.formatMessage({ id: 'service_level_' + level }) })
                product.skus = available_products.skus.filter(sku => sku.productId.startsWith(service + '_' + level))
                products.push(product);
            })
        })

        setProducts(products);
    }, [available_products])

    const initPay = (service, productId) => {
        let channel = 'alipay'
        setLoading({ service, productId, action: 'initPay' });
        dispatch(initPayment({ productId, channel, service, fromWeb: true }, (payInfo) => {
            let popup = window.open(payInfo.url)

            if (popup === null || typeof popup === 'undefined') {
                alert('浏览器阻止了弹出窗口，请允许弹出窗口来打开新网页完成支付。');
            }

            setLoading(null);

            let paying = {
                service,
                orderId: payInfo.orderId,
                channel,
                timeStamp: new Date().getTime()
            }
            setPaying(paying);
            setTimeout(() => checkPayStatus(paying), 30 * 1000);
        }))
    }

    const checkPayStatus = (paying, userInited) => {
        if (!userInited && (new Date().getTime() - paying.timeStamp > 300 * 1000)) {
            return;
        }

        userInited && setLoading({ action: 'checkPayStatus' })
        dispatch(checkPaymentStatus({ payInfo: JSON.stringify(paying), userInited }, (item) => {
            userInited && setLoading(null);

            if (!item) {
                !userInited && setTimeout(() => checkPayStatus(paying), 10 * 1000);
                return;
            }

            if (item.service) {
                dispatch({
                    type: SERVING_PRODUCT,
                    item
                })
            }

            //show message to user
            setPaying(null);
            setSpUpdated({ service: item.service });
            setTimeout(() => setSpUpdated(null), 30 * 1000);
        }))
    }

    if (!available_products || !available_products.privileges) {
        return <></>
    }

    return (
        <div style={{ paddingTop: '20px', columnGap: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                paddingTop: '40px',
                paddingBottom: '20px'
            }}>
                {
                    services.map((service, index) => {
                        let serving_product = serving_products.find(sp => sp.service == service);
                        if (!serving_product) {
                            return null
                        }
                        return <div key={index + ''} style={{ minWidth: '280px' }}>
                            <div style={{ fontSize: 17, fontWeight: 'bold' }}> {intl.formatMessage({ id: 'service_name_' + service })} </div>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '6px'}}>
                                <div style={{ fontSize: 13, color: '#666', padding: '4px', backgroundColor: '#e9e9e9', borderRadius: '5px', marginRight: '6px' }}>{intl.formatMessage({ id: 'current_plan' })}</div>
                                <div style={{ fontSize: 18, fontWeight: 'bold', textTransform: 'capitalize'}}>{intl.formatMessage({ id: 'service_level_' + serving_product.name })}</div>
                            </div>
                            <div style={{ fontSize: 13, color: '#666', paddingTop: '4px', paddingBottom: '6px' }}>{serving_product.name !== 'free' ? intl.formatMessage({ id: 'plan_expire_at' }, { date: formatDate(serving_product.endAt, 'YYYY-MM-DD') }) : ''}</div>
                            {
                                paying?.service === service &&
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '6px' }}>
                                    <div style={{ fontSize: 14, fontWeight: 'bold' }}>{intl.formatMessage({ id: 'paid_to_check' })}</div>

                                    <LoadingButton loading={loading?.action === 'checkPayStatus'} disabled={!!loading} variant="outlined" onClick={() => checkPayStatus(paying, true)} >{intl.formatMessage({ id: 'refresh' })}</LoadingButton>
                                </div>
                            }
                            {
                                spUpdated?.service === service &&
                                <div style={{ color: 'green', fontWeight: 'bold', padding: '6px' }}>
                                    {intl.formatMessage({ id: 'subscribe_status_updated' })}
                                </div>
                            }
                        </div>
                    })
                }
            </div>

            <Divider />
            <div style={{ alignSelf: 'flex-start', paddingLeft: '6px', paddingBottom: '6px' }}>{intl.formatMessage({ id: 'buyable_plans' })}</div>
            <table style={{ borderCollapse: 'collapse' }}>
                <tbody>
                    <tr style={{ verticalAlign: 'bottom' }}>
                        <td style={{ textAlign: 'right', padding: '4px' }}>{intl.formatMessage({ id: 'privileges' })}</td>
                        {
                            products.map((product, i) => {
                                const skus = product.skus;
                                if (!skus) return <td style={{ minWidth: '80px', textAlign: 'right', padding: '4px' }}>
                                    {intl.formatMessage({ id: 'service_level_free' })}
                                </td>;

                                return <td key={i + ''} style={{ minWidth: '200px', padding: '4px' }}>
                                    <div style={{ fontWeight: 'bold', color: 'dodgerblue', fontSize: 15, paddingBottom: '4px' }}>{product.name}</div>
                                    <div style={{ rowGap: '5px', display: 'flex', flexDirection: 'column' }}>
                                        {
                                            skus.map((sku, index) => {
                                                return <div
                                                    key={index + ''}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        padding: '4px',
                                                        backgroundColor: 'aliceblue'
                                                    }}
                                                >
                                                    <div>
                                                        <div style={{ fontWeight: 'bold', color: 'dodgerblue' }}>{sku.title}</div>
                                                        <div>{`${sku.price} 元`}</div>
                                                    </div>
                                                    <LoadingButton
                                                        variant="contained"
                                                        color={index === skus.length - 1 ? "success" : "primary"}
                                                        loading={loading?.action == 'initPay' && loading?.service == product.service && loading?.productId == sku.productId}
                                                        disabled={loading?.action == 'initPay'}
                                                        onClick={() => {
                                                            initPay(product.service, sku.productId);
                                                        }}> {intl.formatMessage({ id: 'buy' })}
                                                    </LoadingButton>
                                                </div>
                                            })
                                        }
                                    </div>
                                </td>
                            })
                        }
                    </tr>
                    {
                        Object.keys(available_products?.privileges)?.map((privilege, index) => {
                            return <tr style={{ backgroundColor: '#f9f9f9' }}>
                                <td style={{ textAlign: 'right', padding: '4px' }}>
                                    {
                                        available_products.privileges[privilege]
                                    }
                                </td>
                                {
                                    products.map((product, i) => {
                                        return <td key={privilege + i + ''} style={{ textAlign: 'right', padding: '4px' }}>
                                            <div>
                                                {
                                                    product.privileges[privilege]
                                                }
                                            </div>
                                        </td>
                                    })
                                }
                            </tr>
                        })
                    }

                </tbody>
            </table>
            <div style={{ fontSize: 13, color: '#666', padding: '6px' }}>
                {
                    available_products.notes
                }
            </div>
        </div>
    );
}

export default ServiceSubscribe;