export const getTopLevelDomain = () => {
    var hostname = window.location.hostname;
    var parts = hostname.split('.');

    // 检查是否是IP地址
    if (parts.length === 4 && parts.every(part => !isNaN(part))) {
        return hostname;
    }

    // 如果域名部分数大于或等于2个，则取最后两个部分作为一级域名
    if (parts.length >= 2) {
        return parts.slice(-2).join('.');
    }

    // 否则返回原始的hostname
    return hostname;
}

export const getProxiedImageUrl =  (url, server_host) => {
    if(url.includes(server_host) || url.includes('/imgproxy?') || url.startsWith('data:')) {
        return url;
    }
    return server_host + 'imgproxy?url=' + encodeURIComponent(url);
}