import { Divider, MenuItem, Select, TextField } from "@mui/material";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { updateRILBindedOrgId } from "src/actions/ticketAction";
import { getStateByUser } from "src/reducers/listReducer";
import { Selector } from "../common/Selector";

const RILSettings = () => {
    const dispatch = useDispatch();
    const loginUser = useSelector(state => state.loginIn.user);
    const orgs = useSelector(state => getStateByUser(state.org_lists, loginUser));
    const intl = useIntl();

    return (
        <div style={{ paddingTop: '20px', display: 'flex', flexDirection: 'row', columnGap: 5, alignItems: 'center' }} >
            {intl.formatMessage({ id: "settings_ril_workspace_selection" })}
           
            <Selector
                options={orgs.items.map(item => {
                    return {
                        label: item.name,
                        value: item._id
                    }
                })}
                value={loginUser.rilBindedOrgId || ''}
                inputStyle={{
                    border: '1px solid #ccc',
                    borderRadius: '3px',
                }}

                onChange={(value) => {
                    dispatch(updateRILBindedOrgId({ orgId: value }, null, "org"));
                }}
            />
        </div>
    );
}

export default RILSettings;