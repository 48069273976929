import { Popover, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';


export const node_color_themes = [{
    id: 'orange',
    title_bg: 'rgb(244, 182, 121)',
    content_bg: 'rgb(255, 235, 220)',
    border: '#DC7633'
}, {
    id: 'pink',
    title_bg: 'rgb(245, 193, 192)',
    content_bg: 'rgb(255, 243, 243)',
    border: 'rgb(225, 164, 165)',
    borderShadow: '#E1A4A5'
}, {
    id: 'gray',
    title_bg: 'rgb(205, 205, 205)',
    content_bg: 'rgb(248, 248, 245)',
    border: '#777'
}, {
    id: 'yellow',
    title_bg: 'rgb(234, 205, 99)',
    content_bg: 'rgb(255, 251, 236)',
    border: 'rgb(202, 169, 72)',
    borderShadow: '#CAA948'
}, {
    id: 'green',
    title_bg: 'rgb(190, 219, 144)',
    // content_bg: 'rgb(218, 247, 206)',
    content_bg: '#F0FFF0',
    border: 'rgb(180, 179, 124)'
}, {
    id: 'blue',
    title_bg: 'rgb(167, 221, 215)',
    content_bg: 'azure',
    border: 'rgb(99, 167, 159)',
    borderShadow: '#63A79F'
}, {
    id: 'purple',
    title_bg: '#fa90e6',
    content_bg: 'rgb(255, 239, 255)',
    // content_bg: '#fee0fd',
    border: 'mediumorchid'
}]

export const ColorMenu = (props) => {
    const intl = useIntl();
    const [anchorEl, setAnchorEl] = useState(null);
    const { children, onSubmit, iconStyle } = props;

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <div>
            <Tooltip title={intl.formatMessage({ id: 'color' })} placement="top">
                <div
                    className='hoverStand'
                    style={iconStyle}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                    {children}
                </div>
            </Tooltip>
            <Popover
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: 1,
                        justifyContent: 'space-evenly',
                    }}
                >
                    {
                        node_color_themes.map((theme, index) => {
                            return <div
                                key={index + ''}
                                className='hoverStand'
                                style={iconStyle}
                                onClick={() => onSubmit(theme.id)}
                            >
                                <div
                                    style={{
                                        width: 20,
                                        height: 20,
                                        borderRadius: '50%',
                                        backgroundColor: theme.title_bg,
                                        cursor: 'pointer'
                                    }}
                                />
                            </div>
                        })
                    }
                </div>
            </Popover>
        </div>
    );
}