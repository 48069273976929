import { create } from 'zustand';
import {
    addEdge,
    applyNodeChanges,
    applyEdgeChanges,
    MarkerType
} from '@xyflow/react';
import { cloneDeep } from 'lodash';
import { temporal } from 'zundo';
import equal from 'fast-deep-equal/react';

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useStoreWithUndo = create()(temporal((set, get) => ({
    nodes: [],
    edges: [],
    onNodesChange: (changes) => {
        set({
            nodes: applyNodeChanges(changes, get().nodes),
        });
    },
    onEdgesChange: (changes) => {
        set({
            edges: applyEdgeChanges(changes, get().edges),
        });
    },
    onConnect: (connection) => {
        set({
            edges: addEdge({ ...connection, type: 'float_edge', markerEnd: { type: MarkerType.ArrowClosed } }, get().edges),
        });
    },

    setNodes: (nodes) => {
        set({ nodes });
    },
    setEdges: (edges) => {
        set({ edges });
    },

    addNode: (newNode) => {
        let nodes = cloneDeep(get().nodes);

        set({ nodes: (nodes || []).concat(newNode) })
        set({ newNode })
    },

    deleteNode: (nodeId) => {
        const nodes = get().nodes;
        const node = nodes.find(node => node.id === nodeId);
        if (node.parentId && nodes.find(n => n.id === node.parentId)) {
            return;
        }

        set({ nodes: cloneDeep(get().nodes).filter(node => node.id !== nodeId) });
        set({ edges: cloneDeep(get().edges || []).filter(edge => edge.source !== nodeId && edge.target !== nodeId) })
    },

    getNode: (id) => {
        return cloneDeep(get().nodes?.find(node => node.id == id) || {});
    },

    updateNode: (updatedNode) => {
        let nodes = cloneDeep(get().nodes);
        let index = nodes.findIndex(node => node.id === updatedNode.id);
        if (index > -1) {
            nodes[index] = updatedNode;
        }
        set({ nodes })
    },

    updateNodeData: (nodeId, updatedData) => {
        let nodes = cloneDeep(get().nodes);
        let node = nodes.find(n => n.id == nodeId);

        if (!node) return;

        node.data = {
            ...(node.data || {}),
            ...updatedData
        }

        set({ nodes })
    },

    addSubNode: (parentId, newNode) => {
        set({ nodes: cloneDeep(get().nodes).concat(newNode) })
        if (parentId) {
            set({ edges: cloneDeep(get().edges || []).concat({ id: newNode.id, type: 'float_edge', source: parentId, target: newNode.id, markerEnd: { type: MarkerType.ArrowClosed } }) });
        }

        set({ newNode })
    },

    getNodeEdges: (nodeId, direction) => {
        return [...(get().edges?.filter(edge => {
            if (direction == 'source') {
                return edge.source == nodeId
            } else if (direction == 'target') {
                return edge.target == nodeId
            }

            return edge.source == nodeId || edge.target == nodeId;

        }) || [])]
    }
}),
    {
        // equality: (pastState, currentState) => equal(pastState, currentState)
        equality: (pastState, currentState) =>
            equal({
                ...pastState,
                nodes: cloneDeep(pastState.nodes)?.map(node => {
                    node.position = undefined;
                    node.width = undefined;
                    node.height = undefined;
                    node.selected = false;
                    node.data = {
                        title: node.data.title
                    }
                    return node
                })
            }, {
                ...currentState,
                nodes: cloneDeep(currentState.nodes)?.map(node => {
                    node.position = undefined;
                    node.width = undefined;
                    node.height = undefined;
                    node.selected = false;
                    node.data = {
                        title: node.data.title
                    }
                    return node
                })
            }),
    }
));

export const useStore = create((set, get) => ({
    tempNotes: [],
    setContextMenu: (context_menu) => {
        set({ context_menu });
    },

    setLang: (lang) => {
        set({ lang })
    },

    setReadOnly: (readOnly) => {
        set({ readOnly })
    },

    setBoardTitle: (title) => {
        set({ boardTitle: title });
    },

    setShareUrl: (shareUrl) => {
        set({ shareUrl })
    },

    setSavingTrigger: (trigger) => {
        set({ savingTrigger: trigger })
    },

    setTempLlmModel: (model) => {
        set({ tempLlmModel: model })
    },

    setTempNotes: (notes) => {
        set({ tempNotes: notes })
    },

    addTempNote: (note) => {
        let notes = cloneDeep(get().tempNotes);

        set({ tempNotes: (notes || []).concat(note) })
    },

    deleteTempNote: (note) => {
        let notes = cloneDeep(get().tempNotes);

        set({ tempNotes: notes.filter(n => n.id !== note.id) })
    }
}))

export default useStoreWithUndo;
