import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { KeyboardArrowDown } from '@styled-icons/material-outlined/KeyboardArrowDown';
import { KeyboardArrowUp } from '@styled-icons/material-outlined/KeyboardArrowUp';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_GROUP_DIALOG, ADD_USER_TO_GROUP_DIALOG } from 'src/constants/actionTypes';
import AddGroupModal from './AddGroupModal';
import { getState } from 'src/reducers/listReducer';
import AddUserToGroupModal from './AddUserToGroupModal';
import { removeUserFromGroup } from 'src/actions/ticketAction';
import { useIntl } from 'react-intl';

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const intl = useIntl();

    return (
        <React.Fragment>
            <TableRow onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
                <TableCell component="th" scope="row" style={{ border: 'none', borderBottom: '1px solid lightgray' }}>
                    {row.name}
                </TableCell>
                <TableCell component="th" scope="row" align="right" style={{ border: 'none', borderBottom: '1px solid lightgray' }}>{row.users.length}</TableCell>
                <TableCell component="th" scope="row" align="right" style={{ border: 'none', borderBottom: '1px solid lightgray' }}>
                    <Button variant="outlined" color="primary" onClick={() => props.onAddUser(row)}>
                        {intl.formatMessage({ id: 'add_user' })}
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th" scope="row" style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small" aria-label="users">
                            <TableBody>
                                {row.users.map((user) => (
                                    <TableRow key={user._id} style={{ paddingBottom: 3, paddingTop: 0 }}>
                                        <TableCell component="th" scope="row" key={'user'} style={{ display: 'flex', flexDirection: 'column', border: 'none', borderBottom: '1px solid lightgray' }}>
                                            <span>
                                                {user.nickname}
                                            </span>
                                            <span style={{ fontSize: '12px', color: 'gray' }}>
                                                {user.username}
                                            </span>
                                        </TableCell>

                                        <TableCell component="th" scope="row" align="right" style={{ border: 'none', borderBottom: '1px solid lightgray' }}>
                                            <Button
                                                variant="outlined"
                                                size='small'
                                                color="secondary"
                                                disabled={!props.isAdmin}
                                                onClick={() => props.onRemoveUser(row, user)}
                                            >
                                                {intl.formatMessage({ id: 'remove' })}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


const GroupsTable = ({ isAdmin, descText }) => {
    const loginUser = useSelector(state => state.loginIn.user);
    const groups = useSelector(state => getState(state.group_lists, loginUser.workingOrgId).items);
    const dispatch = useDispatch();
    const intl = useIntl();

    console.log('groups', groups);
    const onAddUser = (group) => {
        dispatch({ type: ADD_USER_TO_GROUP_DIALOG, value: { visible: true, group } });
    }

    const onRemoveUser = (group, user) => {
        dispatch(removeUserFromGroup({ groupId: group._id, objId: user._id, orgId: loginUser.workingOrgId }));
    }

    return (<Box sx={{ width: '100%' }}>
        <div style={{ color: 'gray', paddingBottom: '10px', fontSize: 14 }}>
            {descText}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button variant="contained" size='small' color="primary"
                disabled={!isAdmin}
                onClick={() => dispatch({ type: ADD_GROUP_DIALOG, value: { visible: true } })}>
                {intl.formatMessage({ id: 'add_group' })}
            </Button>
        </div>
        <TableContainer style={{ overflowX: 'hidden' }}>
            <Table>
                <TableHead>
                    <TableRow style={{}}>
                        <TableCell size='small' style={{ border: 'none', borderBottom: '1px solid gray', fontWeight: 800, color: 'gray' }}>{intl.formatMessage({ id: 'group' })}</TableCell>
                        <TableCell size='small' style={{ border: 'none', borderBottom: '1px solid gray', fontWeight: 800, color: 'gray' }} align="right">{intl.formatMessage({ id: 'size' })}</TableCell>
                        <TableCell size='small' style={{ border: 'none', borderBottom: '1px solid gray', fontWeight: 800, color: 'gray' }} align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {groups && groups.map((group) => (
                        <Row key={group._id} row={group} isAdmin={isAdmin} onAddUser={onAddUser} onRemoveUser={onRemoveUser} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <AddGroupModal />
        <AddUserToGroupModal />
    </Box>
    );
}

export default GroupsTable;
