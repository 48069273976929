
import { KeyboardReturn } from '@styled-icons/material/KeyboardReturn';
import { useEffect, useRef, useState } from 'react';
import { Popover, TextareaAutosize } from "@mui/material";
import { useIntl } from 'react-intl';

export const InputModal = (props) => {
    const intl = useIntl();
    const { initValue, value, anchorEl, onChange, onSubmit, multiline = false, onClose, style, containerStyle, transformOrigin } = props;

    const handleClose = () => {
        onClose();
        onSubmit && onSubmit(value);
    }

    const [inputValue, setInputValue] = useState('');

    const inputRef = useRef();
    const multilineInputRef = useRef();

    useEffect(() => {
        inputRef.current?.focus();
    }, [inputRef && inputRef.current])

    useEffect(() => {
        setInputValue(initValue);
    }, [])

    useEffect(() => {
        if(value != undefined) {
            setInputValue(value);
        } 
    }, [value])

    // useEffect(() => {
    //     multilineInputRef.current?.focus();
    // }, [multilineInputRef && multilineInputRef.current])

    return (
        <Popover
            open={Boolean(anchorEl)}
            onClose={onClose}
            onKeyPress={(e) => {
                if (e.key === 'Enter' && !multiline) {
                    handleClose();

                } 
                e.stopPropagation();
            }}
            onKeyDown={(e) => {
                 if (e.key === 'Escape') {
                    onClose();
                }

                // e.preventDefault();
                e.stopPropagation();
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={ transformOrigin || {
                vertical: 'top',
                horizontal: 'left',
            }}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    background: '#f3f3f3',
                    ...containerStyle
                }}
                ref={inputRef}
            >
                {
                    !multiline &&
                    <input
                        // ref={inputRef}
                        className='fill-available'
                        type="text"
                        value={inputValue}
                        onChange={(e) => {
                            onChange(e);
                            setInputValue(e.target.value)
                        }}
                        autoFocus
                        style={{ border: 'none', outline: 'none', borderRadius: 4, padding: 6, ...style }}
                    />
                }
                {
                    !!multiline &&
                    <TextareaAutosize
                        // ref={multilineInputRef}
                        className='fill-available'
                        value={inputValue}
                        onChange={(e) => {
                            onChange(e);
                            setInputValue(e.target.value)
                        }}
                        minRows={4}
                        autoFocus
                       
                        onFocus={(e) => {
                            e.target.setSelectionRange(e.target.value.length, e.target.value.length);
                        }}
                        style={{ border: 'none', outline: 'none', minWidth: '300px', borderRadius: 4, padding: 6, ...style }}
                    />
                }
                <div
                    className='hoverStand'
                    style={{
                        fontSize: '14px',
                        backgroundColor: 'rgb(35, 131, 226)',
                        color: 'white',
                        whiteSpace: 'nowrap'
                    }}
                    onClick={(e) => {
                        handleClose();
                    }}
                >
                    {intl.formatMessage({ id: 'confirm_done' })}
                    {
                        !multiline &&
                        <KeyboardReturn size={20} style={{ cursor: 'pointer' }} />
                    }
                </div>
            </div>
        </Popover>
    );
}