import { Divider, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOrg } from "src/actions/ticketAction";
import { getStateByUser } from "src/reducers/listReducer";
import BottomActionBar from "./BottomActionBar";
import { useIntl } from "react-intl";

const Space = ({ isAdmin }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const loginUser = useSelector(state => state.loginIn.user);
    const orgs = useSelector(state => getStateByUser(state.org_lists, loginUser));
    const workingSpace = orgs.items.find(org => org._id === loginUser.workingOrgId) || {};


    const [name, setName] = useState(workingSpace.name);
    const [nameValid, setNameValid] = useState(true);

    const handleConfirm = (callback) => {
        const isNameValid = name && name.length > 2;
        setNameValid(isNameValid);

        if (isNameValid) {
            dispatch(updateOrg({ data: { _id: workingSpace._id, name } }, callback, "org"));
        }
    }

    return (
        <div>
            <TextField
                required
                id="name"
                label={intl.formatMessage({ id: "workspace_name" })}
                style={{ width: '360px', marginTop: '20px', outline: 'none' }}
                disabled={!isAdmin}
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
                error={!nameValid}
            />
            <BottomActionBar handleConfirm={handleConfirm} />
        </div>
    );
}

export default Space;