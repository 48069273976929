import React from 'react';

const KeyboardKey = ({ children }) => {
  return (
    <kbd
      className="px-2 py-1 text-sm font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-md shadow-sm"
      style={{ 
        fontFamily: 'system-ui, sans-serif',
        display: 'inline-block',
        margin: '0 2px'
      }}
    >
      {children}
    </kbd>
  );
};

export default KeyboardKey;
