import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DialogContent, Dialog, Button, DialogTitle, Fab, Divider } from '@mui/material';
import { useIntl } from 'react-intl';
import { getInviteFriendMsg, getInviteRecord } from 'src/actions/ticketAction';
import { INVITED_RECORD } from '@/constants/actionTypes';
import { formatDate } from '@/utils/timeFormater';
import { Copy } from '@styled-icons/fluentui-system-regular/Copy';

const InviteFriendsMessage = ({ app }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [msg, setMsg] = React.useState();
    const [copied, setCopied] = React.useState(false);
    const [isInviteRecordsPage, setInviteRecordsPage] = React.useState();
    const invited_friends_record = useSelector(state => state.uiState.invited_record);
    const [invite_record, set_invite_record] = React.useState({ friends: 0, total_rewards: 0 })

    React.useEffect(() => {
        dispatch(getInviteFriendMsg({ app }, (item) => {
            setMsg(item);
        }))

        dispatch(getInviteRecord({}, (items) => {
            dispatch({
                type: INVITED_RECORD,
                value: items
            })
        }))
    }, []);

    React.useEffect(() => {
        if (!invited_friends_record?.length) return;

        let totalCoins = 0;
        for (let i = 0; i < invited_friends_record.length; i++) {
            totalCoins += invited_friends_record[i].coins;
        }

        set_invite_record({
            friends: invited_friends_record.length,
            total_rewards: totalCoins,
            items: invited_friends_record
        })
    }, [invited_friends_record])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: 800, padding: 30, backgroundColor: 'white' }}>
            <div style={{ margin: 10, marginTop: 0, display: 'flex', flexDirection: 'column', rowGap: 6, fontSize: 28 }}>
                {intl.formatMessage({ id: 'invite_to_earn' }, { coins: msg?.coins_reward }).split('\n').map((item, index) => {
                    return <span key={index + ''}>{index === 0 ? item + ' 🎁' : item}</span>
                })}
                <span style={{ fontSize: 18, fontWeight: 500 }}>{intl.formatMessage({ id: 'invited_to_given' }, { coins: msg?.invited_award })}</span>
            </div>

            <div
                style={{
                    display: 'flex', flexDirection: 'row', cursor: 'pointer', backgroundColor: '#eaeaea',
                    borderRadius: 20, padding: 2, margin: 10
                }}
            >
                <div
                    style={{
                        paddingTop: 6, paddingBottom: 6,
                        borderRadius: 20, width: '200px',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        backgroundColor: !isInviteRecordsPage ? 'deepskyblue' : undefined,
                        color: !isInviteRecordsPage ? 'white' : undefined,
                        fontSize: 15
                    }}
                    onClick={() => setInviteRecordsPage(false)}
                >
                    {intl.formatMessage({ id: 'copy_invite_link' })}
                </div>
                <div
                    style={{
                        paddingTop: 6, paddingBottom: 6,
                        borderRadius: 20, width: '200px',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        backgroundColor: isInviteRecordsPage ? 'deepskyblue' : undefined,
                        color: isInviteRecordsPage ? 'white' : undefined,
                        fontSize: 15
                    }}
                    onClick={() => setInviteRecordsPage(true)}
                >
                    {intl.formatMessage({ id: 'invitation_records' })}
                </div>
            </div>

            <div
                className='fill-available'
                style={{ margin: 10 }}
            >

                {
                    !isInviteRecordsPage &&
                    <>
                        <div style={{ marginBottom: 0, color: 'gray', fontSize: 13 }}>{intl.formatMessage({ id: 'copy_intruction' })}</div>
                        <div style={{ padding: 10, marginTop: 6, borderRadius: 6, display: 'flex', flexDirection: 'column', rowGap: 6, color: '#444', fontSize: 14, backgroundColor: 'whitesmoke', border: '1px solid #ccc' }}>
                            {msg?.msg.split('\n').map((item, index) => (<span key={index + ''}
                                style={{
                                    color: item.includes('http') ? 'gray' : undefined
                                }}>{item}</span>))}
                        </div>
                        <div className='fill-available' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <Button
                                variant='contained' style={{ margin: 0, marginTop: 30, alignSelf: 'center', textTransform: 'none', backgroundColor: copied ? 'gray' : 'dodgerblue' }}
                                onClick={() => { navigator.clipboard.writeText(msg?.msg); setCopied(true); setTimeout(() => { setCopied(false); }, 3000); }}
                            >
                                <Copy size={20} />
                                {intl.formatMessage({ id: copied ? 'copied' : 'click_to_copy_msg_link' })}
                            </Button>
                        </div>
                    </>
                }

                {
                    isInviteRecordsPage &&
                    <>
                        <div style={{ fontWeight: 'bold', marginTop: 16 }}>{intl.formatMessage({ id: 'invitation_records' })}</div>
                        <div className='fill-available' style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: 20,
                            backgroundColor: '#f3f3f3',
                            borderRadius: 10,
                            marginTop: 10,
                        }}>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ fontSize: 15 }}>{intl.formatMessage({ id: 'invited_frends' })}:&nbsp;</div>
                                <span style={{ fontWeight: 'bold' }}>{invite_record.friends}</span>
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ fontSize: 15 }}>{intl.formatMessage({ id: 'total_rewards' })}:&nbsp;</div>
                                <span style={{ fontWeight: 'bold' }}>{invite_record.total_rewards}</span>
                            </div>
                        </div>
                        {
                            !!invited_friends_record?.length &&
                            <>
                                <div style={{ fontWeight: 'bold', marginTop: 16 }}>{intl.formatMessage({ id: 'detail' })}</div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    padding: 20,
                                    backgroundColor: '#f3f3f3',
                                    borderRadius: 10,
                                    marginTop: 10,
                                }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            fontWeight: 500,
                                        }}
                                    >
                                        <div style={{ flex: 1 }}>
                                            {intl.formatMessage({ id: 'friend' })}
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            {intl.formatMessage({ id: 'reward' })}
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            {intl.formatMessage({ id: 'time' })}
                                        </div>
                                    </div>
                                    <div style={{
                                        maxHeight: 200,
                                        overflowY: 'auto'
                                    }}>
                                        {
                                            invited_friends_record.map((item, index) => {
                                                return <>
                                                    <div style={{
                                                        height: 1,
                                                        backgroundColor: '#ccc',
                                                        marginTop: 10
                                                    }} />
                                                    <div
                                                        key={index + ''}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            fontSize: 14,
                                                            paddingTop: 10,
                                                            color: '#444'
                                                        }}
                                                    >
                                                        <div style={{ flex: 1 }}>
                                                            {item.reward_partner_name || '***'}
                                                        </div>
                                                        <div style={{ flex: 1 }}>
                                                            {item.coins}
                                                        </div>
                                                        <div style={{ flex: 1 }}>
                                                            {formatDate(item.createdAt)}
                                                        </div>
                                                    </div>
                                                </>
                                            })
                                        }
                                    </div>
                                </div>

                            </>
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default InviteFriendsMessage;
