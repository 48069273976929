
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Popover, Radio, RadioGroup, Switch, TextareaAutosize } from "@mui/material";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { PROMOTE_VIP, PROMPT_DIALOG } from 'src/constants/actionTypes';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { Selector } from '../common/Selector';
import { cloneDeep } from 'lodash';
import PromoteVIP from './PromoteVIP';
import { PromptFormArgEditor } from './PromptFormArgEditor';
import { Edit } from '@styled-icons/material';
import { ChevronRight } from '@styled-icons/material/ChevronRight';

export const PromptForm = ({ modalVisibility, data, setData, error, readOnly, trigger }) => {
    const intl = useIntl();
    const promote_vip = useSelector(state => state.uiState.promote_vip) || {};
    const lng_list = useSelector(state => state.uiState.lng_list);
    const dispatch = useDispatch();

    const [promptFormArgEditorState, setPromptFormArgEditorState] = useState({ visible: false });

    const [showAdvanced, setShowAdvanced] = useState(false);
    const [hasAdvancedChanges, setHasAdvancedChanges] = useState(false);

    useEffect(() => {
        // Check if there are changes in advanced options
        const hasChanges = !!data.visibility || data.args?.length > 0;
        setHasAdvancedChanges(hasChanges);
        setShowAdvanced(hasChanges);
    }, [data]);

    useEffect(() => {
        !modalVisibility && setShowAdvanced(false);
    }, [modalVisibility]);

    const toggleAdvanced = () => {
        setShowAdvanced(!showAdvanced);
    };


    const handleClose = () => {
        dispatch({ type: PROMPT_DIALOG, value: { visible: false } });

        if (promote_vip?.visible) {
            dispatch({
                type: PROMOTE_VIP,
                value: {
                    visible: false
                }
            })
        }

    }

    const removeArg = useCallback((index) => {
        setData(prev => {
            let args = [...prev.args];
            args.splice(index, 1);

            return {
                ...prev,
                args
            }
        })
    }, []);

    const prepareNewArg = useCallback((arg) => {
        let newArg = cloneDeep(arg);

        if (arg.type === 'select') {
            if (arg.enable_user_input) {
                if (!arg.options.find(option => option.value === '')) {
                    newArg.options.push({
                        value: '',
                        label: intl.formatMessage({ id: 'no_suitable_option' })
                    })
                }
            } else {
                newArg.options = newArg.options.filter(option => !!option.value);
            }

            newArg = {
                ...newArg,
                value: newArg.options[0].value
            };
        }

        return newArg;
    }, []);

    const isChinese = useCallback((str) => {
        var reg = /[\u4e00-\u9fa5]/g; //使用Unicode字符集范围来匹配中文字符
        return reg.test(str);
    }, [])

    const variables_str = useMemo(() => {
        if (!data.args) {
            return ''
        }

        return data.args?.map((arg, index) => {
            return `{{var_${index}}}`
        }).join(', ')
    }, [data.args])

    return (
        <div style={{ margin: '20px', display: 'flex', flexDirection: 'column', rowGap: '12px', width: '-webkit-fill-available' }}>
            {/* Basic options */}
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '12px' }}>
                {
                    !readOnly &&
                    <div style={{ display: 'flex', flexDirection: 'row', width: '-webkit-fill-available', alignItems: 'center', columnGap: '6px' }}>
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {intl.formatMessage({ id: 'prompt_title' })}
                        </div>
                        <input
                            id="name"
                            value={data?.name || ''}
                            placeholder={intl.formatMessage({ id: 'prompt_title_placeholder' })}
                            onChange={(event) => {
                                setData({
                                    ...data,
                                    name: event.target.value
                                })
                            }}
                            style={{
                                padding: '3px',
                                width: '-webkit-fill-available',
                                border: '1px solid #ccc',
                                outline: 'none',
                                fontSize: '15px',
                                borderRadius: '3px'
                            }}
                            disabled={readOnly}
                            autoComplete='off'
                        />
                    </div>
                }
                {
                    readOnly && <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '-webkit-fill-available',
                        alignItems: 'center',
                    }}>
                        <span style={{
                            fontSize: 20,
                            fontWeight: 'bold'
                        }}>
                            {data?.name}
                        </span>
                        {
                            data?.userId?.nickname &&
                            <span>
                                {intl.formatMessage({ id: 'developer' }) + ': ' + data.userId.nickname}
                            </span>
                        }

                    </div>
                }
                {
                    !readOnly &&
                    <div style={{ display: 'flex', flexDirection: 'column', width: '-webkit-fill-available', rowGap: '3px' }}>
                        {intl.formatMessage({ id: 'description' })}
                        <textarea
                            id="desc"
                            rows={3}
                            value={data.desc || ''}
                            placeholder={intl.formatMessage({ id: 'prompt_desc_placeholder' })}
                            onChange={(event) => {
                                setData({
                                    ...data,
                                    desc: event.target.value
                                })
                            }}
                            style={{
                                padding: '3px',
                                width: '-webkit-fill-available',
                                border: '1px solid #ccc',
                                outline: 'none',
                                fontSize: '15px',
                                borderRadius: '3px'
                            }}
                        />
                    </div>
                }
                {
                    readOnly && data?.desc &&
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {data.desc}
                    </div>
                }
                {
                    readOnly &&
                    <div style={{ display: 'flex', flexDirection: 'column', width: '-webkit-fill-available', rowGap: '3px' }}>
                        {intl.formatMessage({ id: 'prompt_template' })}
                        <div style={{
                            backgroundColor: 'whitesmoke',
                            padding: 6,
                            fontSize: 14,
                            borderRadius: 3
                        }}>
                            {data.prompt}
                        </div>
                    </div>
                }
                {
                    !readOnly &&
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '3px' }}>
                        {intl.formatMessage({ id: 'prompt_template' })}
                        {/* <div style={{ color: 'gray', fontSize: '14px' }}>
                            {intl.formatMessage({ id: 'prompt_template_desc' }, { variables: !variables_str ? '' : (', ' + variables_str), selected_text: '{{selected_text}}' })
                                + (!variables_str ? '' : (', ' + intl.formatMessage({ id: 'prompt_template_desc_1' }, { variables: variables_str })))
                            }
                        </div> */}

                        <textarea
                            id="promptTemplate"
                            rows={5}
                            value={data.prompt || ''}
                            placeholder={intl.formatMessage({ id: 'prompt_template_placeholder' })}
                            onChange={(event) => {
                                setData({
                                    ...data,
                                    prompt: event.target.value
                                })
                            }}
                            style={{
                                padding: '3px',
                                width: '-webkit-fill-available',
                                border: '1px solid #ccc',
                                outline: 'none',
                                fontSize: '15px',
                                borderRadius: '3px'
                            }}
                        />


                    </div>
                }
            </div>

            {
                trigger !== 'shared' &&
                <div
                    onClick={toggleAdvanced}
                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                >
                    <ChevronRight
                        size={20}
                        style={{
                            transform: showAdvanced ? 'rotate(90deg)' : 'rotate(0deg)',
                            transition: 'transform 0.3s'
                        }}
                    />
                    <span>{intl.formatMessage({ id: 'advanced_options' })}</span>
                </div>
            }


            {/* Advanced options content */}
            {
                trigger !== 'shared' && showAdvanced && (
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '12px' }}>
                        {
                            !readOnly &&
                            <div>
                                <div>{intl.formatMessage({ id: 'context_usage' })}
                                    {!readOnly && <span style={{ color: '#666', fontSize: '14px' }}> ({intl.formatMessage({ id: 'arg_optional' })})</span>}
                                </div>
                                <div style={{ color: 'gray', fontSize: '14px' }}>
                                    {intl.formatMessage({ id: 'context_usage_desc' })}
                                </div>
                            </div>
                        }
                        {/* Task content form */}
                        <div>
                            <div>{intl.formatMessage({ id: 'task_content_form' })}
                                {!readOnly && <span style={{ color: '#666', fontSize: '14px' }}> ({intl.formatMessage({ id: 'arg_optional' })})</span>}
                            </div>
                            <div style={{ color: 'gray', fontSize: '14px' }}>
                                {intl.formatMessage({ id: 'task_content_form_desc' })}
                            </div>
                            {
                                data.args?.length > 0 &&
                                <table style={{ borderCollapse: 'collapse', width: '-webkit-fill-available', color: '#666', fontSize: 14 }}>
                                    <thead>
                                        <tr style={{ backgroundColor: '#f8f8f8' }}>
                                            <th style={{ fontWeight: 400 }}>{intl.formatMessage({ id: 'arg_name' })}</th>
                                            <th style={{ fontWeight: 400 }}>{intl.formatMessage({ id: 'arg_label' })}</th>
                                            <th style={{ fontWeight: 400 }}>{intl.formatMessage({ id: 'arg_type' })}</th>
                                            {/* <th style={{ fontWeight: 400 }}>{intl.formatMessage({ id: 'arg_hint' })}</th> */}
                                            {
                                                !readOnly &&
                                                <th style={{ fontWeight: 400 }}>{intl.formatMessage({ id: 'operation' })}</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {
                                    data.args?.length === 0 &&
                                    <tr>
                                        <td style={{ paddingLeft: '4px' }} colSpan={6}>{intl.formatMessage({ id: 'prompt_no_arg' })}</td>
                                    </tr>
                                } */}
                                        {data.args?.map((arg, index) => (
                                            <tr key={index}>
                                                <td style={{ paddingLeft: '4px' }}>
                                                    <div style={{ color: 'dodgerblue', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setPromptFormArgEditorState({
                                                                visible: true,
                                                                edittingArgIndex: index
                                                            })
                                                        }}
                                                    >{'var_' + index}</div>
                                                </td>
                                                <td style={{ paddingLeft: '4px' }}>{arg.label}</td>
                                                <td style={{ paddingLeft: '4px' }}>{intl.formatMessage({ id: 'arg_type_' + arg.type })}</td>
                                                {/* <td style={{ maxWidth: '200px', paddingLeft: '4px' }}>{arg.hint}</td> */}
                                                {
                                                    !readOnly &&
                                                    <td>
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                                            <div
                                                                onClick={() => removeArg(index)}
                                                                className="hoverStand"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    flexDirection: 'row',
                                                                    borderRadius: '3px'
                                                                }}
                                                            >
                                                                <Trash size={16} style={{ color: 'gray' }} />
                                                            </div>

                                                            <div
                                                                onClick={() => {
                                                                    setPromptFormArgEditorState({
                                                                        visible: true,
                                                                        edittingArgIndex: index
                                                                    })
                                                                }}
                                                                className="hoverStand"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    flexDirection: 'row',
                                                                    borderRadius: '3px'
                                                                }}
                                                            >
                                                                <Edit size={16} style={{ color: 'gray' }} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            }
                            {
                                !readOnly && data.args?.length > 0 &&
                                <div style={{ color: 'gray', fontSize: '14px' }}>
                                    {intl.formatMessage({ id: 'prompt_template_desc_1' }, { variables: variables_str })}
                                </div>
                            }
                            {
                                !readOnly &&
                                <div>
                                    <Button variant='contained' onClick={() => {
                                        setPromptFormArgEditorState({
                                            visible: true
                                        })
                                    }}>
                                        {intl.formatMessage({ id: 'add_arg' })}
                                    </Button>
                                </div>
                            }
                        </div>

                        {/* Visibility options */}
                        {trigger !== 'shared' && (
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '12px' }}>
                                <div style={{ whiteSpace: 'nowrap' }}>{intl.formatMessage({ id: "visibility" })}</div>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        value={data.visibility || 0}
                                        onChange={(event) => {
                                            setData({
                                                ...data,
                                                visibility: parseInt(event.target.value),
                                                locale: isChinese(data.name?.trim()) ? 'zh' : 'en'
                                            });
                                        }}
                                    >
                                        <FormControlLabel value={0} disabled={readOnly} control={<Radio />} label={intl.formatMessage({ id: 'visibility_private' })} />
                                        <FormControlLabel value={1} disabled={readOnly} control={<Radio />} label={intl.formatMessage({ id: 'visibility_workspace' })} />
                                        <FormControlLabel value={2} disabled={readOnly} control={<Radio />} label={intl.formatMessage({ id: 'visibility_public' })} />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        )}

                        {/* Language selection for public prompts */}
                        {data?.visibility === 2 && trigger !== 'shared' && (
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '6px' }}>
                                {intl.formatMessage({ id: "prompt_language" })}
                                {readOnly ? (
                                    <span>{lng_list.find(l => l.Symbol === (data.locale || (isChinese(data.name?.trim()) ? 'zh' : 'en'))).Language}</span>
                                ) : (
                                    <select
                                        name="locales"
                                        id="locale-select"
                                        style={{
                                            padding: "2px 6px",
                                            marginLeft: "6px",
                                            border: '1px solid #ccc',
                                            borderRadius: '3px',
                                            outline: 'none',
                                        }}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                locale: e.target.value
                                            })
                                        }}
                                        value={data.locale || (isChinese(data.name?.trim()) ? 'zh' : 'en')}
                                        disabled={readOnly}
                                    >
                                        {lng_list?.map((lng, index) => (
                                            <option key={index} value={lng.Symbol}>
                                                {lng.Language}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                        )}
                    </div>
                )
            }

            {
                error &&
                <div style={{ color: 'red' }}>{error}</div>
            }
            {
                promote_vip?.visible && <PromoteVIP onClose={() => handleClose()} />
            }
            <PromptFormArgEditor
                editorState={promptFormArgEditorState}
                form={data}
                handleClose={() => setPromptFormArgEditorState({ visible: false })}
                handleConfirm={(arg) => {
                    let args = data.args ? [...data.args] : [];
                    let newArg = prepareNewArg(arg)

                    if (promptFormArgEditorState.edittingArgIndex > -1) {
                        args[promptFormArgEditorState.edittingArgIndex] = arg;
                    } else {
                        args.push(newArg);
                    }

                    setData({
                        ...data,
                        args
                    })

                    setPromptFormArgEditorState({ visible: false })
                }}
            />
        </div>
    );
}