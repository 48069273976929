import React, { useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { copyNodeOrTextToNote, getNewSubNodePosition } from './utils';

import { Message } from '@styled-icons/boxicons-regular/Message';
import { List } from '@styled-icons/fluentui-system-filled/List';
import { Magic } from '@styled-icons/remix-line/Magic'
import { Note } from '@styled-icons/fluentui-system-regular/Note';
import { Clipboard, ListTask, Trash } from '@styled-icons/bootstrap';
import { StickyNote } from '@styled-icons/remix-line/StickyNote';
import { Book } from '@styled-icons/boxicons-regular/Book'
import { Image } from '@styled-icons/remix-line/Image'
import { Tooltip } from '@mui/material';
import { Link } from '@styled-icons/entypo/Link';
import { Search } from '@styled-icons/remix-line/Search';
import { LightUp } from '@styled-icons/entypo/LightUp';
import { Paste } from '@styled-icons/boxicons-regular/Paste';
import { FileCopy } from '@styled-icons/remix-line/FileCopy';
import { Copy } from '@styled-icons/ionicons-outline/Copy';
import { Group } from '@styled-icons/fluentui-system-regular/Group';
import { NotebookAdd } from '@styled-icons/fluentui-system-regular/NotebookAdd'
import { useReactFlow, getIncomers, getOutgoers } from '@xyflow/react';

export default function ContextMenu({
  id,
  top,
  left,
  right,
  bottom,
  selection_rect_top,
  selected_nodes_sect,
  type,
  selected_text,
  setEdges,
  setNodes,
  getNode,
  getNodeEdges,
  deleteNode,
  addNode,
  addNote,
  addImage,
  addSubNode,
  addInitNode,
  groupNodes,
  setSavingTrigger,
  paste,
  copyNode,
  nodes,
  edges,
  addTempNote,
  ...props
}) {
  const intl = useIntl();
  const menuRef = useRef();
  const flowInstance = useReactFlow();

  const duplicateNode = useCallback(() => {
    const node = getNode(id);
    const position = {
      x: node.position.x + 50,
      y: node.position.y + 50,
    };

    addNode({
      ...node,
      selected: false,
      dragging: false,
      id: `${node.id}-copy`,
      position,
    });
  }, [id, getNode, addNode]);

  const copyToNote = useCallback(() => {
    const node = getNode(id);

    const node_screen_position = flowInstance.flowToScreenPosition(node.position);
    const newNode = copyNodeOrTextToNote(node, selected_text, (selection_rect_top - node_screen_position.y) / flowInstance.getViewport().zoom);

    addNode(newNode);
    setSavingTrigger(Math.random());
  }, [id, getNode, addNode, selected_text]);

  const expainIt = useCallback((selected_text) => {
    const node = getNode(id);

    addSubNode(id, {
      id: new Date().getTime() + '',
      position: getNewSubNodePosition(node, getNode, getNodeEdges),
      data: {
        nodeType: 'aigc',
        title: selected_text,
        userInput: selected_text,
        ai_action: 'explain',
      },
      type: 'ai_node'
    })
  }, []);

  const getMenuPosition = useCallback(() => {
    const menuRect = menuRef.current.getBoundingClientRect();

    return {
      x: menuRect.left,
      y: menuRect.top
    }
  }, [menuRef]);

  const breakdown = useCallback((selected_text) => {
    if (selected_text) {
      addSubNode(id, {
        id: new Date().getTime() + '',
        position: getNewSubNodePosition(getNode(id), getNode, getNodeEdges),
        data: {
          nodeType: 'aigc',
          title: selected_text,
          userInput: selected_text,
          ai_action: 'breakdown',
          queryType: 'breakdown'
        },
        type: 'ai_node'
      })
    } else {
      addInitNode({ queryType: 'breakdown', position: getMenuPosition() })
    }
  }, [id, getNode, addSubNode, getMenuPosition])

  const brainstorming = useCallback((selected_text) => {
    if (selected_text) {
      addSubNode(id, {
        id: new Date().getTime() + '',
        position: getNewSubNodePosition(getNode(id), getNode, getNodeEdges),
        data: {
          nodeType: 'aigc',
          title: selected_text,
          userInput: selected_text,
          ai_action: 'flow_brainstorming',
          queryType: 'brainstorming'
        },
        type: 'ai_node'
      })
    } else {
      addInitNode({ queryType: 'brainstorming', position: getMenuPosition() })
    }
  }, [id, getNode, addSubNode, getMenuPosition])

  const searchWeb = useCallback(() => {
    if (!selected_text) {
      addInitNode({ queryType: 'search', position: getMenuPosition() })
    } else {
      addSubNode(id, {
        id: new Date().getTime() + '',
        position: getNewSubNodePosition(getNode(id), getNode, getNodeEdges),
        data: {
          nodeType: 'prompt',
          userInput: selected_text,
          queryType: 'search',
          trigger: selected_text
        },
        type: 'ai_node'
      })
    }
  }, [selected_text, id, getNode, addSubNode, getMenuPosition])

  const findClosestExternalNode = useCallback((nodeToRemove, nodes, edges) => {
    const incomers = getIncomers(nodeToRemove, nodes, edges);
    const outgoers = getOutgoers(nodeToRemove, nodes, edges);
    const connectedNodes = [...incomers, ...outgoers];

    // Find the first connected node that's not in certain group
    const externalNode = connectedNodes.find(node => !node.parentId);

    return externalNode;
  }, []);

  const calculateNewPosition = useCallback((nodeToRemove, parentNode, closestNode) => {
    const toEdge = 80;
    const nodeWidth = nodeToRemove.measured?.width || nodeToRemove.computed?.width || 0;
    const nodeHeight = nodeToRemove.measured?.height || nodeToRemove.computed?.height || 0;
    const groupWidth = parentNode.measured?.width || parentNode.computed?.width || 0;
    const groupHeight = parentNode.measured?.height || parentNode.computed?.height || 0;

    if (closestNode) {
      const closestNodeWidth = closestNode.measured?.width || closestNode.computed?.width || 0;
      const closestNodeHeight = closestNode.measured?.height || closestNode.computed?.height || 0;

      const spaceX = Math.abs((parentNode.position.x + groupWidth / 2) - (closestNode.position.x + closestNodeWidth / 2)) -
        (groupWidth / 2 + closestNodeWidth / 2);

      const spaceY = Math.abs((parentNode.position.y + groupHeight / 2) - (closestNode.position.y + closestNodeHeight / 2)) -
        (groupHeight / 2 + closestNodeHeight / 2);

      let newX = closestNode.position.x, newY = closestNode.position.y;

      if (spaceX > spaceY) {
        if (closestNode.position.x < parentNode.position.x) {
          newX = newX + closestNodeWidth + toEdge
        } else {
          newX = newX - nodeWidth - toEdge
        }
      } else {
        if (closestNode.position.y < parentNode.position.y) {
          newY = newY + closestNodeHeight + toEdge
        } else {
          newY = newY - nodeHeight - toEdge
        }
      }

      return { x: newX, y: newY };
    } else {
      // Position outside the group if no external connected node found
      const relativeX = nodeToRemove.position.x / groupWidth;
      const relativeY = nodeToRemove.position.y / groupHeight;

      const toEdgeX = Math.min(nodeToRemove.position.x, groupWidth - nodeToRemove.position.x);
      const toEdgeY = Math.min(nodeToRemove.position.y, groupHeight - nodeToRemove.position.y);

      let newX = parentNode.position.x + nodeToRemove.position.x, newY = parentNode.position.y + nodeToRemove.position.y;

      if (toEdgeX < toEdgeY) {
        if (relativeX < 0.5) {
          newX = newX - nodeToRemove.position.x - toEdge - nodeWidth
        } else {
          newX = newX + groupWidth - nodeToRemove.position.x + toEdge
        }
      } else {
        if (relativeY < 0.5) {
          newY = newY - nodeToRemove.position.y - toEdge - nodeHeight
        } else {
          newY = newY + groupHeight - nodeToRemove.position.y + toEdge
        }
      }

      return { x: newX, y: newY };
    }
  }, []);

  const removeNodeFromGroup = useCallback((id) => {
    const nodeToRemove = getNode(id);
    const parentNode = getNode(nodeToRemove.parentId);

    if (!nodeToRemove || !nodeToRemove.parentId) return;

    const siblingNodes = nodes.filter(node => node.parentId === parentNode.id);

    let newNodes;

    if (siblingNodes.length <= 2) {
      // Dissolve the group if there are 2 or fewer nodes
      newNodes = nodes.map(node => {
        if (node.id === parentNode.id) {
          return null; // Remove the parent node
        }
        if (node.parentId === parentNode.id) {
          return {
            ...node,
            position: {
              x: node.position.x + parentNode.position.x,
              y: node.position.y + parentNode.position.y,
            },
            parentId: undefined,
            extent: undefined
          };
        }
        return node;
      }).filter(n => !!n);
    } else {
      // Remove the node from the group
      const closestNode = findClosestExternalNode(nodeToRemove, nodes, edges);
      const newPosition = calculateNewPosition(nodeToRemove, parentNode, closestNode);

      newNodes = nodes.map(node => {
        if (node.id === id) {
          return {
            ...node,
            position: newPosition,
            parentId: undefined,
            extent: undefined
          };
        }
        return node;
      });
    }

    setNodes(newNodes);

    // Update edges if necessary
    let newEdges = edges.map(edge => {
      if (siblingNodes.length <= 2) {
        // If dissolving the group, remove edges connected to the group node
        if (edge.source === parentNode.id || edge.target === parentNode.id) {
          return null;
        }
      }

      // For the node being removed from the group, update its edges
      if (edge.source === id || edge.target === id) {
        return {
          ...edge,
          sourceHandle: edge.source === id ? null : edge.sourceHandle,
          targetHandle: edge.target === id ? null : edge.targetHandle,
        };
      }

      return edge;
    }).filter(e => !!e); // Remove any null edges (those connected to the dissolved group)

    setEdges(newEdges);

    setSavingTrigger(Math.random())
  }, [nodes, edges, getNode, setNodes, setEdges])

  const horizontal = useMemo(() => ['text_selection', 'nodes_selection'].includes(type), [type]);
  const menu_items = useMemo(() => [{
    //   label: intl.formatMessage({ id: 'duplicate' }),
    //   icon: <ContentCopy size={20} />,
    //   action: duplicateNode,
    //   context_types: ['node'],
    // }, {
    id: 'dynamic',
    label: intl.formatMessage({ id: 'dynamic' }),
    icon: <Magic size={20} color='dodgerblue' />,
    action: () => addInitNode({ queryType: 'dynamic', position: getMenuPosition() }),
    context_types: ['pane'],
  }, {
    id: 'ask_question',
    label: intl.formatMessage({ id: 'ask_question' }),
    icon: <Message size={20} />,
    action: () => addInitNode({ queryType: 'ask', position: getMenuPosition() }),
    context_types: ['pane'],
  }, {
    id: 'explain',
    label: intl.formatMessage({ id: 'explain' }),
    icon: <Book size={20} />,
    action: () => {
      expainIt(selected_text);
    },
    context_types: ['text_selection'],
  }, {
    id: 'brainstorming',
    label: intl.formatMessage({ id: 'brainstorming' }),
    icon: <LightUp size={20} />,
    action: () => brainstorming(selected_text),
    context_types: ['pane', 'text_selection'],
  }, {
    id: 'generate_todo_list',
    label: intl.formatMessage({ id: 'generate_todo_list' }),
    icon: <ListTask size={20} />,
    action: () => addInitNode({ queryType: 'todos', position: getMenuPosition() }),
    context_types: ['pane'],
  }, {
    id: 'breakdown_topics',
    label: intl.formatMessage({ id: 'breakdown_topics' }),
    icon: <List size={20} />,
    action: () => breakdown(selected_text),
    context_types: ['text_selection', 'pane'],
  }, {
    id: 'to_note',
    label: intl.formatMessage({ id: 'to_note' }),
    icon: <Note size={21} />,
    action: () => {
      copyToNote(type == 'text_selection' && top)
    },
    context_types: ['text_selection', 'node'],
  }, {
    id: 'copy_url',
    label: intl.formatMessage({ id: 'copy_url' }),
    icon: <Clipboard size={18} />,
    action: () => {
      const node = getNode(id);

      navigator.clipboard.writeText(node.data.content?.src || node.data.context?.url);
    },
    context_types: ['node'],
  }, {
    id: 'copy',
    label: intl.formatMessage({ id: 'copy' }),
    icon: <Clipboard size={18} />,
    action: () => navigator.clipboard.writeText(selected_text),
    context_types: ['text_selection'],
  }, {
    id: 'to_temp_notebook',
    label: intl.formatMessage({ id: 'to_temp_notebook' }),
    icon: <NotebookAdd size={21} />,
    action: () => {
      addTempNote({ id: new Date().getTime(), content: selected_text });
      setSavingTrigger(Math.random());
    },
    context_types: ['text_selection'],
  }, {
    id: 'copy_node',
    label: intl.formatMessage({ id: 'copy_node' }),
    icon: <Copy size={20} />,
    action: () => {
      const node = getNode(id);
      copyNode(node);
    },
    context_types: ['node'],
  }, {
    id: 'delete',
    label: intl.formatMessage({ id: 'delete' }),
    icon: <Trash size={20} />,
    action: () => deleteNode(id),
    context_types: ['node'],
  }, {
    id: 'remove_from_group',
    label: intl.formatMessage({ id: 'remove_from_group' }),
    icon: <Trash size={20} />,
    action: () => removeNodeFromGroup(id),
    context_types: ['node'],
  }, {
    id: 'search_web',
    label: intl.formatMessage({ id: 'search_web' }),
    icon: <Search size={20} />,
    action: () => searchWeb(),
    context_types: ['text_selection', 'pane'],
  }, {
    id: 'add_link',
    label: intl.formatMessage({ id: 'add_link' }),
    icon: <Link size={20} />,
    action: () => addInitNode({ queryType: 'link', position: getMenuPosition() }),
    context_types: ['pane'],
  }, {
    id: 'add_image',
    label: intl.formatMessage({ id: 'add_image' }),
    icon: <Image size={20} />,
    action: () => addImage(getMenuPosition()),
    context_types: ['pane'],
  }, {
    id: 'add_note',
    label: intl.formatMessage({ id: 'add_note' }),
    icon: <StickyNote size={20} />,
    action: () => addNote(getMenuPosition()),
    context_types: ['pane'],
  }, {
    id: 'paste_as_node',
    label: intl.formatMessage({ id: 'paste_as_node' }),
    icon: <Paste size={19} />,
    action: () => paste(getMenuPosition()),
    context_types: ['pane'],
  }, {
    id: 'group_nodes',
    label: intl.formatMessage({ id: 'group_nodes' }),
    icon: <Group size={22} />,
    action: () => groupNodes(selected_nodes_sect),
    context_types: ['nodes_selection'],
  }].filter(item => {
    if (!item.context_types.includes(type)) return false;

    if (!id) return true;

    const node = getNode(id);

    if (node.parentId && item.id === 'delete') return false;
    if (!node.parentId && item.id === 'remove_from_group') return false;

    const nodeType = node?.data.nodeType;
    if (nodeType === 'group') {
      return !['to_note', 'delete', 'copy_node', 'copy_url'].includes(item.id)
    } else if (nodeType === 'image') {
      return !['to_note'].includes(item.id)
    } else {
      return !['copy_url'].includes(item.id)
    }
  }).map((item, index, items) => {

    const item_btn = <button
      key={index + ''}
      onClick={() => item.action()}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: horizontal ? undefined : 10,
        minWidth: horizontal ? undefined : 240,
        fontSize: 14,
        fontWeight: 450,
        color: '#333',
        borderBottom: !horizontal && index != items.length - 1 && '1px solid #ccc',
        borderRight: horizontal && index != items.length - 1 && '1px solid #ccc'
      }}
    >
      {item.icon} {horizontal ? '' : item.label}
    </button>

    return !horizontal ? item_btn : <Tooltip
      key={index + ''}
      title={item.label}
      placement='bottom'
    >
      {item_btn}
    </Tooltip>
  }), [intl, duplicateNode, deleteNode, addInitNode, type, horizontal, selected_nodes_sect]);

  return (
    <div
      className={horizontal ? "context-menu-h" : "context-menu"}
      style={{ top, left, right, bottom }}
      {...props}
      ref={menuRef}
    >
      {
        menu_items
      }
    </div>
  );
}
